import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Autocomplete, Button, Container, FormControl, Grid, InputLabel, MenuItem, Select, Tab, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import {getAddress} from './../../../../Helpers/function';
import api from './../../../../Apis'
import { useCookies } from "react-cookie";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import Swal from "sweetalert2";

function Index() {
    const [state, setState] = useState({
        value: '1',
        warehouses: [],
        service_viettel_post: [],
        loading_service_viettel_post: false,
        ghtk: {
            province_ghtk: [],
            district_ghtk: [],
            district_to_ghtk: [],
            loading_ghtk: false,
            fee_ghtk: null
        },
        ghn: {
            province_ghn: [],
            district_ghn: [],
            district_to_ghn: [],
            ward_ghn: [],
            ward_to_ghn: [],
            loading_ghn: false,
            fee_ghn: null
        },
        vnpost: {
            province_vnpost: [],
            district_vnpost: [],
            district_to_vnpost: [],
            loading_vnpost: false,
            fee_fast: null,
            fee_slow: null,
            fee: null
        },
        viettel_post: {
            province_viettel_post: [],
            district_viettel_post: [],
            district_to_viettel_post: [],
            loading_viettel_post: false,
            fee_viettel_post: null
        }
    })
    const [cookies, setCookie] = useCookies('is_login');
    const {register, handleSubmit, reset, control} = useForm();
    const handleChange = (e, newValue) => {
        setState({
            ...state,
            value: newValue,
        })
    }

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            }
        };
        fetch(api.get_data_fee, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        ghtk: {...state.ghtk, province_ghtk: result.province_ghtk},
                        ghn: {...state.ghn, province_ghn: result.province_ghn},
                        vnpost: {...state.vnpost, province_vnpost: result.province_vnpost},
                        viettel_post: {...state.viettel_post, province_viettel_post: result.province_viettel_post},
                        warehouses: result.warehouses
                    })
                }
            }
        )
    }, [])

    const changeProvince = (e, province, transport, index, firm_transport, index_district) => {
        const res = getAddress(transport, cookies.is_login, api.get_district, province.id, null);
        const state_copy = {...state};
        if (index == 'from') {
            res.then(result => {
                state_copy[firm_transport][index_district] = result;
                setState(state_copy);
            })
        } else if (index == 'to') {
            res.then(result => {
                state_copy[firm_transport][index_district] = result;
                setState(state_copy);
            })
        }
    }

    const calculatorFeeViettelPost = (data) => {
        setState({
            ...state,
            viettel_post: {...state.viettel_post, loading_viettel_post: true}
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify(data)
        };
        fetch(api.caculator_fee_viettel_post, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        viettel_post: {
                            ...state.viettel_post,
                            loading_viettel_post: false,
                            fee_viettel_post: result.fee
                        },
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error'
                    })
                    setState({
                        ...state,
                        viettel_post: {...state.viettel_post, loading_viettel_post: false},
                    })
                }
            }
        )
    }

    const calculatorFeeGhtk = (data) => {
        setState({
            ...state,
            ghtk: {...state.ghtk, loading_ghtk: true}
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify(data)
        };
        fetch(api.caculator_fee_ghtk, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        ghtk: {...state.ghtk, loading_ghtk: false, fee_ghtk: result.fee},
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error'
                    })
                    setState({
                        ...state,
                        ghtk: {...state.ghtk, loading_ghtk: false},
                    })
                }
            }
        )
    }

    const changeDistrict = (e, district, transport, index, firm_transport, index_ward) => {
        if (index_ward != null) {
            const res = getAddress(transport, cookies.is_login, api.get_ward, null, district.id);
            const state_copy = {...state};
            if (index == 'from') {
                res.then(result => {
                    state_copy[firm_transport][index_ward] = result
                    setState(state_copy)
                })
            } else if (index == 'to') {
                res.then(result => {
                    state_copy[firm_transport][index_ward] = result
                    setState(state_copy)
                })
            }
        }

    }

    const calculatorFeeVnpost = (data) => {
        setState({
            ...state,
            vnpost: {...state.vnpost, loading_vnpost: true}
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify(data)
        };
        fetch(api.caculator_fee_vnpost, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        vnpost: {
                                    ...state.vnpost,
                                    loading_vnpost: false,
                                    // fee_fast: result.fee_fast,
                                    // fee_slow: result.fee_slow,
                                    fee: result.fee
                                },
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error'
                    })
                    setState({
                        ...state,
                        vnpost: {...state.vnpost, loading_vnpost: false},
                    })
                }
            }
        )
    }

    const calculatorFeeGhn = (data) => {
        setState({
            ...state,
            ghn: {...state.ghn, loading_ghn: true}
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify(data)
        };
        fetch(api.caculator_fee_ghn, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        ghn: {...state.ghn, loading_ghn: false, fee_ghn: result.fee},
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error'
                    })
                    setState({
                        ...state,
                        ghn: {...state.ghn, loading_ghn: false},
                    })
                }
            }
        )
    }

    const getServiceViettelPost = (data) => {
        setState({
            ...state,
            loading_service_viettel_post: true
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify(data)
        };
        fetch(api.get_service_viettel_post, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        service_viettel_post: result.list_services,
                        loading_service_viettel_post: false
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        timer: 1500
                    })
                    setState({
                        ...state,
                        loading_service_viettel_post: false
                    })
                }
            }
        )
    }

    return (
        <Container maxWidth="xl" component="main" className='m-t-40'>
            <Grid item xs={12} md={12}>
                <TabContext value={state.value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'rgba(0, 0, 0, 0.12)' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab className='text-transform' label="GHTK" value="1" />
                            <Tab className='text-transform' label="GHN" value="2" />
                            <Tab className='text-transform' label="VNPOST" value="3" />
                            <Tab className='text-transform' label="Viettel Post" value="4" />
                        </TabList>
                    </Box>
                    <TabPanel style={{padding: '10px 0px'}} value="1">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <h3>Tính phí vận chuyển hãng GHTK</h3>
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <form onSubmit={handleSubmit(calculatorFeeGhtk)}>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={3} md={3}>
                                            <b>Gửi từ</b>
                                        </Grid>
                                        <Grid item xs={9} md={9}>
                                            <Controller
                                                render={(props) => (
                                                    <Autocomplete
                                                    onChange={(e, value) => {
                                                        changeProvince(e, value, null, 'from', 'ghtk', 'district_ghtk');
                                                        props.field.onChange(value.name);
                                                    }}
                                                    {...props}
                                                    options={state.ghtk.province_ghtk}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        margin="dense"
                                                        {...params}
                                                        label="Từ tỉnh/Tp *"
                                                        variant="outlined"
                                                        // helperText={state.errors.province_id?.message}
                                                        // error={state.errors.province_id?.status}
                                                        />
                                                    )}
                                                    />
                                                )}
                                                name="province_from_ghtk"
                                                control={control}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={3} md={3}>
                                            <b>Quận/huyện</b>
                                        </Grid>
                                        <Grid item xs={9} md={9}>
                                            <Controller
                                                render={(props) => (
                                                    <Autocomplete
                                                    {...props}
                                                    onChange={(e, value) => {
                                                        props.field.onChange(value.name);
                                                    }}
                                                    options={state.ghtk.district_ghtk}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        margin="dense"
                                                        {...params}
                                                        label="Từ quận/huyện *"
                                                        variant="outlined"
                                                        // helperText={state.errors.province_id?.message}
                                                        // error={state.errors.province_id?.status}
                                                        />
                                                    )}
                                                    />
                                                )}
                                                name="district_from_ghtk"
                                                control={control}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={3} md={3}>
                                            <b>Gửi đến</b>
                                        </Grid>
                                        <Grid item xs={9} md={9}>
                                            <Controller
                                                render={(props) => (
                                                    <Autocomplete
                                                    onChange={(e, value) => {
                                                        changeProvince(e, value, null, 'to', 'ghtk', 'district_to_ghtk');
                                                        props.field.onChange(value.name);
                                                    }}
                                                    {...props}
                                                    options={state.ghtk.province_ghtk}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        margin="dense"
                                                        {...params}
                                                        label="Đến tỉnh/Tp *"
                                                        variant="outlined"
                                                        // helperText={state.errors.province_id?.message}
                                                        // error={state.errors.province_id?.status}
                                                        />
                                                    )}
                                                    />
                                                )}
                                                name="province_to_ghtk"
                                                control={control}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={3} md={3}>
                                            <b>Quận/huyện</b>
                                        </Grid>
                                        <Grid item xs={9} md={9}>
                                            <Controller
                                                render={(props) => (
                                                    <Autocomplete
                                                    {...props}
                                                    onChange={(e, value) => {
                                                        props.field.onChange(value.name);
                                                    }}
                                                    options={state.ghtk.district_to_ghtk}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        margin="dense"
                                                        {...params}
                                                        label="Đến quận/huyện *"
                                                        variant="outlined"
                                                        // helperText={state.errors.province_id?.message}
                                                        // error={state.errors.province_id?.status}
                                                        />
                                                    )}
                                                    />
                                                )}
                                                name="district_to_ghtk"
                                                control={control}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={3} md={3}>
                                            <b>Cân nặng (g)</b>
                                        </Grid>
                                        <Grid item xs={9} md={9}>
                                            <TextField
                                                fullWidth
                                                {...register('weight_ghtk')}
                                                margin="dense"
                                                label="Cân nặng *"
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={3} md={3}>
                                            <b>Phương thức</b>
                                        </Grid>
                                        <Grid item xs={9} md={9}>
                                            <FormControl fullWidth margin="dense">
                                                <InputLabel id="demo-simple-select-label">Phương thức</InputLabel>
                                                <Select
                                                    {...register('option_delivery_ghtk')}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Phương thức"
                                                    defaultValue={'none'}
                                                >
                                                    <MenuItem value='xteam'>Xfast</MenuItem>
                                                    <MenuItem value='none'>None</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={3} md={3}>
                                            <b>Vận chuyển đường</b>
                                        </Grid>
                                        <Grid item xs={9} md={9}>
                                            <FormControl fullWidth margin="dense">
                                                <InputLabel id="demo-simple-select-label">Vận chuyển đường *</InputLabel>
                                                <Select
                                                    {...register('service_id_ghtk')}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Vận chuyển đường *"
                                                    defaultValue={'none'}
                                                >
                                                    <MenuItem value='road'>Bộ</MenuItem>
                                                    <MenuItem value='fly'>Bay</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <br></br>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={3} md={3}></Grid>
                                        <Grid item xs={3} md={3}>
                                            <LoadingButton loading={state.ghtk.loading_ghtk} size="large" type="submit" className="text-transform" variant="contained">
                                                Tính phí
                                            </LoadingButton>
                                        </Grid>
                                        {
                                            state.ghtk.fee_ghtk != null &&
                                            <Grid item xs={6} md={6}>
                                                <p style={{fontSize: '20px'}}>
                                                    Tổng phí: <b>{Number(state.ghtk.fee_ghtk).toLocaleString()}</b> vnđ
                                                </p>
                                                {/* {
                                                    !state.fee_ghtk?.delivery &&
                                                    <p style={{color: 'red'}}>
                                                        Không hỗ trợ ship địa chỉ giao hàng này
                                                    </p>
                                                } */}
                                            </Grid>
                                        }
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid> 
                    </TabPanel>
                    <TabPanel style={{padding: '10px 0px'}} value="2">
                        <Grid item xs={12} md={12}>
                            <h3>Tính phí vận chuyển hãng GHN</h3>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={8} md={8}>
                                <form onSubmit={handleSubmit(calculatorFeeGhn)}>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={2} md={2}>
                                            <b>Gửi từ</b>
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <Controller
                                                render={(props) => (
                                                    <Autocomplete
                                                    onChange={(e, value) => {
                                                        changeProvince(e, value, 2, 'from', 'ghn', 'district_ghn');
                                                        props.field.onChange(value.id);
                                                    }}
                                                    {...props}
                                                    options={state.ghn.province_ghn}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        margin="dense"
                                                        {...params}
                                                        label="Từ tỉnh/Tp *"
                                                        variant="outlined"
                                                        // helperText={state.errors.province_id?.message}
                                                        // error={state.errors.province_id?.status}
                                                        />
                                                    )}
                                                    />
                                                )}
                                                name="province_from_ghn"
                                                control={control}
                                            />
                                        </Grid>
                                        <Grid item xs={1} md={1}></Grid>
                                        <Grid item xs={2} md={2}>
                                            <b>Gửi đến</b>
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <Controller
                                                render={(props) => (
                                                    <Autocomplete
                                                    onChange={(e, value) => {
                                                        changeProvince(e, value, 2, 'to', 'ghn', 'district_to_ghn');
                                                        props.field.onChange(value.id);
                                                    }}
                                                    {...props}
                                                    options={state.ghn.province_ghn}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        margin="dense"
                                                        {...params}
                                                        label="Đến tỉnh/Tp *"
                                                        variant="outlined"
                                                        // helperText={state.errors.province_id?.message}
                                                        // error={state.errors.province_id?.status}
                                                        />
                                                    )}
                                                    />
                                                )}
                                                name="province_to_ghn"
                                                control={control}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={2} md={2}>
                                            <b>Quận/huyện</b>
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <Controller
                                                render={(props) => (
                                                    <Autocomplete
                                                    {...props}
                                                    onChange={(e, value) => {
                                                        changeDistrict(e, value, 2, 'from', 'ghn', 'ward_ghn');
                                                        props.field.onChange(value.id);
                                                    }}
                                                    options={state.ghn.district_ghn}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        margin="dense"
                                                        {...params}
                                                        label="Từ quận/huyện *"
                                                        variant="outlined"
                                                        // helperText={state.errors.province_id?.message}
                                                        // error={state.errors.province_id?.status}
                                                        />
                                                    )}
                                                    />
                                                )}
                                                name="district_from_ghn"
                                                control={control}
                                            />
                                        </Grid>
                                        <Grid item xs={1} md={1}></Grid>
                                        <Grid item xs={2} md={2}>
                                            <b>Quận/huyện</b>
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <Controller
                                                render={(props) => (
                                                    <Autocomplete
                                                    {...props}
                                                    onChange={(e, value) => {
                                                        props.field.onChange(value.id);
                                                        changeDistrict(e, value, 2, 'to', 'ghn', 'ward_to_ghn');
                                                    }}
                                                    options={state.ghn.district_to_ghn}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        margin="dense"
                                                        {...params}
                                                        label="Đến quận/huyện *"
                                                        variant="outlined"
                                                        // helperText={state.errors.province_id?.message}
                                                        // error={state.errors.province_id?.status}
                                                        />
                                                    )}
                                                    />
                                                )}
                                                name="district_to_ghn"
                                                control={control}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={2} md={2}>
                                            <b>Phường/xã</b>
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <Controller
                                                render={(props) => (
                                                    <Autocomplete
                                                    onChange={(e, value) => {
                                                        props.field.onChange(value.id);
                                                    }}
                                                    {...props}
                                                    options={state.ghn.ward_ghn}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        margin="dense"
                                                        {...params}
                                                        label="Phường/xã *"
                                                        variant="outlined"
                                                        // helperText={state.errors.ward_id?.message}
                                                        // error={state.errors.ward_id?.status}
                                                        />
                                                    )}
                                                    />
                                                )}
                                                name="ward_ghn"
                                                control={control}
                                            />
                                        </Grid>
                                        <Grid item xs={1} md={1}>
                                        </Grid>
                                        <Grid item xs={2} md={2}>
                                            <b>Phường/xã</b>
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <Controller
                                                render={(props) => (
                                                    <Autocomplete
                                                    onChange={(e, value) => {
                                                        props.field.onChange(value.id);
                                                    }}
                                                    {...props}
                                                    options={state.ghn.ward_to_ghn}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        margin="dense"
                                                        {...params}
                                                        label="Phường/xã *"
                                                        variant="outlined"
                                                        // helperText={state.errors.ward_id?.message}
                                                        // error={state.errors.ward_id?.status}
                                                        />
                                                    )}
                                                    />
                                                )}
                                                name="ward_to_ghn"
                                                control={control}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={2} md={2}>
                                            <b>Chiều rộng</b>
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <TextField
                                                fullWidth
                                                {...register('width_ghn')}
                                                margin="dense"
                                                label="Chiều rộng (cm)"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={1} md={1}></Grid>
                                        <Grid item xs={2} md={2}>
                                            <b>Chiều dài</b>
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <TextField
                                                fullWidth
                                                {...register('length_ghn')}
                                                margin="dense"
                                                label="Chiều dài (cm)"
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={2} md={2}>
                                            <b>Chiều cao</b>
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <TextField
                                                fullWidth
                                                {...register('height_ghn')}
                                                margin="dense"
                                                label="Chiều cao (cm)"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={1} md={1}></Grid>
                                        <Grid item xs={2} md={2}>
                                            <b>Cân nặng</b>
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <TextField
                                                fullWidth
                                                {...register('weight_ghn')}
                                                margin="dense"
                                                label="Cân nặng (g)"
                                                required
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        {/* <Grid item xs={2} md={2}>
                                            <b>Phương thức</b>
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <FormControl fullWidth margin="dense">
                                                <InputLabel id="demo-simple-select-label">Vận chuyển</InputLabel>
                                                <Select
                                                    {...register('delivery_option_ghn')}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Phương thức"
                                                    defaultValue={2}
                                                >
                                                    <MenuItem value='2'>Đường bộ</MenuItem>
                                                    <MenuItem value='1'>Đường bay</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid> */}
                                        {/* <Grid item xs={1} md={1}></Grid> */}
                                        <Grid item xs={2} md={2}>
                                            <b>Dịch vụ</b>
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <FormControl fullWidth margin="dense">
                                                <InputLabel id="demo-simple-select-label">Vận chuyển</InputLabel>
                                                <Select
                                                    {...register('service_id_ghn')}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Phương thức"
                                                    defaultValue={2}
                                                >
                                                    {/* <MenuItem value='53319'>Chuyển phát nhanh</MenuItem>
                                                    <MenuItem value='53321'>Chuyển phát tiết kiệm</MenuItem>
                                                    <MenuItem value='53320'>Chuyển phát tiêu chuẩn</MenuItem> */}
                                                    <MenuItem value='1'>Bay</MenuItem>
                                                    <MenuItem value='2'>Bộ</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={1} md={1}></Grid>
                                        <Grid item xs={2} md={2}>
                                            <b>Kho hàng</b>
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <FormControl fullWidth margin="dense">
                                                <InputLabel id="demo-simple-select-label">Kho hàng</InputLabel>
                                                <Select
                                                    {...register('warehouse_id_ghn')}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Kho hàng"
                                                    defaultValue={2}
                                                >
                                                    {
                                                        state.warehouses.map((value, key) => {
                                                            if (value.transport_id == 2) {
                                                               return (
                                                                    <MenuItem value={value.id}>{value.name}</MenuItem>
                                                                ) 
                                                            }
                                                            
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <br></br>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={2} md={2}>
                                            <LoadingButton loading={state.ghn.loading_ghn} size="large" type="submit" className="text-transform" variant="contained">
                                                Tính phí
                                            </LoadingButton>
                                        </Grid>
                                        {
                                            state.ghn.fee_ghn != null &&
                                            <Grid item xs={6} md={6}>
                                                <p style={{fontSize: '20px'}}>
                                                    Tổng phí: <b>{Number(state.ghn.fee_ghn).toLocaleString()}</b> vnđ
                                                </p>
                                            </Grid>
                                        }
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid> 
                    </TabPanel>
                    <TabPanel style={{padding: '10px 0px'}} value="3">
                        <Grid item xs={12} md={12}>
                            <h3>Tính phí vận chuyển hãng VNPOST</h3>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={4} md={4}>
                                <form onSubmit={handleSubmit(calculatorFeeVnpost)}>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={3} md={3}>
                                            <b>Gửi từ</b>
                                        </Grid>
                                        <Grid item xs={9} md={9}>
                                            <Controller
                                                render={(props) => (
                                                    <Autocomplete
                                                    onChange={(e, value) => {
                                                        changeProvince(e, value, 3, 'from', 'vnpost', 'district_vnpost');
                                                        props.field.onChange(value.id);
                                                    }}
                                                    {...props}
                                                    options={state.vnpost.province_vnpost}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        margin="dense"
                                                        {...params}
                                                        label="Từ tỉnh/Tp *"
                                                        variant="outlined"
                                                        // helperText={state.errors.province_id?.message}
                                                        // error={state.errors.province_id?.status}
                                                        />
                                                    )}
                                                    />
                                                )}
                                                name="province_from_vnpost"
                                                control={control}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={3} md={3}>
                                            <b>Quận/huyện</b>
                                        </Grid>
                                        <Grid item xs={9} md={9}>
                                            <Controller
                                                render={(props) => (
                                                    <Autocomplete
                                                    {...props}
                                                    onChange={(e, value) => {
                                                        props.field.onChange(value.id);
                                                    }}
                                                    options={state.vnpost.district_vnpost}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        margin="dense"
                                                        {...params}
                                                        label="Từ quận/huyện *"
                                                        variant="outlined"
                                                        // helperText={state.errors.province_id?.message}
                                                        // error={state.errors.province_id?.status}
                                                        />
                                                    )}
                                                    />
                                                )}
                                                name="district_from_vnpost"
                                                control={control}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={3} md={3}>
                                            <b>Gửi đến</b>
                                        </Grid>
                                        <Grid item xs={9} md={9}>
                                            <Controller
                                                render={(props) => (
                                                    <Autocomplete
                                                    onChange={(e, value) => {
                                                        changeProvince(e, value, 3, 'to', 'vnpost', 'district_to_vnpost');
                                                        props.field.onChange(value.id);
                                                    }}
                                                    {...props}
                                                    options={state.vnpost.province_vnpost}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        margin="dense"
                                                        {...params}
                                                        label="Đến tỉnh/Tp *"
                                                        variant="outlined"
                                                        // helperText={state.errors.province_id?.message}
                                                        // error={state.errors.province_id?.status}
                                                        />
                                                    )}
                                                    />
                                                )}
                                                name="province_to_vnpost"
                                                control={control}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={3} md={3}>
                                            <b>Quận/huyện</b>
                                        </Grid>
                                        <Grid item xs={9} md={9}>
                                            <Controller
                                                render={(props) => (
                                                    <Autocomplete
                                                    {...props}
                                                    onChange={(e, value) => {
                                                        props.field.onChange(value.id);
                                                    }}
                                                    options={state.vnpost.district_to_vnpost}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        margin="dense"
                                                        {...params}
                                                        label="Đến quận/huyện *"
                                                        variant="outlined"
                                                        // helperText={state.errors.province_id?.message}
                                                        // error={state.errors.province_id?.status}
                                                        />
                                                    )}
                                                    />
                                                )}
                                                name="district_to_vnpost"
                                                control={control}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={3} md={3}>
                                            <b>Cân nặng (g)</b>
                                        </Grid>
                                        <Grid item xs={9} md={9}>
                                            <TextField
                                                fullWidth
                                                {...register('weight_vnpost')}
                                                margin="dense"
                                                label="Cân nặng *"
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={3} md={3}>
                                            <b>Thu hộ</b>
                                        </Grid>
                                        <Grid item xs={9} md={9}>
                                            <TextField
                                                fullWidth
                                                {...register('price_vnpost')}
                                                margin="dense"
                                                label="Thu hộ"
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={3} md={3}>
                                            <b>Dịch vụ</b>
                                        </Grid>
                                        <Grid item xs={9} md={9}>
                                            <FormControl fullWidth margin="dense">
                                                <InputLabel id="demo-simple-select-label">Dịch vụ</InputLabel>
                                                <Select
                                                    {...register('service_id')}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Dịch vụ"
                                                    defaultValue={2}
                                                >
                                                    <MenuItem value="EMS">
                                                        Chuyển phát nhanh
                                                    </MenuItem>
                                                    <MenuItem value="BK">
                                                        Chuyển phát thường
                                                    </MenuItem>
                                                    <MenuItem value="TMDT_BK">
                                                        Chuyển phát tiêu chuẩn
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <br></br>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={3} md={3}></Grid>
                                        <Grid item xs={3} md={3}>
                                            <LoadingButton loading={state.vnpost.loading_vnpost} size="large" type="submit" className="text-transform" variant="contained">
                                                Tính phí
                                            </LoadingButton>
                                        </Grid>    
                                        <Grid item xs={6} md={6}>
                                            {
                                                state.vnpost.fee != null &&
                                                <p style={{fontSize: '20px'}}>
                                                    <b>{Number(state.vnpost.fee).toLocaleString()}</b> vnđ
                                                </p>
                                            }
                                            {/* {
                                                state.vnpost.fee_fast != null &&
                                                <p style={{fontSize: '20px'}}>
                                                    Ship nhanh: <b>{Number(state.vnpost.fee_fast).toLocaleString()}</b> vnđ
                                                </p>
                                            }
                                            {
                                                state.vnpost.fee_slow != null &&
                                                <p style={{fontSize: '20px'}}>
                                                    Ship chậm: <b>{Number(state.vnpost.fee_slow).toLocaleString()}</b> vnđ
                                                </p>
                                            } */}
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid> 
                    </TabPanel>
                    <TabPanel style={{padding: '10px 0px'}} value="4">
                        <Grid item xs={12} md={12}>
                            <h3>Tính phí vận chuyển hãng Viettel Post</h3>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={8} md={8}>
                                <form onSubmit={handleSubmit(calculatorFeeViettelPost)}>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={2} md={2}>
                                            <b>Gửi từ</b>
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <Controller
                                                render={(props) => (
                                                    <Autocomplete
                                                    onChange={(e, value) => {
                                                        changeProvince(e, value, 4, 'from', 'viettel_post', 'district_viettel_post');
                                                        props.field.onChange(value.id);
                                                    }}
                                                    {...props}
                                                    options={state.viettel_post.province_viettel_post}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        margin="dense"
                                                        {...params}
                                                        label="Từ tỉnh/Tp *"
                                                        variant="outlined"
                                                        // helperText={state.errors.province_id?.message}
                                                        // error={state.errors.province_id?.status}
                                                        />
                                                    )}
                                                    />
                                                )}
                                                name="from_province_id_viettel_post"
                                                control={control}
                                            />
                                        </Grid>
                                        <Grid item xs={1} md={1}></Grid>
                                        <Grid item xs={2} md={2}>
                                            <b>Gửi đến</b>
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <Controller
                                                render={(props) => (
                                                    <Autocomplete
                                                    onChange={(e, value) => {
                                                        changeProvince(e, value, 4, 'to', 'viettel_post', 'district_to_viettel_post');
                                                        props.field.onChange(value.id);
                                                    }}
                                                    {...props}
                                                    options={state.viettel_post.province_viettel_post}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        margin="dense"
                                                        {...params}
                                                        label="Đến tỉnh/Tp *"
                                                        variant="outlined"
                                                        // helperText={state.errors.province_id?.message}
                                                        // error={state.errors.province_id?.status}
                                                        />
                                                    )}
                                                    />
                                                )}
                                                name="to_province_id_viettel_post"
                                                control={control}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={2} md={2}>
                                            <b>Quận/huyện</b>
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <Controller
                                                render={(props) => (
                                                    <Autocomplete
                                                    {...props}
                                                    onChange={(e, value) => {
                                                        changeDistrict(e, value, 4, 'from', 'viettel_post', null);
                                                        props.field.onChange(value.id);
                                                    }}
                                                    options={state.viettel_post.district_viettel_post}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        margin="dense"
                                                        {...params}
                                                        label="Từ quận/huyện *"
                                                        variant="outlined"
                                                        // helperText={state.errors.province_id?.message}
                                                        // error={state.errors.province_id?.status}
                                                        />
                                                    )}
                                                    />
                                                )}
                                                name="from_district_id_viettel_post"
                                                control={control}
                                            />
                                        </Grid>
                                        <Grid item xs={1} md={1}></Grid>
                                        <Grid item xs={2} md={2}>
                                            <b>Quận/huyện</b>
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <Controller
                                                render={(props) => (
                                                    <Autocomplete
                                                    {...props}
                                                    onChange={(e, value) => {
                                                        props.field.onChange(value.id);
                                                        changeDistrict(e, value, 4, 'to', 'viettel_post', null);
                                                    }}
                                                    options={state.viettel_post.district_to_viettel_post}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        margin="dense"
                                                        {...params}
                                                        label="Đến quận/huyện *"
                                                        variant="outlined"
                                                        // helperText={state.errors.province_id?.message}
                                                        // error={state.errors.province_id?.status}
                                                        />
                                                    )}
                                                    />
                                                )}
                                                name="to_district_id_viettel_post"
                                                control={control}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={2} md={2}>
                                            <b>Cân nặng</b>
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <TextField
                                                fullWidth
                                                {...register('weight_viettel_post')}
                                                margin="dense"
                                                label="Cân nặng (g)"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={1} md={1}></Grid>
                                        <Grid item xs={2} md={2}>
                                            <b>Số tiền thu hộ</b>
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <TextField
                                                fullWidth
                                                {...register('price_viettel_post')}
                                                margin="dense"
                                                label="Thu hộ (vnđ)"
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                    {
                                        state.service_viettel_post.length > 0 &&
                                        <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                            <Grid item xs={2} md={2}>
                                                <b>Dịch vụ *</b>
                                            </Grid>
                                            <Grid item xs={9} md={9}>
                                                <FormControl fullWidth margin="dense">
                                                    <InputLabel id="demo-simple-select-label">Dịch vụ chuyển phát</InputLabel>
                                                    <Select
                                                        {...register('service_code_viettel_post')}
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Dịch vụ chuyển phát"
                                                        defaultValue={2}
                                                    >
                                                        {
                                                            state.service_viettel_post.map((value, key) => {
                                                                return (
                                                                    <MenuItem value={value.MA_DV_CHINH}>
                                                                        {value.TEN_DICHVU}
                                                                    </MenuItem>
                                                                )
                                                            })
                                                            
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    }
                                    <br></br>
                                    <Grid container spacing={2} sx={{alignItems: 'center', display: 'flex'}}>
                                        <Grid item xs={4} md={4}>
                                            <LoadingButton loading={state.viettel_post.loading_viettel_post} size="large" type="submit" className="text-transform" variant="contained">
                                                Tính phí
                                            </LoadingButton>
                                            <LoadingButton loading={state.loading_service_viettel_post} onClick={handleSubmit(getServiceViettelPost)} size="large" type="submit" className="text-transform" sx={{marginLeft: '10px'}} color="warning" variant="contained">
                                                Lấy dịch vụ phù hợp
                                            </LoadingButton>
                                        </Grid>
                                        {
                                            state.viettel_post.fee_viettel_post != null &&
                                            <Grid item xs={6} md={6}>
                                                <p style={{fontSize: '20px'}}>
                                                    Tổng phí: <b>{Number(state.viettel_post.fee_viettel_post).toLocaleString()}</b> vnđ
                                                </p>
                                            </Grid>
                                        }
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid> 
                    </TabPanel>
                </TabContext>
            </Grid>
        </Container>
    )
}

export default Index;