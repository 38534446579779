import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button, Dialog, DialogTitle, Grid } from '@mui/material';
import { useState } from 'react';
import FormCreateProduct from './FromCreateProduct';

export default function DialogCreateProduct(props) {
    const [state, setState] = useState({
        dialog: false,
        status_edit: false,
        errors: {},
        attributes: [],
        product_details: []
    })

    const addProduct = () => {
        setState({
            ...state,
            dialog: true,
            
        })
    }

    const createProductSuccess = (data) => {
        setState({
            ...state,
            dialog: false
        })
        props.createProductSuccess(data);
    }
    
    return (
        <Grid item xs={12} md={12}>
            <Button onClick={addProduct} sx={{textTransform: "unset"}} startIcon={<AddRoundedIcon/>} variant="contained">
                Thêm sản phẩm
            </Button>
            <Dialog open={state.dialog} fullWidth="true" maxWidth='xl' onClose={() => {setState({...state, dialog: false})}}>
                <DialogTitle>Thông tin sản phẩm</DialogTitle>
                <FormCreateProduct
                    cookie={props.cookie}
                    data_edit={null}
                    createProductSuccess={createProductSuccess}
                />
            </Dialog>
        </Grid>
    )
}