import { Autocomplete, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Controller } from "react-hook-form";
import api from './../../Apis';
import {getAddress} from './../../Helpers/function'

function ProvinceDistrictWard(props) {
    const [state, setState] = useState({
        districts: [],
        wards: []
    })
    const [cookies, setCookie] = useCookies('is_login');
    const changeProvince = (e, value) => {
        const res = getAddress(props.state.transport_id, cookies.is_login, api.get_district, value.id, null);
        res.then( result => {
            setState({
                ...state,
                districts: result,
            })
        })
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + cookies.is_login
        //     },
        //     body: JSON.stringify({
        //         province_id: value.id
        //     })
        // };
        // fetch(api.get_district, requestOptions)
        // .then(res => res.json())
        // .then(
        //     (result) => {
        //         if (result.success) {
        //             setState({
        //                 ...state,
        //                 districts: result.datas
        //             })
        //         }
        //     }
        // )
    }
    const changeDistrict = (e, value) => {
        const res = getAddress(props.state.transport_id, cookies.is_login, api.get_ward, null, value.id);
        res.then( result => {
            setState({
                ...state,
                wards: result,
            })
        })
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + cookies.is_login
        //     },
        //     body: JSON.stringify({
        //         district_id: value.id
        //     })
        // };
        // fetch(api.get_ward, requestOptions)
        // .then(res => res.json())
        // .then(
        //     (result) => {
        //         if (result.success) {
        //             setState({
        //                 ...state,
        //                 wards: result.datas,
        //             })
        //         }
        //     }
        // )
    }
    // const get_district_and_ward = (province_id, district_id) => {
    //     const requestOptions = {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + cookies.is_login
    //         },
    //         body: JSON.stringify({
    //             province_id: province_id,
    //             district_id: district_id
    //         })
    //     };
    //     fetch(api.get_district_and_ward, requestOptions)
    //     .then(res => res.json())
    //     .then(
    //         (result) => {
    //             if (result.success) {
    //                 setState({
    //                     ...state,
    //                     wards: result.wards,
    //                     districts: result.districts
    //                 })
    //             }
    //         }
    //     )
    // }

    const provinces = props.provinces;
    const default_province = {
        id: props.state.data_edit.province.id,
        name: props.state.data_edit.province.name,
    }
    const default_district = {
        id: props.state.data_edit.district.id,
        name: props.state.data_edit.district.name,
    }
    const default_ward = {
        id: props.state.data_edit.ward.id,
        name: props.state.data_edit.ward.name,
    }
    useEffect(() => {
        // get_district_and_ward(props.state.data_edit.province.id, props.state.data_edit.district.id);
        setState({
            districts: props.state.districts,
            wards: props.state.wards
        })
    }, [props.state.data_edit])
    const errors = props.errors;
    return (
        <Grid container spacing={2} sx={{paddingBottom: "16px"}}>
            <Grid item xs={6}>
                <Controller
                    render={(props) => (
                        <Autocomplete
                        onChange={(e, value) => {
                            changeProvince(e, value);
                            props.field.onChange(value.id);
                        }}
                        {...props}
                        defaultValue={default_province}
                        options={provinces}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                            helperText={errors.province_id?.message}
                            error={errors.province_id?.status}
                            {...params}
                            label="Tỉnh/Tp *"
                            variant="outlined"
                            />
                        )}
                        />
                    )}
                    name="province_id"
                    control={props.control}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    render={(props) => (
                        <Autocomplete
                        onChange={(e, value) => {
                            changeDistrict(e, value);
                            props.field.onChange(value.id);
                        }}
                        {...props}
                        defaultValue={default_district}
                        options={state.districts}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                            helperText={errors.district_id?.message}
                            error={errors.district_id?.status}
                            {...params}
                            label="Quận/huyện *"
                            variant="outlined"
                            />
                        )}
                        />
                    )}
                    name="district_id"
                    control={props.control}
                />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    render={(props) => (
                        <Autocomplete
                        onChange={(e, value) => {
                            props.field.onChange(value.id);
                        }}
                        {...props}
                        defaultValue={default_ward}
                        options={state.wards}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                            helperText={errors.ward_id?.message}
                            error={errors.ward_id?.status}
                            {...params}
                            label="Phường/xã *"
                            variant="outlined"
                            />
                        )}
                        />
                    )}
                    name="ward_id"
                    control={props.control}
                />
            </Grid>
        </Grid>
    )
}

export default ProvinceDistrictWard;