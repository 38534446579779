import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material"
import { useEffect, useState } from "react"
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PostItemError from "./PostItemError";
import parse from 'html-react-parser';
import api from './../../../../../../Apis'
import { requestOptions } from "../../../../../../Helpers/function";

export default function ListPostError(props) {
    const [state, setState] = useState({
        dialog_post_error: false,
        dialog_content: false,
        detail_post: {content: '', attachments: []},
        loading_page_error: true,
        list_post_errors: [],
        total_post_error: 0,
        limit: 50
    })

    useEffect(() => {
        setState({
            ...state,
            total_post_error: props.total_post_error
        })
    }, [props.total_post_error])

    const getListPostError = () => {
        setState({...state, dialog_post_error: true});
        fetch(api.get_post_publish_error, requestOptions('POST', props.cookie, null))
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState((oldState) => {
                        return {
                            ...oldState,
                            list_post_errors: result.list_post_errors,
                            loading_page_error: false
                        }
                    })
                }
            }
        )
    }

    const detailPost = (data) => {
        setState({
            ...state,
            detail_post: data,
            dialog_content: true
        })
    }

    const realoadListPostError = (index) => {
        const list_post_errors = [...state.list_post_errors];
        list_post_errors.splice(index, 1);
        setState({
            ...state,
            list_post_errors: list_post_errors
        })
    }

    const loadMorePostError = () => {
        const new_limit = parseInt(state.limit) + 20;
        setState({
            ...state,
            limit: new_limit
        })
    }

    return (
        <div>
            <Dialog open={state.dialog_content} fullWidth="true" maxWidth='md' onClose={() => {setState({...state, dialog_content: false})}}>
                <DialogTitle>Nội dung bài viết</DialogTitle>
                <DialogContent>
                    <div>{state.detail_post.content != null ? parse(state.detail_post.content) : ''}</div><br></br>
                    {
                        state.detail_post.attachments.length > 0 &&
                        <Grid container spacing={2}>
                            {
                                state.detail_post.attachments.map((attachment, index) => {
                                    return (
                                        <Grid item xs={3} md={3}>
                                            <img src={attachment} width="100%"></img>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    }
                </DialogContent>
            </Dialog>
            <Button
                startIcon={<FormatListBulletedIcon/>}
                variant="contained"
                className="text-transform"
                color="error"
                onClick={getListPostError}
            >Bài đăng lỗi ({state.total_post_error})</Button>
            <Dialog open={state.dialog_post_error} fullWidth="true" maxWidth='md' onClose={() => {setState({...state, dialog_post_error: false})}}>
                <DialogTitle>Danh sách bài đăng chưa thành công lên Facebook</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{paddingBottom: "16px"}}>
                        <Grid item xs={1}>
                            <b>STT</b>
                        </Grid>
                        <Grid item xs={4}>
                            <b>Fanpage</b>
                        </Grid>
                        <Grid item xs={2}>
                            <b>Nội dung</b>
                        </Grid>
                        <Grid item xs={3}>
                            <b>Cảnh báo từ Facebook</b>
                        </Grid>
                        <Grid item xs={2}>
                            <b>Hành động</b>
                        </Grid>
                    </Grid>
                    {
                        state.loading_page_error
                        ?
                        <center>
                            <CircularProgress />
                        </center>
                        :
                        state.list_post_errors.slice(0, state.limit).map((postItem, index) => {
                            return (
                                <PostItemError
                                    postItem={postItem}
                                    key={index}
                                    stt={index}
                                    detailPost={detailPost}
                                    cookie={props.cookie}
                                    realoadListPostError={realoadListPostError}
                                />
                            )
                        })
                    }
                    {
                        state.list_post_errors.length > state.limit &&
                        <center>
                            <Button variant="outlined" onClick={loadMorePostError}>
                                Xem thêm
                            </Button>
                        </center>
                    }
                </DialogContent>
            </Dialog>
        </div>
    )
}