import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";
import { useState } from "react";
import Swal from "sweetalert2";
import api from "../../../../../Apis";

export default function ButtonDeleteAllFanpage(props) {
    const [state, setState] = useState({
        loading: false
    })
    const { cookies } = props;
    const removeAllFanpage = () => {
        Swal.fire({
            title: 'Bạn có chắc chắn xóa toàn bộ Fanpage?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Hủy'
        }).then((result) => {
            if (result.isConfirmed) {
                setState({
                    ...state,
                    loading: true
                })
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + cookies.is_login
                    }
                };
                fetch(api.delete_all_fanpage, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            setState({
                                ...state,
                                loading: false
                            })
                            props.deleteAllPage();
                            Swal.fire(result.message, "", "success")
                        } else {
                            setState({
                                ...state,
                                loading: false
                            })
                            Swal.fire(result.message, "", "error")
                        }
                    }
                )
            }
        })
    }

    return (
        <LoadingButton loading={ state.loading } className='text-transform' onClick={() => removeAllFanpage()} variant='contained' color="error">Xóa tất cả Fanpage</LoadingButton>
    )
}