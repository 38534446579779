import { useEffect, useState } from "react"
import Swal from "sweetalert2"
import api from './../../../../../Apis'

export default function ContentTemplateEmailPhone(props) {
    const [state, setState] = useState({
        title: ''
    })
    useEffect(() => {
        setState({
            ...state,
            title: props.data.content.title
        })
    }, [props.data])

    const changeValue = (e) => {
        setState({
            ...state,
            title: e.target.value
        })
    }

    const updateValue = () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                code: props.data.code,
                title: state.title
            })
        };
        fetch(api.update_content_template_phone_email, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (!result.success) {
                    Swal.fire({
                        title: result.message,
                        icon: 'warning',
                        timer: 1500
                    })
                }
            }
        )
    }

    return (
        <textarea 
            value={state.title}
            className="textarea"
            rows="3"
            onChange={changeValue}
            onBlur={updateValue}
        ></textarea>
    )
}