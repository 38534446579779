import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useForm } from 'react-hook-form';
import api from './../../../../../../../Apis'
import Swal from 'sweetalert2';
import LoadingButton from '@mui/lab/LoadingButton';

function ActionComment(props) {
    const hiddenComment = (data, index) => {
        props.hiddenComment(data, index);
    }

    const deleteComment = (data, index) => {
        props.deleteComment(data, index);
    }

    const [state, setState] = useState({
        dialog: false,
        loading: false
    })

    const {register, handleSubmit, reset} = useForm();

    const onSendMessage = (data) => {
        if (data.message == '') {
            Swal.fire('Cần nhập thông điệp', '', 'warning');
        } else {
            setState({
                ...state,
                loading: true
            })
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.cookie
                },
                body: JSON.stringify({
                    message: data.message,
                    fanpage_id: props.fanpage_id,
                    conversation_id: props.conversation_id
                })
            };
            fetch(api.send_text_to_messenger, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        reset();
                        setState({
                            ...state,
                            dialog: false,
                            loading: false
                        })
                    } else {
                        Swal.fire(result.message, '', 'error');
                        setState({
                            ...state,
                            dialog: false,
                            loading: false
                        })
                    }
                }
            )
        }
    }

    return (
        <p className="m-b-0 action-comment">
            <Dialog open={state.dialog} fullWidth="true" maxWidth='sm' onClose={() => {setState({...state, dialog: false})}}>
                <DialogTitle>Nhắn tin đến khách hàng</DialogTitle>
                <DialogContent>
                    <p>
                        Gửi đến: <b>{props.name_sender}</b>
                    </p>
                    <form onSubmit={handleSubmit(onSendMessage)}>
                        <textarea {...register('message')} className='textarea' rows={5} placeholder="Nhập nội dung tin nhắn..."></textarea>
                        <LoadingButton loading={state.loading} type="submit" variant="contained" className='text-transform'>
                            Gửi
                        </LoadingButton>
                    </form>
                </DialogContent>
            </Dialog>
            <Tooltip title={props.data.is_hidden ? 'Hiện' : 'Ẩn'} placement="top">
                <VisibilityOffRoundedIcon className={props.data.is_hidden ? 'hidden-active icon' : 'icon'} onClick={() => hiddenComment(props.data, props.index)}/>
            </Tooltip>
            <Tooltip onClick={() => deleteComment(props.data, props.index)} title='Xóa bình luận' placement="top">
                <DeleteRoundedIcon className="icon"/>
            </Tooltip>
            <Tooltip onClick={() => setState({...state, dialog: true})} title='Nhắn tin riêng' placement="top">
                <MessageRoundedIcon className="icon"/>
            </Tooltip>
        </p>
    )
}

export default ActionComment;