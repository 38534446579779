import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import api from './../../../../../Apis';
import parse from 'html-react-parser';
import Swal from 'sweetalert2';

export default function DialogKeywordComment(props) {
    const {register, handleSubmit, reset, getValues, setValue} = useForm();
    const [state, setState] = useState({
        type_post: '',
        type_content_comment: 'comment_any',
        order: '',
        dialog_list_post: false,
        posts: [],
        current_page: 1,
        last_page: 1,
        is_tag: false,
        errors: {}
    })

    const onSaveKeywordComment = (data) => {
        setState({
            ...state,
            errors: {}
        })
        data['type_content_comment'] = state.type_content_comment;
        data['type_post'] = state.type_post;
        data['fanpage_id'] = props.fanpage_id;
        if (props.index_edit != null) {
            data['id'] = props.data_edit.id;
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify(data)
        };
        fetch(props.index_edit != null ? api.update_keyword : api.create_keyword, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    if (props.index_edit != null) {
                        props.updateKeywordComment(result.new_data);
                    } else {
                        props.createKeywordComment(result.new_data);
                    }
                    Swal.fire({
                        title: result.message,
                        icon: 'success'
                    })
                } else {
                    if (result.type == 'error') {
                        Swal.fire({
                            title: result.message,
                            icon: 'error'
                        })
                    } else if (result.type == 'validate') {
                        setState({
                            ...state,
                            errors: result.errors
                        })
                        Swal.fire({
                            title: result.message,
                            icon: 'warning'
                        })
                    }
                }
            }
        )
    }

    const changePost = (e) => {
        if (e.target.value == 'post_all') {
            setValue('post_ids', '');
        }
        setState({...state, type_post: e.target.value})
    }

    const changeComment = (e) => {
        if (e.target.value != 'comment_keyword') {
            setValue('keyword_comment', '');
        }
        setState({...state, type_content_comment: e.target.value});
    }

    const loadMorePost = () => {
        if (state.current_page < state.last_page) {
            const current_page = state.current_page + 1;
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.cookie
                },
                body: JSON.stringify({
                    fanpage_id: props.fanpage_id,
                    page: current_page
                })
            };
            fetch(api.get_post, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        const old_posts = [...state.posts];
                        setState({
                            ...state,
                            current_page: current_page,
                            posts: old_posts.concat(result.datas.posts),
                        })
                    }
                }
            )
        }
    }

    useEffect(() => {
        if (props.index_edit != null && props.data_edit != null) {
            setValue('keyword_comment', props.data_edit.keyword_comment);
            setValue('post_id_input', props.data_edit.post_id);
            setValue('post_ids', props.data_edit.post_id);
            setValue('message_comment', props.data_edit.message_comment);
            setValue('message_inbox', props.data_edit.message_inbox);
            setValue('is_create_order', props.data_edit.is_create_order);
            setValue('is_hidden_comment', props.data_edit.is_hidden_comment);
            setValue('is_rep_comment', props.data_edit.is_rep_comment);
            setValue('is_inbox', props.data_edit.is_inbox);
            setValue('is_tag', props.data_edit.is_tag);
            setValue('tag_number', props.data_edit.tag_number);
            setValue('comment_rep_tag', props.data_edit.comment_rep_tag);
            setValue('message_inbox_tag', props.data_edit.message_inbox_tag);
            setState({
                ...state,
                is_tag: props.data_edit.is_tag,
                type_content_comment: props.data_edit.type_content_comment,
                type_post: props.data_edit.post_id == '' ? 'post_all' : 'post_special'
            })
        } else {
            reset();
        }
    }, [props.index_edit])

    const listPost = () => {
        setState({
            ...state,
            dialog_list_post: true,
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                fanpage_id: props.fanpage_id,
                page: 1
            })
        };
        fetch(api.get_post, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        dialog_list_post: true,
                        posts: result.datas.posts,
                        last_page: result.last_page,
                        current_page: 1
                    })
                }
            }
        )
    }

    const onSubmitPostId = () => {
        setValue('list_post_ids', getValues('post_ids').join());
        // console.log(getValues('post_ids'));
        setState({...state, dialog_list_post: false});
    }

    const checkTag = () => {
        setState({
            ...state,
            is_tag: getValues('is_tag')
        })
        if (!getValues('is_tag')) {
            setValue('number_tag', '');
            setValue('message_inbox_tag', '');
            setValue('comment_rep_tag', '');
        }
        
    }

    return (
        <form onSubmit={handleSubmit(onSaveKeywordComment)}>
            <Grid container spacing={2}>
                <Dialog
                    open={state.dialog_list_post}
                    fullWidth="true"
                    maxWidth='md'
                    onClose={() => setState({...state, dialog_list_post: false})}
                >
                    <DialogTitle>Dánh sách bài viết</DialogTitle>
                    <DialogContent>
                        {
                            state.posts.length > 0
                            ?    
                                state.posts.map((value, key) => {
                                    return (
                                        <Grid container spacing={2} sx={{marginBottom: '20px'}}>
                                            <Grid item xs={1}>
                                                <input {...register('post_ids')} value={value.post_id} name="post_ids" type="checkbox"></input>
                                            </Grid>
                                            {
                                                value.attachments.length > 0 &&
                                                <Grid item xs={1}>
                                                    <img src={value.attachments[0]} className="w-100"></img>
                                                </Grid>
                                            }
                                            <Grid item xs={10} className="post-content">
                                                <a target="_blank" href={"https://www.facebook.com/" + value.post_id} className="a-none">
                                                    {parse(value.content)}
                                                </a>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            :
                            <center>
                                <i>Dữ liệu trống</i>
                            </center>
                        }
                        {
                            state.last_page > 1 &&
                            <center>
                                <a href="#" onClick={loadMorePost}>Xem thêm</a>
                            </center>
                        }
                        <br></br>
                        <Button onClick={onSubmitPostId} variant="contained" className="text-transform">Xác nhận</Button>
                        <Button sx={{marginLeft: '10px'}} onClick={() => setState({...state, dialog_list_post: false})} color="warning" variant="contained" className="text-transform">Đóng</Button>
                    </DialogContent>
                </Dialog>
                {
                    props.data_edit == null && 
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox {...register('is_setting_together')} />} label='Cài đặt cho tất cả Fanpage' />
                        </FormGroup>
                    </Grid>
                }
                {/* <Grid item xs={12}>
                    <FormGroup onChange={checkTag}>
                        <FormControlLabel control={<Checkbox {...register('is_setting_together')} />} label={props.data_edit?.is_setting_together == 1 ? 'Sửa cho tất cả Fanpage đã cài đặt chung trước đó' : 'Cài đặt cho tất cả Fanpage'} />
                    </FormGroup>
                </Grid> */}
                <Grid item xs={6}>
                    <FormControl className="w-100">
                        <FormLabel id="demo-radio-buttons-group-label">Khi người dùng bình luận trên fanpage:</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            onChange={changeComment}
                            value={state.type_content_comment}
                        >
                            <FormControlLabel value="comment_any" control={<Radio />} label="Nội dung bất kỳ" />
                            <FormControlLabel value="comment_phone" control={<Radio />} label="Nội dung chứa số điện thoại" />
                            <FormControlLabel value="comment_keyword" control={<Radio />} label="Nội dung chứa một số từ khóa" />
                        </RadioGroup>
                        {
                            state.type_content_comment == 'comment_keyword' &&
                            <TextField {...register('keyword_comment')} id="outlined-basic" size="small" label="Từ khóa (cách nhau bằng dấu phẩy)" variant="outlined" />
                        }
                    </FormControl>
                    {
                        state.errors?.type_content_comment &&
                        <p style={{color: 'red'}}>
                            {state.errors.type_content_comment.message}
                        </p>
                    }
                </Grid>
                <Grid item xs={6}>
                    <FormControl className="w-100">
                        <FormLabel id="demo-radio-buttons-group-label">Trong bài viết:</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            onChange={changePost}
                            value={state.type_post}
                        >
                            <FormControlLabel value="post_all" control={<Radio />} label="Tất cả bài viết" />
                            <FormControlLabel value="post_special" control={<Radio />} label="Một bài viết cụ thể" />
                        </RadioGroup>
                        {
                            state.type_post == 'post_special' &&
                            <div>
                                <TextField
                                    fullWidth id="outlined-basic"
                                    defaultValue={Array.isArray(getValues('post_ids')) ? getValues('post_ids').join() : getValues('post_ids')}
                                    {...register('list_post_ids')}
                                    size="small"
                                    placeholder="Id bài viết cách nhau bởi dấu phẩy"
                                    variant="outlined"
                                    />
                                <Button onClick={listPost} variant="contained" color="success" className="text-transform" sx={{marginTop: '10px'}}>Chọn bài viết</Button>
                            </div>
                        }
                    </FormControl>
                    {
                        state.errors?.type_post &&
                        <p style={{color: 'red'}}>
                            {state.errors.type_post.message}
                        </p>
                    }
                </Grid>
                <Grid item xs={12}>
                    <FormGroup onChange={checkTag}>
                        <FormControlLabel control={<Checkbox defaultChecked={props.data_edit?.is_tag} {...register('is_tag')} />} label="Kiểm tra số lượng User được Tag vào comment" />
                    </FormGroup>
                    {
                        state.errors?.is_tag &&
                        <p style={{color: 'red'}}>
                            {state.errors.is_tag.message}
                        </p>
                    }
                    {
                        state.is_tag
                        ?
                        <FormControl className="w-100">
                            <FormLabel sx={{padding: '9px 0px'}} id="demo-radio-buttons-group-label">
                                Số lượng Tag tối thiểu
                            </FormLabel>
                            <TextField placeholder="Số lượng tag tối thiểu" {...register('tag_number')} type="number" size="small"/>
                            {
                                state.errors?.tag_number ?
                                <p style={{color: 'red'}}>
                                    {state.errors.tag_number.message}
                                </p>
                                :
                                <br></br>
                            }
                            <FormLabel sx={{padding: '9px 0px'}} id="demo-radio-buttons-group-label">
                                Nếu khách bình luận nhưng không tag hoặc tag không đủ thì trả lời bình luận (Không bắt buộc):
                            </FormLabel>
                            <textarea cols="30" {...register('comment_rep_tag')} placeholder="Nội dung bình luận" className="textarea" rows="5"></textarea>
                            <small>
                                Chú ý: FB yêu cầu nội dung reply không được giống nhau ==> Chào tên khách hàng + spin content theo mẫu bên dưới:<br></br>
                                Ví dụ: Chào {"{full_name}"}, bạn [hãy|vui lòng] [check|kiểm tra] [inbox|tin nhắn] để [được tư vấn|trao đổi trực tiếp] nhé
                            </small>
                            {
                                state.errors?.comment_rep_tag ?
                                <p style={{color: 'red'}}>
                                    {state.errors.comment_rep_tag.message}
                                </p>
                                :
                                <br></br>
                            }
                            <FormLabel sx={{padding: '9px 0px'}} id="demo-radio-buttons-group-label">
                                Nếu khách bình luận đủ tag thì tự động nhắn tin (Không bắt buộc):
                            </FormLabel>
                            <textarea cols="30" {...register('message_inbox_tag')} placeholder="Nội dung tin nhắn" className="textarea" rows="5"></textarea>
                            {
                                state.errors?.message_inbox_tag &&
                                <p style={{color: 'red'}}>
                                    {state.errors.message_inbox_tag.message}
                                </p>
                            }
                        </FormControl>
                        :
                        <span></span>
                    }
                    
                </Grid>
                <Grid item xs={12}>
                    <FormControl className="w-100">
                        <FormLabel sx={{padding: '9px 0px'}} id="demo-radio-buttons-group-label">Thì tự động trả lời bình luận (Không bắt buộc)</FormLabel>
                        <textarea cols="30" {...register('message_comment')} placeholder="Nội dung bình luận" className="textarea" rows="5"></textarea>
                        {
                            state.errors?.message_comment &&
                            <p style={{color: 'red'}}>
                                {state.errors.message_comment.message}
                            </p>
                        }
                    </FormControl>
                    {/* <p className="m-b-0"> */}
                        <small>
                            Chú ý: FB yêu cầu nội dung reply không được giống nhau ==> Chào tên khách hàng + spin content theo mẫu bên dưới:<br></br>
                            Ví dụ: Chào {"{full_name}"}, bạn [hãy|vui lòng] [check|kiểm tra] [inbox|tin nhắn] để [được tư vấn|trao đổi trực tiếp] nhé
                        </small>
                    {/* </p> */}
                </Grid>
                <Grid item xs={12}>
                    <FormControl className="w-100">
                        <FormLabel sx={{padding: '9px 0px'}} id="demo-radio-buttons-group-label"> Gửi tin nhắn cho khách (Không bắt buộc)</FormLabel>
                        <textarea cols="30" {...register('message_inbox')} placeholder="Nội dung tin nhắn" className="textarea" rows="5"></textarea>
                        {
                            state.errors?.message_inbox &&
                            <p style={{color: 'red'}}>
                                {state.errors.message_inbox.message}
                            </p>
                        }
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    Thuật ngữ viết tắt nội dung tin nhắn:<br></br>
                    <b>{"{full_name}"}</b>: Tên khách hàng<br></br>
                    <b>{"{page_name}"}</b>: Tên page<br></br>
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        {/* <FormControlLabel control={<Checkbox defaultChecked={props.data_edit?.is_create_order} {...register('is_create_order')} />} label="Tạo đơn nếu bình luận chứa số điện thoại và mã sản phẩm" />
                        {
                            state.errors?.is_create_order &&
                            <p style={{color: 'red'}}>
                                {state.errors.is_create_order.message}
                            </p>
                        } */}
                        <FormControlLabel control={<Checkbox defaultChecked={props.data_edit?.is_hidden_comment} {...register('is_hidden_comment')} />} label="Tự động ẩn bình luận" />
                        {
                            state.errors?.is_hidden_comment &&
                            <p style={{color: 'red'}}>
                                {state.errors.is_hidden_comment.message}
                            </p>
                        }
                        <FormControlLabel control={<Checkbox defaultChecked={props.data_edit?.is_rep_comment} {...register('is_rep_comment')} />} label="Tự động trả lời bình luận 1 lần/khách/bài viết" />
                        {
                            state.errors?.is_rep_comment &&
                            <p style={{color: 'red'}}>
                                {state.errors.is_rep_comment.message}
                            </p>
                        }
                        {/* <FormControlLabel control={<Checkbox defaultChecked={props.data_edit?.is_inbox} {...register('is_inbox')} />} label="Tự động nhắn tin 1 lần/khách" />
                        {
                            state.errors?.is_inbox &&
                            <p style={{color: 'red'}}>
                                {state.errors.is_inbox.message}
                            </p>
                        } */}
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                    <Button type="submit" startIcon={<SaveOutlinedIcon/>} variant="contained">
                        Lưu
                    </Button>
                </Grid>
                {/* <Grid item xs={6}>
                    <p className="m-t-0">
                    <Button color="success" startIcon={<DriveFolderUploadOutlinedIcon/>} className="text-transform" variant='contained'>Chọn ảnh bình luận</Button>
                    </p>
                </Grid>
                <Grid item xs={6}>
                    <Button startIcon={<DriveFolderUploadOutlinedIcon/>} className="text-transform" variant='contained'>Chọn ảnh nhắn tin</Button>
                </Grid> */}
            </Grid>
        </form>
    )
}