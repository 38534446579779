import { Button, Checkbox, CircularProgress, Container, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Paper, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import api from './../../../../Apis'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import TabContext from "@mui/lab/TabContext";
import { Box } from "@mui/system";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function Index() {
    const [state, setState] = useState({
        loading: true,
        revenues: [],
        value: '1',
        datasets: [],
        list_months: [],
        year: ''
    })
    const [cookies, setCookie] = useCookies('is_login');
    const {register, handleSubmit, reset} = useForm();

    useEffect(() => {
        callApi({month: null, year: null});
    }, [])

    const callApi = (body) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify(body)
        };
        fetch(api.revenue, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        loading: false,
                        revenues: result.revenues,
                        datasets: result.datasets,
                        list_months: result.list_months,
                        year: result.year,
                        value: '1'
                    })
                } else {
                    setState({
                        ...state,
                        loading: false
                    })
                }
            }
        )
    }

    const searchRevenue = (data) => {
        setState({
            ...state,
            loading: true,
            datas: [],
        })
        callApi(data);
    }
    const labels = state.list_months;
    const data = {
        labels,
        datasets: state.datasets
    };
    const options = {
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        stacked: false,
        plugins: {
          title: {
            display: true,
            text: 'Biểu đồ doanh thu năm ' + state.year,
          },
        },
        scales: {
          y: {
            type: 'linear',
            display: true,
            position: 'left',
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
              drawOnChartArea: false,
            },
          },
        },
    };

    const listMonth = () => {
        const months = [];
        for (var month = 1; month <= 12; month++) {
            months.push(<MenuItem value={month}>Tháng {month}</MenuItem>);
        }

        return months;
    }

    const listYear = () => {
        const years = [];
        for (var year = 2022; year <= new Date().getFullYear(); year++) {
            years.push(<MenuItem value={year}>Năm {year}</MenuItem>);
        }

        return years;
    }

    const total = (items) => {
        return items.map(item => item.total_price + item.total_extra - item.total_discount).reduce((prev, next) => prev + next);
    }

    return (
        <Container maxWidth="xl" className='m-t-40'>
            <h3>Thu nhập</h3>
            <form onSubmit={handleSubmit(searchRevenue)}>
                <Grid container spacing={2}>
                    <Grid item xs={2} md={2}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            {...register('month')}
                            size="small"
                            fullWidth
                            label="Tháng"
                            defaultValue={parseInt(new Date().getMonth() + 1)}
                        >
                            {listMonth()}
                        </TextField>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            {...register('year')}
                            size="small"
                            fullWidth
                            label="Năm"
                            defaultValue={new Date().getFullYear()}
                        >
                            {listYear()}
                        </TextField>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <Button
                            type='submit'
                            startIcon={<SearchRoundedIcon/>}
                            className="text-transform"
                            variant="contained">
                                Tìm kiếm
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <br></br>
            <TabContext value={state.value}>
                <Box sx={{ borderBottom: 1, borderColor: 'rgba(0, 0, 0, 0.12)' }}>
                    <TabList onChange={(event, newValue) => setState({...state, value: newValue})} aria-label="lab API tabs example">
                        <Tab className='text-transform' label="Doanh thu" value="1" />
                        <Tab className='text-transform' label="Biểu đồ" value="2" />
                    </TabList>
                </Box>
                <TabPanel style={{padding: '10px 0px'}} value="2">
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                            <Bar options={options} data={data} />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel style={{padding: '10px 0px'}} value="1">
                    <Grid item xs={12} md={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow style={{whiteSpace: 'nowrap'}}>
                                        <TableCell>STT</TableCell>
                                        <TableCell>Page</TableCell>
                                        <TableCell>Số đơn thành công</TableCell>
                                        <TableCell>Giảm</TableCell>
                                        <TableCell>Phụ thu</TableCell>
                                        <TableCell>Tổng</TableCell>
                                        <TableCell>Nhận</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        state.revenues.length > 0 &&
                                        <TableRow sx={{background: '#5fa6ffd9'}}>
                                            <TableCell align="right" colSpan={6}>
                                                Tổng: 
                                            </TableCell>
                                            <TableCell>
                                                <b style={{fontSize: '20px'}}>
                                                    {Number(total(state.revenues)).toLocaleString()} vnđ
                                                </b>
                                            </TableCell>
                                        </TableRow>
                                    }
                                    {
                                        state.loading
                                        ?
                                        <TableRow>
                                            <TableCell colSpan={7} align="center">
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>
                                        :
                                        state.revenues.length > 0
                                        ?

                                            state.revenues.map((dataItem, key) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell>{key + 1}</TableCell>
                                                        <TableCell>
                                                            <div className="display-flex align-items-center">
                                                                <div><img src={dataItem.page.avatar}></img></div>
                                                                <div style={{marginLeft: '10px'}}>{dataItem.page.name}</div>
                                                            </div>
                                                            
                                                        </TableCell>
                                                        <TableCell>{dataItem.total_order}</TableCell>
                                                        <TableCell>
                                                            <b>{Number(dataItem.total_discount).toLocaleString()}</b> vnđ
                                                        </TableCell>
                                                        <TableCell>
                                                            <b>{Number(dataItem.total_extra).toLocaleString()}</b> vnđ
                                                        </TableCell>
                                                        <TableCell>
                                                            <b>{Number(dataItem.total_price).toLocaleString()}</b> vnđ
                                                        </TableCell>
                                                        <TableCell>
                                                            <b>{Number(dataItem.total_price + dataItem.total_extra - dataItem.total_discount).toLocaleString()}</b> vnđ
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        :
                                        <TableRow>
                                            <TableCell colSpan={7} align="center">
                                                <i>Dữ liệu trống</i>
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </TabPanel>
            </TabContext>
        </Container>
    )
}

export default Index;