import { TextField, Button } from "@mui/material";
import { useState } from "react";
import { convertNumber } from '../../../../../../../Helpers/function.js';

function Time(props) {
    const date = new Date();
    const dateStart = date.getFullYear() + '-' + convertNumber(date.getMonth() + 1) + '-' + convertNumber(date.getDate());
    const dateEnd = date.getFullYear() + '-' + convertNumber(date.getMonth() + 1) + '-' + convertNumber(date.getDate());
    const [date_start, setDateStart] = useState(dateStart);
    const [date_end, setDateEnd] = useState(dateEnd);

    const getRangDate = () => {
        props.responseProps(date_start, date_end);
    }
    return (
        <div className='list-saler date-search'>
            <p style={{textAlign: 'left', paddingLeft: '20px'}} className="m-t-0">Từ:</p>
            <TextField
                id="date"
                // label="Từ"
                type="date"
                // value={date_start}
                onChange={(e) => setDateStart(e.target.value)}
            />
            <p style={{textAlign: 'left', paddingLeft: '20px'}}>Đến:</p>
            <TextField
                id="date"
                // label="Đến"
                type="date"
                // value={date_end}
                onChange={(e) => setDateEnd(e.target.value)}
            />
            <Button onClick={getRangDate} sx={{textAlign: "left", marginTop: "20px"}} variant="contained">
                Xác nhận
            </Button>
        </div>
    )
}

export default Time;