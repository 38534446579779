import LoadingButton from "@mui/lab/LoadingButton";
import { Stack, TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../../../../Apis";

export default function RowFanpageItemNotConnect(props) {
    const { data, stt, cookies, index, status } = props;
    const navigation = useNavigate();
    const [state, setState] = useState({
        loading: false
    })

    const connectPage = () => {
        setState({
            ...state,
            loading: true,
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify({
                fanpage_id: data.fanpage_id
            })
        };
        fetch(api.connect_page, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    Swal.fire(result.message, '', 'success');
                    data["value_status"] = 1;
                    data["value_status_comment"] = 1;
                    props.connected(index, data);
                    setState({
                        ...state,
                        loading: false
                    })
                } else {
                    Swal.fire(result.message, '', 'error');
                    setState({
                        ...state,
                        loading: false
                    })
                }
            }
        )
    }

    return (
        <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell>{ stt }</TableCell>
            <TableCell>
                { data.fanpage_id }
            </TableCell>
            <TableCell>
                <b>{ data.fanpage_name }</b>
            </TableCell>
            <TableCell align="center">
                <Stack sx={{ marginBottom: "5px" }} spacing={1} direction="row" justifyContent="center" flexWrap="wrap">
                    <LoadingButton onClick={() => connectPage()} loading={ state.loading } className="text-transform" color="primary" variant="contained">Kết nối</LoadingButton>
                </Stack>
            </TableCell>
        </TableRow>
    )
}