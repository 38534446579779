import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useState } from "react";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Product from "./Product";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import Swal from 'sweetalert2'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import LoadingButton from '@mui/lab/LoadingButton';

function Cart(props) {
    const [state, setState] = useState({
        dialog: false,
        carts: [],
        extra: 0,
        discount: 0,
        percent: 0,
        payment_method: 0,
        transport: 0,
        loading_submit: false
    })
    const addToCart = (products) => {
        const carts = [];
        products.map((productItem) => {
            const item = JSON.parse(productItem);
            item['number'] = 1;
            carts.push(item);
        })
        setState({...state, dialog: false, carts: carts.concat(state.carts)});
    }

    const removeItemCart = (index) => {
        const copy_cart = [...state.carts];
        copy_cart.splice(index, 1);
        setState({...state, carts: copy_cart});
    }

    const changeNumber = (index, e) => {
        const number = e.target.value;
        if (number <= 0) {
            Swal.fire("Số lượng phải lớn hơn 0", "", "error");
        } else {
            const copy_cart = [...state.carts];
            copy_cart[index].number = number;
            setState({...state, carts: copy_cart});
        }
    }

    const total = (data) => {
        if (data.length > 0) {
            return data.map(item => item.price * item.number).reduce((prev, next) => prev + next);
        }

        return 0;
    }

    const addExtra = (e) => {
        const value = e.target.value;
        
        if (!isNaN(value)) {
            setState({...state, extra: value == '' ? 0 : Number(value)});
        }
    }

    const changeDiscount = (e) => {
        const value = e.target.value;
        const total_price = total(state.carts);
        const percent = total_price == 0 ? 0 : value/total_price * 100;
        if (!isNaN(value)) {
            setState({...state, discount: value == '' ? 0 : Number(value), percent: percent.toFixed(1)});
        }
    }

    const changePercent = (e) => {
        const value = e.target.value;
        const total_price = total(state.carts);
        const discount = value/100 * total_price;
        if (!isNaN(value)) {
            setState({...state, discount: discount, percent: value == '' ? 0 : Number(value)});
        }
    }
    
    const onSubmitOrder = () => {
        if (state.carts.length == 0) {
            Swal.fire("Chưa chọn sản phẩm", "", "warning");
        } else {
            props.onSubmitOrder(state);
        }
        
    }

    return (
        <TableContainer component={Paper}>
            <Dialog open={state.dialog} fullWidth="true" maxWidth='lg' onClose={() => {setState({...state, dialog: false})}}>
                <DialogTitle>Danh sách sản phẩm</DialogTitle>
                    <DialogContent>
                        <Product
                            addToCart={addToCart}
                            cookie={props.cookie}
                            //products={props.products}
                        />
                    </DialogContent>
            </Dialog>
            <Table aria-label="simple table" className="table-cart">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={5}>
                            <Button onClick={() => setState({...state, dialog: true})} startIcon={<AddRoundedIcon/>} sx={{textAlign: "left", textTransform: "unset"}} size="small" variant="contained">
                                Sản phẩm
                            </Button>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>SP</TableCell>
                        {/* <TableCell align="right">Còn</TableCell> */}
                        <TableCell align="right">SL</TableCell>
                        <TableCell align="right">Giá (vnđ)</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {state.carts.map((item, key) => (
                        <TableRow
                        key={key}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {item.product_name} {(item.attribute != '' && item.attribute != null) ? ' (' + item.attribute + ')' : ''}
                            </TableCell>
                            {/* <TableCell align="right">
                                {item.max_number}
                            </TableCell> */}
                            <TableCell width="20%" align="right">
                                <TextField
                                    onChange={(e) => changeNumber(key, e)}
                                    id="standard-helperText"
                                    variant="standard"
                                    value={item.number}
                                    type="number"
                                    // max={item.max_number}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <b>
                                    {Number(item.price * item.number).toLocaleString()}
                                </b>
                            </TableCell>
                            <TableCell align="right">
                                <ClearRoundedIcon onClick={() => removeItemCart(key)} className="cursor" size="small" color="error"/>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell colSpan={2}>
                            <b>Phụ thu</b>
                        </TableCell>
                        <TableCell colSpan={2}>
                            <TextField fullWidth size="small" onChange={addExtra} id="outlined-basic" value={state.extra} variant="outlined" />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell colSpan={2}>
                            <b>Giảm giá</b>
                        </TableCell>
                        <TableCell>
                            <TextField size="small" id="outlined-basic" onChange={changeDiscount} value={state.discount} label="Số tiền" variant="outlined" />
                        </TableCell>
                        <TableCell>
                            <TextField size="small" id="outlined-basic" onChange={changePercent} value={state.percent} label="%" variant="outlined" />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}>
                            <b>Tổng tiền</b>
                        </TableCell>
                        <TableCell align="right" colSpan={2}>
                            <b style={{fontSize: '30px', color: "rgb(236, 136, 0)"}}>
                                {Number(total(state.carts) + parseFloat(state.extra) - parseFloat(state.discount)).toLocaleString()}
                            </b>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="right" colSpan={4}>
                            <LoadingButton onClick={onSubmitOrder} startIcon={<SaveRoundedIcon/>}  variant="contained">
                                Lưu đơn hàng
                            </LoadingButton>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default Cart;