import logo from './logo.svg';
import './Css/dashboard.scss';
import {
	BrowserRouter,
	Routes as Switch,
	Route
  } from 'react-router-dom';
import Login from './Components/Pages/Logins/Index';
import ForgetPassword from './Components/Pages/ForgetPasswords/Index';
import ResetPassword from './Components/Pages/ResetPasswords/Index';
import LoginWithPassword from './Components/Pages/LoginWithPasswords/Index';
import UpdateAfterLogin from './Components/Pages/UpdateAfterLogin/Index';
import { CookiesProvider } from 'react-cookie';
import DashboardRoute from './Router/Index';
import BillOrder from './Components/Dashboards/Pages/Orders/Components/BillOrder';
import ViettelPost from './Components/Dashboards/Pages/PrintBills/ViettelPost';

function App() {
  	return (
		<CookiesProvider>
			<BrowserRouter>
				<Switch>
					<Route path='/print-bill-order/:id' element={<BillOrder/>}/>
					<Route path='/print-bill/:id' element={<ViettelPost/>}/>
					<Route path="/" element={<Login />} />
					<Route path="/reset-password/:token" element={<ResetPassword />} />
					<Route path="/forget-password" element={<ForgetPassword />} />
					{/* <Route path="/login-with-password" element={<LoginWithPassword />} /> */}
					<Route path='/dashboard/*' element={<DashboardRoute />} />
					<Route path='/:uid/update-login' element={<UpdateAfterLogin />} />
				</Switch>
			</BrowserRouter>
		</CookiesProvider>

  );
}

export default App;
