import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import api from '../../../../Apis'
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";

function Index() {
    const {register, handleSubmit, reset} = useForm();
    const [cookies, setCookie] = useCookies('is_login');
    const [state, setState] = useState({
        loading_check: false,
        total_page: 1,
        page_active: 1,
        people: null
    })

    const checkPhoneNumber = (data) => {
        setState({
            ...state,
            loading_check: true
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify(data)
        };
        fetch(api.check_phone_number, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        dialog: false,
                        loading_check: false,
                        people: result.people
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        timer: 1500,
                        icon: 'warning'
                    })
                    setState({
                        ...state,
                        loading_check: false
                    })
                }
            }
        )
    }

    return (
        <div>
            <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
                <Typography
                component="h1"
                variant="h4"
                align="center"
                color="text.primary"
                gutterBottom
                >
                    Kiểm tra bom hàng bằng số điện thoại
                </Typography>
            </Container>
            <Container disableGutters maxWidth="sm" component="main">
                <form onSubmit={handleSubmit(checkPhoneNumber)}>
                    <Grid container spacing={2} sx={{paddingBottom: "16px"}}>
                        <Grid item xs={9}>
                            <TextField
                                {...register('phone')}
                                fullWidth
                                id="outlined-required"
                                label="Nhập số điện thoại"
                                variant="outlined"
                                required
                                autoComplete="off"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <LoadingButton type="submit" loading={state.loading_check} variant="contained" className="text-transform">
                                Kiểm tra
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </Container>
            <Container maxWidth="md" component="main" sx={{ pt: 3, pb: 6 }}>
                <Grid container spacing={2} sx={{paddingBottom: "16px"}}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow style={{whiteSpace: 'nowrap'}}>
                                        <TableCell>SĐT</TableCell>
                                        <TableCell>Tên</TableCell>
                                        <TableCell>Địa chỉ</TableCell>
                                        <TableCell>Ghi chú</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        state.people != null &&
                                        <TableRow>
                                            <TableCell>{state.people.phone}</TableCell>
                                            <TableCell>{state.people.name}</TableCell>
                                            <TableCell>{state.people.address}</TableCell>
                                            <TableCell>{state.people.note}</TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Container>
        </div>
        
    )
}

export default Index;