var domain = process.env.REACT_APP_API;
var domain1 = process.env.REACT_APP_API1;
var api = {
    'get_shop': domain + 'user/info',
    'check_token': domain + 'user/check-token',
    'update_time_online': domain + 'user/update-time-online',
    'upload_video': domain + 'attachment/upload-video',
    'active_shop': domain + 'user/active',
    'update-login': domain + 'update-login',
    'login': domain + 'login',
    'register': domain + 'register',
    'logout': domain + 'logout',
    'delete_account': domain + 'user/delete',
    'delete_multiple_image': domain + 'attachment/mutiple-delete',
    'user_profile': domain + 'user-profile',
    'update_user': domain + 'update-user',
    'forget_password': domain + 'forget-password',
    'reset_password': domain + 'reset-password',
    'get_profile': domain + 'get-profile',
    'get_fanpage': domain + 'fanpage/list',
    'unsubscribe': domain + 'fanpage/unsubscribe',
    'split_fanpage': domain + 'fanpage/split',
    'remove': domain + 'fanpage/remove',
    'run_and_pause_recive_message': domain + 'fanpage/run-and-pause-recive-message',
    'run_and_pause_recive_comment': domain + 'fanpage/run-and-pause-recive-comment',
    'subscribe': domain + 'fanpage/subscribe',
    'get_post': domain + 'get-post',
    'create_post': domain + 'post/create',
    'publish_post_to_facebook': domain + 'post/publish-post-to-facebook',
    'list_post_by_process': domain + 'post/list-post-by-process',
    'create_post_unitest': domain + 'post/unit-test-create',
    'publish_post_special_to_facebook': domain + 'post/publish-post-special-to-facebook',
    'get_post_publish_error': domain + 'post/get-post-publish-error',
    'delete_post': domain + 'delete-post',
    'edit_post': domain + 'edit-post',
    'comment': domain + 'comment',
    'list_comment': domain + 'list-comment',
    // 'delete_comment': domain + 'delete-comment',
    'get_conversation': domain + 'list-conversation',
    'get_conversation_unit_test': domain + 'list-conversation-unit-test',
    'get_list_conversation_paused':  domain1 + 'conversation/get-list-conversation-paused',
    'send_message': domain + 'send-message',
    'get_message': domain + 'get-message',
    'update_activity': domain + 'update-activity',
    'get_field_webhook': domain + 'get-field-webhook',
    'update_field_webhook': domain + 'update-field-webhook',
    'send_attachment': domain + 'send-attachment',
    'get_attachment': domain + 'attachment/list',
    'upload_attachment': domain + 'attachment/upload',
    'upload_url': domain + 'attachment/upload-url',
    'search_name_file': domain + 'attachment/search-name-file',
    'delete_file': domain + 'attachment/delete-file',
    'get_mess_sample': domain + 'messenger/list-mess-sample',
    'create_mess_sample': domain + 'messenger/create-sample',
    'edit_mess_sample': domain + 'messenger/update-mess-sample',
    'delete_mess_sample': domain + 'messenger/delete-mess-sample',
    'search_conversation': domain + 'messenger/search',
    'update_read_conversation': domain + 'conversation/update-read',
    'send_block': domain + 'conversation/send-block',
    'finish_conversation': domain + 'conversation/finish',
    'filter_conversation': domain + 'conversation/search',
    'get_district': domain + 'address/district',
    'get_ward': domain + 'address/ward',
    'get_district_and_ward': domain + 'address/get-district-ward',
    'load_more_mess': domain + 'messenger/load-more-message',
    'create_product': domain + 'product/create',
    'edit_product': domain + 'product/edit',
    'list_product': domain + 'product/list',
    'delete_product': domain + 'product/delete',
    'search_product': domain + 'product/search',
    'create_order': domain + 'order/create',
    'create_handle_order': domain + 'order/create-handle',
    'export_order': domain + 'order/export',
    'update_order': domain + 'order/update',
    'order_detail': domain + 'order/detail',
    'get_order': domain + 'order/list',
    'delete_order': domain + 'order/delete',
    'search_order': domain + 'order/search',
    'get_list_order_by_conversation': domain + 'order/list-by-conversation',
    'get_data_setting': domain + 'setting/data',
    'add_employee': domain + 'setting/add-employee',
    'add_fanpage_to_user': domain + 'setting/add-fanpage-to-user',
    'delete_employee': domain + 'setting/delete-employee',
    'update_employee': domain + 'setting/update-employee',
    'update_shop': domain + 'setting/update-shop',
    'delete_tag': domain + 'setting/delete-tag',
    'update_tag': domain + 'setting/update-tag',
    'create_tag': domain + 'setting/create-tag',
    'setting': domain + 'setting',
    'update_user_for_conversation': domain + 'conversation/update-user',
    'hidden_comment': domain + 'comment/hidden',
    'delete_comment': domain + 'comment/delete',
    'send_comment': domain + 'comment/send',
    'send_attachment_comment': domain + 'comment/send-attachment',
    'send_text_to_messenger': domain + 'comment/send-text-to-messenger',
    'connect_page': domain + 'fanpage/connect',
    'group_fanpage': domain + 'fanpage/group/list',
    'delete_fanpage_in_group' : domain + 'fanpage/delete-in-group',
    'delete_group_fanpage': domain + 'fanpage/group/delete',
    'connect_mutiple_page': domain + 'fanpage/connect-mutiple',
    'refresh_connect_page': domain + 'fanpage/refresh-connect',
    'create_api_token': domain + 'transport/create-api-token',
    'get_token_vnpost': domain + 'transport/get-token-vnpost',
    'get_token_viettel_post': domain + 'transport/get-token-viettel-post',
    'create_warehouse': domain + 'warehouse/create',
    'edit_warehouse': domain + 'warehouse/edit',
    'delete_warehouse': domain + 'warehouse/delete',
    'create_bill_transport': domain + 'order/create-bill-transport',
    'cancel_bill_transport': domain + 'order/cancel-bill-transport',
    'get_bill_transport': domain + 'order/get-bill-transport',
    'create_bill_picked': domain + 'order/create-bill-picked',
    'check_fee_ship': domain + 'order/check-fee-ship',
    'get_service_viettel_post': domain + 'transport/get-service-viettel-post',
    'price_list': domain + 'price',
    'purchase': domain + 'purchase',
    'get_province_by_transport': domain + 'address/get-province-by-transport',
    'print_bill_order': domain + 'order/print-bill-order',
    'print_bill_viettel_post': domain + 'print/bill-viettel-post',
    'update_status_transport_order': domain + 'order/update-status-transport-order',
    'revenue': domain + 'revenue/list',
    'expense': domain + 'expense/list',
    'create_expense': domain + 'expense/create',
    'delete_expense': domain + 'expense/delete',
    'edit_expense': domain + 'expense/edit',
    'report_phone_number': domain + 'report/phone-number',
    'check_phone_number': domain + 'report/check-phone-number',
    'get_all_province': domain + 'address/get-all-province',
    'caculator_fee_ghtk': domain + 'fee/calculator-ghtk',
    'caculator_fee_viettel_post': domain + 'fee/calculator-viettel-post',
    'caculator_fee_ghn': domain + 'fee/calculator-ghn',
    'get_data_fee': domain + 'fee/get-data',
    'caculator_fee_vnpost': domain + 'fee/calculator-vnpost',
    'create_coupon_message': domain + 'messenger/create-coupon-message',
    'get_coupon_message': domain + 'messenger/get-coupon-message',
    'delete_coupon_message': domain + 'messenger/delete-coupon-message',
    'send_coupon_message': domain + 'messenger/send-coupon-message',
    'send_series_coupon_message': domain + 'messenger/send-series-coupon-message',
    'transaction_list': domain + 'transaction/list',
    'list_bank': domain + 'bank/list',
    'list_customer': domain + 'customer/list',
    'get_total_customer_recive_broadcast': domain + 'customer/get-total-customer-recive-broadcast',
    'get_campaign': domain + 'customer/get-campaign',
    'store_campaign': domain + 'customer/store-campaign',
    'delete_campaign': domain + 'customer/delete-campaign',
    'update_info_customer': domain + 'customer/update-info',
    'list_customer_old': domain1 + 'customer/list-old',
    'get_data_keyword': domain + 'keyword/get-data',
    'delete_keyword': domain + 'keyword/delete',
    'change_fanpage_in_keyword': domain + 'keyword/change-fanpage',
    'create_keyword': domain + 'keyword/create',
    'update_keyword': domain + 'keyword/update',
    'create_keyword_message': domain + 'keyword/create-message',
    'update_keyword_message': domain + 'keyword/update-message',
    'change_status_active': domain + 'keyword/change-status-active',
    'get_data_chabot': domain + 'chatbot/get-data',
    'change_fanpage_chatbot': domain + 'chatbot/change-fanpage-chatbot',
    'verify_code': domain + 'coupon/verify-code',
    'create_block_group': domain + 'block-group/create',
    'create_block': domain + 'block-group/block/create',
    'block_group_update': domain + 'block-group/update',
    'block_group_delete': domain + 'block-group/delete',
    'block_update': domain + 'block-group/block/update',
    'create_message_block': domain + 'block/create-message',
    'list_message_block': domain + 'block/list',
    'create_new_template_text_button': domain + 'block/create-new-template-text-button',
    'create_new_template_image': domain + 'block/create-new-template-image',
    'update_text_for_template_text_button': domain + 'block/update-text-for-template-text-button',
    'update_template_image': domain + 'block/update-image-for-template-image',
    'update_content_template_phone_email': domain + 'block/update-content-template-phone-email',
    'delete_block_message': domain + 'block/delete-block-message',
    'add_element_template_gallery': domain + 'block/add-element-template-gallery',
    'delete_element_template_gallery': domain + 'block/delete-element-template-gallery',
    'create_button_for_template_gallery': domain + 'block/create-button-for-template-gallery',
    'update_content_template_gallery': domain + 'block/update-content-template-gallery',
    'sort_block_message': domain + 'block/sort-block',
    'delete_button_in_templete': domain + 'block/delete-button-template',
    'change_status_block_message': domain + 'block/change-status',
    'active_block_default_for_first_message': domain + 'block/active-block-for-first-message',
    'update_block_message': domain + 'block/update-block-message',
    'update_block': domain + 'block/update-block',
    'list_block': domain + 'block/list-block',
    'remove_setting_keyword': domain + 'keyword/delete-all',
    'get_duration': domain + 'purchase/duration',
    'delete_all_fanpage': domain + 'fanpage/delete-all'
};

export default api;