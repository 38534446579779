import TabPanel from '@mui/lab/TabPanel';
import { useEffect, useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button, TextField, Grid, List, RadioGroup, FormControlLabel, Radio, FormGroup, Checkbox, FormControl, InputLabel, Select, OutlinedInput, MenuItem, ListItemText } from '@mui/material';
import { useForm } from 'react-hook-form';
import api from './../../../../../Apis';
import Swal from 'sweetalert2';
import SettingAutoRepComment from './SettingAutoRepComment';

function Tag(props) {
    const [state, setState] = useState({
        tags: [],
        error: {
            name: {
                status: false,
                message: ''
            },
            position: {
                status: false,
                message: ''
            }
        },
        settings: {
            'commission': '',
            'tag_conversation': '',
            'hide_comment': 1,
            'auto_rep_comment': {
                'status': 0,
                'fanpages': [],
                'mess_sample_id': ''
            },
            'auto_inbox': {
                'status': 0,
                'fanpages': [],
                'mess_sample_id': ''
            }
        }
        
    })
    const {register, handleSubmit, reset, getValues} = useForm();
    useEffect(() => {
        setState({
            ...state,
            tags: props.tags,
            settings: props.settings
        })
    }, [props.tags]);

    const compare = (a,b) => {
        if ( a.position > b.position ){
            return 1;
          }
          if ( a.position  < b.position ){
            return -1;
          }
          return 0;
    }

    const onSubmitTag = (data) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                color: data.color,
                name: data.name,
                position: data.position
            })
        };
        fetch(api.create_tag, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    const new_tags = [...state.tags, result.data];
                    setState({
                        ...state,
                        tags: new_tags.sort(compare),
                        error: {
                            name: {
                                status: false,
                                message: ''
                            },
                            position: {
                                status: false,
                                message: ''
                            }
                        }
                    })
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        timer: 1500
                    })
                } else {
                    if (result.type == 'validate') {
                        setState({
                            ...state,
                            error: {
                                name: {
                                    status: result.errors.name != undefined ? true : false,
                                    message: result.errors.name != undefined ? result.errors.name.message : ''
                                },
                                position: {
                                    status: result.errors.position != undefined ? true : false,
                                    message: result.errors.position != undefined ? result.errors.position.message : ''
                                }
                            }
                        })
                    } else {
                        Swal.fire({
                            title: result.message,
                            icon: 'error',
                            timer: 1500
                        })
                    }
                }
            }
        )
    }

    const updateTag = (index, tagId) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                id: tagId,
                position: state.tags[index].position,
                name: state.tags[index].name
            })
        };
        fetch(api.update_tag, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        timer: 1500
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        timer: 1500
                    })
                }
            }
        )
    }

    const changeNameTag = (index, e) => {
        const copy_tags = [...state.tags];
        copy_tags[index].name = e.target.value;
        setState({
            ...state,
            tags: copy_tags
        })
    }

    const changePosition = (index, e) => {
        const copy_tags = [...state.tags];
        copy_tags[index].position = e.target.value;
        setState({
            ...state,
            tags: copy_tags
        })
    }

    const deleteTag = (tagId, index) => {
        Swal.fire({
            title: 'Bạn có chắc chắn muốn xóa thẻ này?',
            showDenyButton: true,
            confirmButtonText: 'Đồng ý',
            denyButtonText: 'Từ chối',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.cookie
                    },
                    body: JSON.stringify({
                        id: tagId
                    })
                };
                fetch(api.delete_tag, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            const array_copy = [...state.tags];
                            array_copy.splice(index, 1);
                            setState({...state, tags: array_copy});
                            Swal.fire({
                                title: result.message,
                                icon: 'success',
                                timer: 1500
                            })
                        } else {
                            Swal.fire({
                                title: result.message,
                                icon: 'error',
                                timer: 1500
                            })
                        }
                    }
                )
            }
        })
    }

    const updateSettingHideComment = () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                value: state.settings.hide_comment,
                name: 'hide_comment'
            })
        };
        fetch(api.setting, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        timer: 1500
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        timer: 1500
                    })
                }
            }
        )
    }

    const updateSettingTag = () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                'value': state.settings.tag_conversation,
                'name': 'tag_conversation',
            })
        };
        fetch(api.setting, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        timer: 1500
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        timer: 1500
                    })
                }
            }
        )
    }

    const onSubmitCommission = (data) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                commission: state.settings.commission,
                name: 'commission'
            })
        };
        fetch(api.setting, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        timer: 1500
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        timer: 1500
                    })
                }
            }
        )
    }

    return (
        <TabPanel style={{padding: '30px 0px'}} value="4">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <form onSubmit={handleSubmit(onSubmitTag)}>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <TextField type="color"  {...register('color')} fullWidth size="small" id="outlined-basic" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth {...register('name')} helperText={state.error.name.message} error={state.error.name.status} size="small" id="outlined-basic" label="Tên trạng thái" variant="outlined" />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField helperText={state.error.position.message} error={state.error.position.status} autoComplete='off' {...register('position')} fullWidth size="small" id="outlined-basic" type="number" label="Vị trí" variant="outlined" />
                            </Grid>
                            <Grid item xs={3}>
                                <Button type="submit" startIcon={<AddRoundedIcon/>} className="text-transform" variant="contained">
                                    Thêm nhãn
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
                <Grid item xs={6}>
                    <h3 className='m-t-0'>
                        Cài đặt chiết khấu hoa hồng cho nhân viên
                    </h3>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField value={state.settings.commission} autoComplete='off' onChange={(e) => setState({...state, settings: {...state.settings, commission: e.target.value}})} fullWidth size="small" id="outlined-basic" type="number" label="Phần trăm" variant="outlined" />
                        </Grid>
                        <Grid item xs={3}>
                            <Button onClick={onSubmitCommission} type="submit" className="text-transform" variant="contained">
                                Lưu
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <br></br><br></br>
                <br></br><br></br>
                <Grid item xs={6}>
                    <table>
                        {
                            state.tags.map((tagItem, index) => {
                                return (
                                    <tr>
                                        <td style={{padding: '10px 0px'}}>
                                            <Button onClick={() => deleteTag(tagItem.id, index)} endIcon={<CloseRoundedIcon/>} className="text-transform" style={{marginRight: '15px', background: tagItem.color}} variant="contained">
                                            {tagItem.name}
                                            </Button>
                                        </td>
                                        <td style={{padding: '10px 0px'}}>
                                            <TextField onChange={(e) => changeNameTag(index, e)} autoComplete='off' value={tagItem.name} fullWidth size="small" id="outlined-basic" label="Tên trạng thái" variant="outlined" />
                                        </td>
                                        <td style={{padding: '10px 0px'}}>
                                            <TextField onChange={(e) => changePosition(index, e)} value={tagItem.position} autoComplete='off' fullWidth size="small" id="outlined-basic" type="number" label="Vị trí" variant="outlined" />
                                        </td>
                                        <td style={{padding: '10px 0px'}}>
                                            <Button onClick={() => updateTag(index, tagItem.id)} className="text-transform" variant="contained">
                                            Cập nhật
                                            </Button>
                                        </td>
                                    </tr>
                                    
                                )
                            })
                        }
                    </table>
                
                </Grid>
                <Grid item xs={6}>
                    <h3>
                        Chia hội thoại cho nhân viên
                    </h3>
                    <List dense sx={{ width: '100%', bgcolor: 'background.paper', padding: '0px' }}>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            style={{padding: "0px 0px"}}
                            onChange={(e) => setState({...state, settings: {...state.settings, tag_conversation: e.target.value}})}
                            value={state.settings.tag_conversation}
                        >
                            <FormControlLabel value="1" control={<Radio />} label={'Phân bằng tay (chỉ Admin và Owner có quyền)'} />
                            <FormControlLabel value="2" control={<Radio />} label={'Phân hội thoại cho người bấm xem đầu tiên'} />
                                
                        </RadioGroup>
                    </List>
                    <br></br>
                    <Button onClick={updateSettingTag} className="text-transform" variant="contained">Cập nhật</Button>
                    {/* <h3>
                        Cài đặt bình luận
                    </h3>
                    <List dense sx={{ width: '100%', bgcolor: 'background.paper', padding: '0px' }}>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            style={{padding: "0px 0px"}}
                            onChange={(e) => setState({...state, settings: {...state.settings, hide_comment: e.target.value}})}
                            value={state.settings.hide_comment}
                        >
                            <FormControlLabel value={1} control={<Radio />} label={'Tự động ẩn bình luận khi có bình luận mới'} />
                            <FormControlLabel value={0} control={<Radio />} label={'Mặc định không ẩn'} />
                                
                        </RadioGroup>
                    </List>
                    <br></br>
                    <Button onClick={updateSettingHideComment} className="text-transform" variant="contained">Cập nhật</Button>
                    <br></br>
                    <h3>
                        Tự động trả lời bình luận, nhắn tin khi nhận được bình luận
                    </h3>
                    <SettingAutoRepComment
                        fanpages={props.fanpages}
                        list_mess_samples={props.list_mess_samples}
                        cookie={props.cookie}
                        settings={state.settings}
                    /> */}
                    
                </Grid>
            </Grid>
            
            
        </TabPanel>
    )
}

export default Tag;