import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Swal from "sweetalert2";
import api from '../../../../../../Apis'

function Index(props) {
    const [state, setState] = useState({
        loading_delete: false
    })

    const deletePost = (value) => {
        Swal.fire({
            title: 'Bạn có chắc chắn muốn xóa?',
            showDenyButton: true,
            confirmButtonText: 'Đồng ý',
            denyButtonText: 'Từ chối',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                setState({
                    ...state,
                    loading_delete: true
                })
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.cookie
                    },
                    body: JSON.stringify({
                        fanpage_id: value.fanpage_id,
                        post_id: value.post_id,
                        page: props.page
                    })
                };
                fetch(api.delete_post, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            setState({
                                ...state,
                                loading_delete: false
                            })
                            props.deletePost(props.index, result.message);
                        } else {
                            Swal.fire({
                                title: result.message,
                                icon: 'error'
                            })
                            setState({
                                ...state,
                                loading_delete: false
                            })
                        }
                    }
                )
            }
        })
    }

    return (
        <LoadingButton loading={state.loading_delete} onClick={() => deletePost(props.value)} className="m-r-5" startIcon={<DeleteRoundedIcon/>} variant="contained" color="error">Xóa</LoadingButton>
    )
}

export default Index;