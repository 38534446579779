import { Button, CircularProgress, DialogTitle, Dialog, DialogContent, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import api from './../../../../../Apis'
import Swal from 'sweetalert2';
import Avatar from '@mui/material/Avatar';

function PageConnect(props) {
    const [state, setState] = useState({
        fanpage_connects: [],
        loading_mutiple_fanpage: false,
        limit: 30
    })
    const connectPage = (key, data) => {
        const copy_array = [...state.fanpage_connects];
        copy_array[key].is_loading = true;
        setState({
            ...state,
            fanpage_connects: copy_array,
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                fanpage_id: data.fanpage_id
            })
        };
        fetch(api.connect_page, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    Swal.fire(result.message, '', 'success');
                    copy_array[key].is_loading = false;
                    copy_array[key].status = 1;
                    // const new_fanpage = {
                    //     fanpage_avatar: data.fanpage_avatar,
                    //     fanpage_name: data.fanpage_name,
                    //     fanpage_id: data.fanpage_id,
                    //     status: 'Hoạt động',
                    //     value_status: 1,
                    //     class: 'status-active',
                    //     fanpage_like: data.fanpage_like
                    // }
                    props.addFanpageActive(result.fb, result.insta);
                    setState({
                        ...state,
                        fanpage_connects: copy_array,
                    })
                } else {
                    copy_array[key].is_loading = false;
                    setState({
                        ...state,
                        fanpage_connects: copy_array
                    })
                    Swal.fire(result.message, '', 'error');
                }
            }
        )
    }
    useEffect(() => {
        setState({
            ...state,
            fanpage_connects: props.fanpage_connects,
        })
    }, [props.fanpage_connects]);

    const handleSelectAll = (e) => {
        const isSelectAll = e.target.checked;
        const fanpage_connects = [...state.fanpage_connects];
        fanpage_connects.map((value) => {
            if (!value.is_connect && isSelectAll && !value.status) {
                value.is_connect = true;
            } else if (!isSelectAll && value.is_connect && !value.status) {
                value.is_connect = false;
            }
        })
        setState({
            ...state,
            fanpage_connects: fanpage_connects
        })
    }

    const handleSelect = (e, index) => {
        const fanpage_connects = [...state.fanpage_connects];
        fanpage_connects[index].is_connect = e.target.checked;
        setState({
            ...state,
            fanpage_connects: fanpage_connects
        })
    }

    const connectMutiplePage = () => {
        const fanpage_ids = [];
        const fanpage_connects = [...state.fanpage_connects];
        fanpage_connects.map((item) => {
            if (!item.status && item.is_connect) {
                fanpage_ids.push(item.fanpage_id);
            }
        })
        if (fanpage_ids.length == 0) {
            Swal.fire({
                title: 'Cần chọn Page cần kết nối',
                icon: 'warning'
            })
        } else {
            Swal.fire({
                title: 'Kết nối đồng thời ' + fanpage_ids.length + ' page sẽ mất thời gian chờ, bạn có chắc chắn?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Đồng ý',
            }).then((result) => {
                if (result.isConfirmed) {
                    setState({
                        ...state,
                        loading_mutiple_fanpage: true
                    });
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + props.cookie
                        },
                        body: JSON.stringify({
                            fanpage_ids: fanpage_ids
                        })
                    };
                    fetch(api.connect_mutiple_page, requestOptions)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result.success) {
                                props.addMutipleFanpageActive(result.fb, result.insta);
                                fanpage_connects.map((item) => {
                                    if (result.fanpage_id_connect_success.includes(item.fanpage_id.toString())) {
                                        item.is_connect = true;
                                        item.status = true;
                                    }
                                })
                                setState({
                                    ...state,
                                    loading_mutiple_fanpage: false,
                                    fanpage_connects: fanpage_connects
                                })
                                if (result.message_error != "") {
                                    Swal.fire({
                                        title: "Kết nối thành công, một số Fanpage chưa kết nối được: ",
                                        text: result.message_error,
                                        icon: 'success'
                                    })
                                } else {
                                    Swal.fire({
                                        title: "Kết nối thành công",
                                        icon: "success",
                                    })
                                }
                            } else {
                                Swal.fire({
                                    title: result.message,
                                    icon: 'warning'
                                })
                            }
                        }
                    )
                }
            })
        }
    }

    const loadMoreFanpage = () => {
        const new_limit = parseInt(state.limit) + 30;
        setState({
            ...state,
            limit: new_limit
        })
    }

    return (
            <DialogContent>
                <LoadingButton loading={state.loading_mutiple_fanpage} color="info" variant='contained' onClick={connectMutiplePage} className='text-transform'>Kết nối các Fanpage đã chọn</LoadingButton>
                <FormGroup onClick={handleSelectAll}>
                    <FormControlLabel control={<Checkbox />} label="Chọn tất cả" />
                </FormGroup>
                {
                    state.fanpage_connects.slice(0, state.limit).map((item, key) => {
                        return (
                            <div className='list-page-connect'>
                                <div>
                                    <Checkbox onClick={event => handleSelect(event, key)} disabled={item.status} checked={(item.is_connect && !item.status) ? true : false} sx={{padding: '0px', marginRight: '10px'}} />
                                </div>
                                <div>
                                    <Avatar alt={item.fanpage_name} src={item.fanpage_avatar}/>
                                    {/* <img src={item.fanpage_avatar}></img> */}
                                </div>
                                <div>
                                    {item.fanpage_name}<br></br>
                                    <small>
                                        {
                                            item.task == item.task_check ? <span style={{color: 'green'}}>Admin</span> : <span style={{color: 'red'}}>Editor</span>
                                        }
                                        {
                                            item.instagram_business_id != null && ', Đã liên kết với Instagram Business'
                                        }
                                    </small>
                                    
                                </div>
                                <div>
                                    {/* <Button onClick={() => connectPage(key)} className='text-transform' variant="contained">
                                        {item.is_loading ? <CircularProgress /> : 'Kết nối'}
                                    </Button> */}
                                    {
                                        item.status ?
                                        <span style={{color: "green"}}>Đã kết nối</span>
                                        :
                                        <LoadingButton className='text-transform' onClick={() => connectPage(key, item)} loading={item.is_loading} variant="contained">
                                            Kết nối
                                        </LoadingButton>
                                    }
                                    
                                </div>
                            </div>
                        )
                    })
                }
                {
                    state.fanpage_connects.length > state.limit &&
                    <p style={{textAlign: 'center'}}>
                        <Button variant='outlined' onClick={loadMoreFanpage}>
                            Xem thêm
                        </Button>
                    </p>
                }
            </DialogContent>
    )
}

export default PageConnect;