import api from './../../../../../Apis';
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const useData = (access_token, fanpage_id) => {
    const [field_webhooks, setFieldWebhook] = useState();
    const [subscribed_apps, setSubscribedApp] = useState();
    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            body: JSON.stringify({
                fanpage_id: fanpage_id
            })
        };
        fetch(api.get_field_webhook, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success == true) {
                    setFieldWebhook(result.datas);
                    setSubscribedApp(result.subscribed_apps);
                }
            }
        )
    }, []);

    return {field_webhooks, subscribed_apps};
}


function Index() {
    const { id } = useParams();
    const [cookies, setCookie] = useCookies('is_login');
    const datas = useData(cookies.is_login, id);
    const {register, handleSubmit} = useForm();
    const onLoginSubmit = (data) => {
        // console.log(JSON.stringify({field_ids:data.id}), data.id);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify({
                field_ids: data.id,
                fanpage_id: id
            })
        };
        fetch(api.update_field_webhook, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                alert(result.message);
            }
        )
    }
    return (
        <div>
            <h3>Đăng ký Webhook của trang</h3>
            <form onSubmit={handleSubmit(onLoginSubmit)}>
                <div className='list-filed-webhook'>
                    {
                        datas.field_webhooks != undefined &&
                        datas.field_webhooks.map((value, key) => {
                            var defaultChecked = null;
                            if (datas.subscribed_apps != undefined) {
                                if (datas.subscribed_apps.includes(value.name)) {
                                    defaultChecked = true;
                                }
                            }
                            return (
                                <label>
                                    <input {...register("id")} checked={defaultChecked} type="checkbox" defaultValue={value.name} /> {value.name}
                                </label>
                            )
                        })
                    }
                </div>
                <br></br>
                <button type='submit'>Cập nhật</button>
            </form>
        </div>
    )
}

export default Index;