import { Autocomplete, Backdrop, Button, CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import api from './../../../../Apis'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Swal from "sweetalert2";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BuildScript from "./Components/BuildScript";

export default function ChatBot() {
    const [state, setState] = useState({
        data_api: {},
        fanpage_selected: '',
        block_groups: [],
        block_active: {},
        loading_page: true,
    })
    const [cookies, setCookie] = useCookies('is_login');

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            }
        };
        fetch(api.get_data_chabot, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        data_api: result.data,
                        loading_page: false,
                        fanpage_selected: result.data.fanpage_selected.fanpage_id
                    })
                }
            }
        )
    }, [])

    const onChangeFanpage = (e, data) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify({
                fanpage_id: data.fanpage_id
            })
        };
        fetch(api.change_fanpage_chatbot, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    const data_api = {...state.data_api};
                    data_api.block_groups = result.data.block_groups;
                    data_api.fanpage_selected = result.data.fanpage_selected;
                    setState({
                        ...state,
                        data_api: data_api,
                        fanpage_selected: data.fanpage_id,
                        block_active: {}
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        timer: 1000,
                        icon: "error"
                    })
                }
            }
        )
    }

    const addBlockGroup = () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify({
                fanpage_id: state.data_api.fanpage_selected.fanpage_id
            })
        };
        fetch(api.create_block_group, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    const data_api = {...state.data_api};
                    data_api.block_groups = [...data_api.block_groups, result.data];
                    setState({
                        ...state,
                        data_api: data_api
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        timer: 1500
                    })
                }
            }
        )
    }

    const addBlock = (data, index) => {
        const rand = Math.random().toString().slice(2,13);
        const data_api = {...state.data_api};
        data_api.block_groups[index].blocks = [...data_api.block_groups[index].blocks, {name: 'Block ' + rand, 'code': rand}];
        setState({
            ...state,
            data_api: data_api
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify({
                block_group_code: data.code,
                name: 'Block ' + rand,
                code: rand
            })
        };
        fetch(api.create_block, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (!result.success) {
                    data_api.block_groups[index].blocks.pop();
                    setState({
                        ...state,
                        data_api: data_api
                    })
                    Swal.fire({
                        title: result.message,
                        icon: 'warning'
                    })
                }
            }
        )
    }

    const changeNameBlockGroup = (e, value) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify({
                code: value.code,
                name: e.target.value
            })
        };
        fetch(api.block_group_update, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (!result.success) {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        timer: 1500
                    })
                }
            }
        )
    }

    const onChangeBlockActive = (blockItem, index, index_child) => {
        blockItem['index_parent'] = index;
        blockItem['index_child'] = index_child;
        setState({...state, block_active: blockItem});
    }

    const updateNameBlockFromProps = (new_name, block) => {
        const data_api = {...state.data_api};
        data_api.block_groups[block.index_parent].blocks[block.index_child].name = new_name;
        setState({
            ...state,
            data_api: data_api
        })
    }

    const deleteBlockGroup = (data, index) => {
        Swal.fire({
            title: 'Bạn có chắc chắn muốn xóa?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Hủy'
        }).then((result) => {
            if (result.isConfirmed) {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + cookies.is_login
                    },
                    body: JSON.stringify({
                        code: data.code,
                    })
                };
                fetch(api.block_group_delete, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            Swal.fire({
                                title: result.message,
                                icon: "success",
                            })
                            const data_api = {...state.data_api};
                            data_api.block_groups.splice(index, 1);
                            setState({
                                ...state,
                                data_api: data_api
                            })
                        } else {
                            Swal.fire({
                                title: result.message,
                                icon: "error",
                                timer: 1000
                            })
                        }
                    }
                )
            }
        })
    }

    return (
        <div>
            {
                state.loading_page
                ?
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={state.loading_page}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                :
                <Container maxWidth="xl" className='m-t-40'>
                    <Grid item xs={12} md={12}>
                        <h3>Cài đặt kịch bản Chatbot</h3>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                {/* <InputLabel id="demo-simple-select-label">Chọn Fanpage</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={state.data_api.fanpage_selected != undefined ? state.data_api.fanpage_selected.fanpage_id : ''}
                                    onChange={onChangeFanpage}
                                    label="Chọn Fanpage"
                                >
                                    {
                                        state.data_api?.fanpages?.map((value, key) => {
                                            return (
                                                <MenuItem value={value.fanpage_id}>{value.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select> */}
                                <Autocomplete
                                    id="tags-outlined"
                                    options={state.data_api?.fanpages}
                                    getOptionLabel={(option) => option.name}
                                    defaultValue={state.data_api.fanpage_selected != undefined && state.data_api.fanpage_selected}
                                    onChange={onChangeFanpage}
                                    filterSelectedOptions
                                    size="small"
                                    renderInput={(params) => (
                                    <TextField
                                        size="small"
                                        {...params}
                                        label="Chọn Fanpage"
                                    />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br></br>
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <h3 className="m-t-0">DANH SÁCH BLOCK</h3>
                            {
                                state.data_api?.block_groups?.length > 0 &&
                                state.data_api?.block_groups?.map((value, index) => {
                                    return (
                                        <div className="block-group-item">
                                            <Grid container spacing={2} sx={{marginBottom: '20px'}}>
                                                <Grid item xs={12}>
                                                    <p className="m-t-0 m-b-0">
                                                        <input key={value.code} style={{fontSize: '20px'}} onBlur={(e) => changeNameBlockGroup(e, value)} defaultValue={value.name}></input>
                                                        <span>
                                                            <DeleteOutlineOutlinedIcon onClick={() => deleteBlockGroup(value, index)} color="error" sx={{marginLeft: '10px'}} className="cursor"/>
                                                        </span>
                                                    </p>
                                                </Grid>
                                                {
                                                    value.blocks.length > 0 &&
                                                    value.blocks.map((blockItem, index_child) => {
                                                        return (
                                                            <Grid item xs={4}>
                                                                <Button
                                                                    fullWidth
                                                                    color={state.block_active?.code == blockItem.code ? "success" : "inherit"}
                                                                    variant="contained"
                                                                    className="text-transform"
                                                                    onClick={() => onChangeBlockActive(blockItem, index, index_child)}
                                                                >
                                                                    <p className="line-1">
                                                                        {blockItem.name}
                                                                    </p>
                                                                </Button>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                                <Grid item xs={4}>
                                                    <Button
                                                        startIcon={<AddOutlinedIcon/>}
                                                        color="warning"
                                                        variant="contained"
                                                        className="text-transform"
                                                        onClick={() => addBlock(value, index)}
                                                    >
                                                        Thêm Block
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )
                                })
                            }
                            <Button
                                startIcon={<AddOutlinedIcon/>}
                                color="primary"
                                variant="contained"
                                className="text-transform"
                                onClick={addBlockGroup}
                                sx={{marginTop: '20px'}}
                            >
                                Tạo nhóm Block mới
                            </Button>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <BuildScript
                            key={state.block_active.code}
                            block_active={state.block_active}
                            cookie={cookies.is_login}
                            updateNameBlockFromProps={updateNameBlockFromProps}
                            fanpage_id={state.data_api?.fanpage_selected?.fanpage_id}
                        />
                    </Grid>
                </Container>
            }
            
        </div>
        
    )
}