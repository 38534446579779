import { Dialog, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import { useState } from "react";
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import {requestOptions} from './../../../../../../../Helpers/function.js';
import api from '../../../../../../../Apis';
import Swal from "sweetalert2";
import LoadingButton from "@mui/lab/LoadingButton";
import BlockItem from "./BlockItem.js";

export default function DialogBlock(props) {
    const [state, setState] = useState({
        dialog_block: false,
        blocks: []
    })

    const openDialogBlock = () => {
        const option = requestOptions('POST', props.cookie, null);
        fetch(api.list_block, option)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        dialog_block: true,
                        blocks: result.list_blocks
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        timer: 1000,
                        icon: "error"
                    })
                }
            }
        )
    }

    const sendBlock = (data) => {
        data['receive_id'] = props.receive_id;
        data['fanpage_id'] = props.fanpage_id;
        const option = requestOptions('POST', props.cookie, data);
        fetch(api.send_block, option)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        dialog_block: false
                    })
                    Swal.fire({
                        title: result.message,
                        icon: "success",
                        timer: 1000
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        timer: 1000,
                        icon: "error"
                    })
                }
            }
        )
    }
    
    return (
        <div>
            <Tooltip arrow onClick={openDialogBlock} title="Danh sách Block" placement="top">
                <GridViewOutlinedIcon/>
            </Tooltip>
            <Dialog open={state.dialog_block} fullWidth="true" maxWidth='sm' onClose={() => {setState({...state, dialog_block: false})}}>
                <DialogTitle>Chọn Block để gửi</DialogTitle>
                    <DialogContent>
                        {/* <ul className="list-block">
                            {
                                state.blocks.map((blockItem) => {
                                    return (
                                        <li onClick={() => sendBlock(blockItem)} className="cursor">
                                            {blockItem.name}
                                        </li>
                                    )
                                })
                            }
                        </ul> */}
                        {
                            state.blocks.map((blockItem, key) => {
                                return (
                                    <BlockItem
                                        key={key}
                                        block={blockItem}
                                        sendBlock={sendBlock}
                                    />
                                )
                            })
                        }
                    </DialogContent>
            </Dialog>
        </div>
    )
}