import { Button, Dialog, DialogContent, DialogTitle, Grid, Tab, TextField } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import api from '../../../../../Apis'
import Swal from "sweetalert2";
import LoadingButton from "@mui/lab/LoadingButton";
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import FormCreateBill from "./FormCreateBill";
import TabContext from "@mui/lab/TabContext";
import { Box } from "@mui/system";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

function ReportPhoneNumber(props) {
    const [state, setState] = useState({
        dialog: false,
        loading_submit: false,
        errors: {},
        dialog_create_bill: false,
        value: String(props.data.transport_id)
    })
    const {register, handleSubmit, reset} = useForm();

    const reportCustomer = (data) => {
        setState({
            ...state,
            loading_submit: true
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify(data)
        };
        fetch(api.report_phone_number, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        dialog: false,
                        loading_submit: false
                    })
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        timer: 1500
                    })
                } else {
                    if (result.type == 'validate') {
                        setState({
                            ...state,
                            loading_submit: false,
                            errors: result.errors
                        })
                    } else if (result.type == 'error') {
                        Swal.fire({
                            title: result.message,
                            icon: 'error',
                            timer: 1500
                        })
                        setState({
                            ...state,
                            loading_submit: false
                        })
                    }
                    
                }
            }
        )
    }

    return (
        <div>
            <Dialog open={state.dialog_create_bill} fullWidth="true" maxWidth='sm' onClose={() => {setState({...state, dialog_create_bill: false})}}>
                <DialogTitle>Xuất vận đơn</DialogTitle>
                <DialogContent>
                    <TabContext value={state.value}>
                        <Box sx={{ borderBottom: 0.5, borderColor: 'divider', borderColor: 'rgba(0, 0, 0, 0.12)'}}>
                            <TabList onChange={(e, newValue) => setState({...state, value: newValue})} aria-label="lab API tabs example">
                                <Tab className='text-transform' label="GHTK" value="1" />
                                <Tab className='text-transform' label="GHN" value="2" />
                                <Tab className='text-transform' label="VNPOST" value="3" />
                                <Tab className='text-transform' label="Viettel Post" value="4" />
                            </TabList>
                        </Box>
                        <TabPanel style={{padding: '10px 0px'}} value="1">
                            <FormCreateBill
                                cookie={props.cookie}
                                order_id={props.order_id}
                                state={props.state}
                                value={state.value}
                                type="list-order"
                            />
                        </TabPanel>
                        <TabPanel style={{padding: '10px 0px'}} value="2">
                            <FormCreateBill
                                cookie={props.cookie}
                                order_id={props.order_id}
                                state={props.state}
                                value={state.value}
                                type="list-order"
                            />
                        </TabPanel>
                        <TabPanel style={{padding: '10px 0px'}} value="3">
                            <FormCreateBill
                                cookie={props.cookie}
                                order_id={props.order_id}
                                state={props.state}
                                value={state.value}
                                type="list-order"
                            />
                        </TabPanel>
                        <TabPanel style={{padding: '10px 0px'}} value="4">
                            <FormCreateBill
                                cookie={props.cookie}
                                order_id={props.order_id}
                                state={props.state}
                                value={state.value}
                                type="list-order"
                            />
                        </TabPanel>
                    </TabContext>
                </DialogContent>
            </Dialog>
            <Dialog open={state.dialog} fullWidth="true" maxWidth='sm' onClose={() => {setState({...state, dialog: false})}}>
                <DialogTitle>Nêu rõ lý do, thông tin địa chỉ:</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit(reportCustomer)}>
                        <Grid container spacing={2} sx={{paddingBottom: "16px"}}>
                            <Grid item xs={12}>
                                <TextField
                                    {...register('phone')}
                                    defaultValue={props.data.customer.phone}
                                    fullWidth
                                    margin="dense"
                                    id="outlined-required"
                                    label="SĐT"
                                    variant="outlined"
                                    required
                                    helperText={state.errors.phone?.message}
                                    error={state.errors.phone?.status}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    {...register('name')}
                                    defaultValue={props.data.customer.name}
                                    fullWidth
                                    margin="dense"
                                    id="outlined-required"
                                    label="Tên"
                                    variant="outlined"
                                    required
                                    helperText={state.errors.name?.message}
                                    error={state.errors.name?.status}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    {...register('address')}
                                    defaultValue={props.data.customer.address + ', ' + props.data.customer.ward + ', ' + props.data.customer.district + ', ' + props.data.customer.province}
                                    fullWidth
                                    margin="dense"
                                    id="outlined-required"
                                    label="Địa chỉ"
                                    variant="outlined"
                                    required
                                    helperText={state.errors.address?.message}
                                    error={state.errors.address?.status}
                                />
                            </Grid>
                        </Grid>
                        <textarea {...register('content')} cols="30" className="textarea" placeholder="Ví dụ: bom hàng, gọi không nghe máy,...." rows="10"></textarea>
                        {
                            state.errors.content?.status &&
                            <p style={{color: 'red'}}>{state.errors.content.message}</p>
                        }
                        <p>
                            <LoadingButton type="submit" loading={state.loading_submit} variant="contained">
                                Xác nhận
                            </LoadingButton>
                        </p>
                    </form>
                </DialogContent>
            </Dialog>
            <Tooltip  title="Đánh dấu bom hàng" arrow placement="top">
                <Button
                    onClick={() => setState({...state, dialog: true})}
                    color="error"
                    size="small"
                    variant="contained"
                    sx={{marginRight: "10px", minWidth: '45px'}}
                >
                    <ReportProblemRoundedIcon sx={{fontSize: '20px'}}/>
                </Button>
            </Tooltip>
            <Tooltip  title="Xuất vận đơn" arrow placement="top">
                <Button onClick={() => {setState({...state, dialog_create_bill: true})}} color="info" variant="contained" sx={{minWidth: '45px'}} size="small">
                    <LocalShippingRoundedIcon sx={{fontSize: '20px'}} color="default" className="cursor"/>
                </Button>
            </Tooltip>
        </div>
        
    )
}

export default ReportPhoneNumber;