import LoadingButton from "@mui/lab/LoadingButton"
import { Button, Checkbox, Dialog, OutlinedInput, DialogContent, DialogTitle, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, TextField, Autocomplete } from "@mui/material"
import { useEffect, useState } from "react"
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useFieldArray, useForm } from "react-hook-form";
import api from './../../../../../../Apis'
import Swal from "sweetalert2";
import PostItemError from "./PostItemError";
import ListPostError from "./ListPostError";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddImage from "./AddImage";

function Index(props) {
    const [state, setState] = useState({
        loading_submit: false,
        dialog_post_error: false,
        list_post_errors: [],
        dialog: false,
        value_select: [],
        number_url: 1,
        time_post: 'now',
        dialog_file_manager: false,
        image_picked: []
    })
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8; 
    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          },
        },
    };
    const {register, handleSubmit, setValue, control, reset,  getValues} = useForm();
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "urls", // unique name for your Field Array
    });
    useEffect(() => {
        if (props.state.data_api?.list_post_errors && props.state.data_api.list_post_errors.length > 0) {
            setState({
                ...state,
                list_post_errors: props.state.data_api.list_post_errors
            })
        }
    }, [props.state.data_api?.list_post_errors])
    const handleChangeFanpage = (event, value) => {
        setState({
            ...state,
            value_select: value
        })
    };
    const onCreateContent = (data) => {
        // setState({
        //     ...state,
        //     loading_submit: true
        // })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                fanpage_ids: state.value_select,
                content: data.content,
                urls: data.urls,
                type_time: data.type_time,
                hour: data.hour,
                minute: data.minute,
                date: data.date,
                group_name: data.group_name
            })
        };
        fetch(api.create_post_unitest, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        loading_submit: false,
                        dialog: false,
                        value_select: [],
                        number_url: 1,
                        time_post: 'now',
                        image_picked: []
                    })
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        timer: 1000
                    })
                    if (data.type_time == 'now') {
                        props.onCreateContent(result);
                    }
                    reset();
                } else {
                    Swal.fire({
                        title: result.message,
                        text: result.error,
                        icon: 'error',
                        timer: 1000
                    })
                    setState({
                        ...state,
                        loading_submit: false
                    })
                }
            }
        )
    }

    const removeElement = (index) => {
        // const image_picked_old = [...state.image_picked];
        // image_picked_old.splice(index, 1);
        const url_olds = getValues('urls');
        url_olds.splice(index, 1);
        setValue('urls', url_olds);
        setState({
            ...state,
            image_picked: url_olds
        })
        // const new_number_url = state.number_url - 1;

        // if (new_number_url > 0) {
        //     setState({
        //         ...state,
        //         number_url: state.number_url - 1
        //     })
        // }
    }

    const renderInput = () => {
        var i;
        var element = [];
        state.image_picked.map((value, i) => {
            setValue(`urls.${i}`, value);
            element.push(
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <TextField {...register(`urls.${i}`)} sx={{marginBottom: '15px'}} autoComplete="off" id="outlined-basic" size="small" fullWidth label={"Dán link ảnh thứ " + i + ' tại đây'} variant="outlined" />
                </Grid>
                <Grid item xs={2}>
                    <Button onClick={() => removeElement(i)} variant="contained" color="error" size="medium">Xóa</Button>
                </Grid>
            </Grid>)
        })
        // for (i = 1; i <= state.number_url; i++) {
        //     element.push(
        //     <Grid container spacing={2}>
        //         <Grid item xs={10}>
        //             <TextField {...register(`urls.${i}`)} sx={{marginBottom: '15px'}} autoComplete="off" id="outlined-basic" size="small" fullWidth label={"Dán link ảnh thứ " + i + ' tại đây'} variant="outlined" />
        //         </Grid>
        //         <Grid item xs={2}>
        //             <Button onClick={removeElement} variant="contained" color="error" size="medium">Xóa</Button>
        //         </Grid>
        //     </Grid>)
        // }

        return element;
    }

    const getHours = () => {
        const options = [];
        for (var hour = 0; hour <= 23; hour++) {
            options.push(
                <MenuItem value={hour}>{hour} giờ</MenuItem> 
            )
        }

        return options;
    }

    const getMinutes = () => {
        const options = [];
        for (var minute = 0; minute <= 59; minute++) {
            if (minute%5 == 0) {
                options.push(
                    <MenuItem value={minute}>{minute} phút</MenuItem> 
                )
            }
            
        }

        return options;
    }

    const updateImagePicked = (image_picked) => {
        const old_images = [...state.image_picked];
        const new_image_picked = old_images.concat(image_picked);
        const filterVideo = new_image_picked.filter((item) => item.includes(".mp4"));
        if (filterVideo.length > 0 && (new_image_picked.length != filterVideo.length || filterVideo.length > 1)) {
            Swal.fire("Không được chọn cả ảnh và video. Chỉ được chọn một video duy nhất.", "", "warning")
        } else {
            setState({
                ...state,
                image_picked: new_image_picked,
                dialog_file_manager: false
            })
        }
    }

    const onChangeTimeCreatePost = (e) => {
        setState({
            ...state, time_post: e.target.value
        });
    }
    const date = new Date();
    const today = date.getFullYear() + '-' + (parseInt(date.getMonth()) + 1) + '-' + date.getDate();
    const hour = date.getHours();
    const minute = 0;
    const list_fanpages = [
        {fanpage_id: -1, name: 'Tất cả'},
        ...props.state.data_api?.fanpages
    ];
    return (
        <div className="display-flex">
            <Button
                sx={{marginRight: '10px'}} 
                startIcon={<AddRoundedIcon/>}
                variant="contained"
                className="text-transform"
                onClick={() => setState({...state, dialog: true})}
            >Thêm mới</Button>
            <ListPostError
                total_post_error={props.state.data_api?.total_post_error}
                cookie={props.cookie}
            />
            <Dialog
                fullWidth="true"
                maxWidth='lg'
                open={state.dialog_file_manager}
                onClose={() => setState({...state, dialog_file_manager: false})}
            >
                <DialogTitle sx={{display: "flex", justifyContent: "space-between"}}>
                    Ảnh của shop
                    <Button startIcon={<CloseOutlinedIcon />} variant="contained" color="warning" onClick={() => setState({...state, dialog_file_manager: false})}>
                        Đóng
                    </Button>
                </DialogTitle>
                <AddImage
                    updateImagePicked={updateImagePicked}
                    cookie={props.cookie}
                />
            </Dialog>
            <Dialog open={state.dialog} fullWidth="true" maxWidth='sm' onClose={() => {setState({...state, dialog: false})}}>
                <DialogTitle>Thêm bài viết</DialogTitle>
                <DialogContent>
                    <p>
                        <b style={{color: 'red'}}>Chú ý: </b>Nếu bạn thêm bài viết đồng thời cho nhiều Fanpage thì quá trình này sẽ mất vài phút hoặc lâu hơn tùy thuộc vào số lượng Page bạn chọn. Lời khuyên là nên phân nhóm các Page của bạn ra để hạn việc bài viết publish bị thiếu.
                    </p>
                    <p>
                        Sử dụng spin content để tránh bị Facebook đánh Spam. Ví dụ: <span style={{color: 'red'}}>Bạn [hãy|vui lòng] [check|kiểm tra] [inbox|tin nhắn] để [được tư vấn|trao đổi trực tiếp] nhé</span>
                    </p>
                    <form onSubmit={handleSubmit(onCreateContent)}>
                        <Grid container spacing={2} sx={{paddingBottom: "16px"}}>
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="dense" size="small">
                                    {/* <InputLabel id="demo-multiple-checkbox-label">Chọn Fanpage</InputLabel> */}
                                    {/* <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={state.value_select}
                                        onChange={handleChangeFanpage}
                                        input={<OutlinedInput label="Chọn Fanpage" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        fullWidth
                                        MenuProps={MenuProps}
                                    >
                                        {
                                            props.state.data_api?.fanpages.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item.fanpage_id}>
                                                        <Checkbox checked={state.value_select.indexOf(item.fanpage_id) > -1}/>
                                                        <ListItemText primary={item.name} />
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select> */}
                                    <Autocomplete
                                        multiple
                                        id="tags-outlined"
                                        options={list_fanpages}
                                        getOptionLabel={(option) => option.name}
                                        value={state.value_select}
                                        onChange={handleChangeFanpage}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Chọn Fanpage"
                                        />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <p className="m-t-0"><b>Chọn nhóm Page</b></p>
                                <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        fullWidth
                                        size="small"
                                        {...register('group_name')}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem value="-1">Chọn nhóm Page</MenuItem>
                                        {
                                            props.group_pages.map((groupName, index) => {
                                                return (
                                                    <MenuItem key={index} value={groupName}>
                                                        <ListItemText primary={'Nhóm ' + groupName} />
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <textarea {...register("content")} placeholder="Nội dung cập nhật ở đây..." className="form-control" rows={15}></textarea>
                            </Grid>
                            <Grid item xs={12}>
                                <p className="t-right m-t-0">
                                    {/* <Button onClick={() => setState({...state, number_url: state.number_url + 1})} type="button" className="text-transform" variant="contained" color="primary" startIcon={<AddRoundedIcon/>}>Thêm mới</Button> */}
                                    <Button onClick={() => setState({...state, dialog_file_manager: true})} type="button" className="text-transform" variant="contained" color="warning" startIcon={<AddRoundedIcon/>}>Thêm ảnh/video</Button>
                                </p>
                                {renderInput()}
                            </Grid>
                            <Grid item xs={12}>
                                <p className="m-t-0"><b>Thời gian đăng</b></p>
                                <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        fullWidth
                                        size="small"
                                        {...register('type_time')}
                                        defaultValue={state.time_post}
                                        onChange={onChangeTimeCreatePost}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem value="now">Ngay lập tức</MenuItem>
                                        <MenuItem value="custom">Tùy chỉnh</MenuItem>
                                </Select>
                            </Grid>
                            {
                                state.time_post == 'custom' &&
                                <Grid item xs={3}>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        fullWidth
                                        size="small"
                                        defaultValue={hour}
                                        {...register('hour')}
                                        MenuProps={MenuProps}
                                    >
                                        {getHours()}
                                    </Select>
                                </Grid>
                            }
                            {
                                state.time_post == 'custom' &&
                                <Grid item xs={3}>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        fullWidth
                                        size="small"
                                        defaultValue={minute}
                                        {...register('minute')}
                                        MenuProps={MenuProps}
                                    >
                                        {getMinutes()}
                                    </Select>
                                </Grid>
                            }
                            {
                                state.time_post == 'custom' &&
                                <Grid item xs={6}>
                                    <TextField type="date" {...register('date')} defaultValue={today} fullWidth size="small" id="outlined-basic" label="Ngày" variant="outlined" />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <p className="m-t-0">Cá nhân hóa:</p>
                                <ul>
                                    <li>
                                        {"{page_name}"}: Tên page
                                    </li>
                                </ul>
                            </Grid>
                            <Grid item xs={12}>
                                {/* <LoadingButton className="text-transform" type="submit" size="large" fullWidth loading={state.loading_submit} variant="contained">
                                    Xác nhận
                                </LoadingButton> */}
                                <Button className="text-transform" type="submit" size="large" fullWidth variant="contained">
                                    Xác nhận
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Index;