import LoadingButton from "@mui/lab/LoadingButton";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { useState } from "react";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Swal from "sweetalert2";
import api from "../../../../../Apis";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function FanpageByUser(props) {
    const { fanpage_selected, list_fanpage, cookie, user } = props;
    const [state, setState] = useState({
        fanpage_selected: fanpage_selected,
        fanpage_prev: fanpage_selected,
        list_fanpage: list_fanpage,
        is_loading: false,
        error: {}
    })

    const onCheckedFanpage = (e, data) => {
        setState({...state, fanpage_selected: data})
    }

    const fanpageDefault = [];
    state.list_fanpage.map((fanpageItem, index_parent) => {
        state.fanpage_selected.map((item, index) => {
            if (item.fanpage_id == fanpageItem.fanpage_id) {
                fanpageDefault.push(list_fanpage[index_parent]);
                state.list_fanpage[index_parent] = item;
            }
        })
    })

    const updateFanpageForUser = () => {
        if (state.fanpage_selected.length <= 0) {
            Swal.fire({
                title: "Vui lòng chọn Fanpage",
                timer: 1000,
                icon: "warning"
            })
            return
        }
        setState({...state, is_loading: true})
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                fanpages: state.fanpage_selected,
                user_id: user.user_id
            })
        };
        fetch(api.add_fanpage_to_user, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    Swal.fire({title: result.message, icon: "success", timer: 1000})
                    setState({...state, is_loading: false, error: {}})
                } else if (result.errors !== undefined) {
                    setState({...state, is_loading: false, error: result.errors})
                } else {
                    Swal.fire({title: result.message, icon: "warning", timer: 1000})
                    setState({...state, is_loading: false, error: {}})
                }
            }
        )
    }

    return (
        <div>
            <Autocomplete
                multiple
                helperText={state.error?.fanpages?.message}
                id="checkboxes-tags-demo"
                onChange={onCheckedFanpage}
                options={ list_fanpage }
                value={ fanpageDefault }
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.name  + ' (' + option.fanpage_id + ')'}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField error={state.error?.fanpages && true} helperText={state.error?.fanpages?.message} {...params} size="small" label="Chọn Fanpage muốn quản lý *" />
                )}
            />
            <br></br>
            <LoadingButton onClick={ updateFanpageForUser } loading={ state.is_loading } className="text-transform" variant='contained'>
                Cập nhật
            </LoadingButton>
        </div>
    )
}