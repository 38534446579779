import { useEffect, useState } from "react"
import api from './../../../../../Apis'
import Swal from "sweetalert2"

export default function InputNameBlock(props) {
    const [state, setState] = useState({
        block_active: {}
    })

    const changeNameBlock = (e) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                code: state.block_active.code,
                name: e.target.value
            })
        };
        fetch(api.block_update, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (!result.success) {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        timer: 1500
                    })
                } else {
                    props.updateNameBlockFromProps(e.target.value, state.block_active);
                }
            }
        )
    }

    const onChangeNameBlock = (e) => {
        const block_active = {...state.block_active};
        block_active.name = e.target.value;
        setState({
            ...state,
            block_active: block_active
        })
    }

    useEffect(() => {
        setState({
            ...state,
            block_active: props.block_active
        })
    }, [props.block_active])

    return (
        <input
            type="text"
            onBlur={(e) => changeNameBlock(e)}
            onChange={onChangeNameBlock}
            value={state.block_active.name}
            style={{fontSize: '30px'}}
        />
    )
}