import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Switch, Tab, Tabs, TextField, Tooltip } from "@mui/material"
import { useEffect, useState } from "react"
import Swal from "sweetalert2"
import api from './../../../../../Apis';
import { useForm } from "react-hook-form";
import InputNameBlock from "./InputNameBlock";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Box } from "@mui/system";
import TabPanel from '@mui/lab/TabPanel';
import TabContext from "@mui/lab/TabContext";
import ObjectText from "./../../../../../Assets/Images/text-format.png";
import ObjectPicture from "./../../../../../Assets/Images/picture.png";
import ObjectVideo from "./../../../../../Assets/Images/multimedia.png";
import ObjectGallery from "./../../../../../Assets/Images/gallery.png";
import ObjectUserEmail from "./../../../../../Assets/Images/user_email.png";
import ObjectUserPhone from "./../../../../../Assets/Images/telephone.png";
import ObjectUserInput from "./../../../../../Assets/Images/input.png";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileManager from "../../../../Globals/FileManager";
import ContentTemplateGallery from "./ContentTemplateGallery";
import ContentTemplateEmailPhone from "./ContentTemplateEmailPhone";
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import InputSaveConstant from "./InputSaveConstant";
import LinkChatbot from "./LinkChatbot";
import {requestOptions} from "./../../../../../Helpers/function.js";
import SwitchDefaultBlock from "./SwitchDefaultBlock";

export default function BuildScript(props) {
    const { register, handleSubmit, setValue, reset} = useForm();
    const [state, setState] = useState({
        block_active: {},
        list_blocks: [],
        dialog_button: false,
        value_tab: 'block',
        list_message_blocks: [],
        block_message_code: '',
        index_button_active: '',
        index_image_active: '',
        dialog_manage_image: false,
        is_default_block_active: false,
        type_add_button: '',
        index_block_message: '',
        index_gallery: ''
    })
    useEffect(() => {
        if (Object.keys(props.block_active).length > 0) {
            const body = props.block_active;
            body['fanpage_id'] = props.fanpage_id;
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.cookie
                },
                body: JSON.stringify(body)
            };
            fetch(api.list_message_block, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        setState({
                            ...state,
                            list_message_blocks: result.list_message_blocks,
                            block_active: props.block_active,
                            list_blocks: result.list_blocks,
                            is_default_block_active: result.is_default_block_active
                        })
                    }
                }
            )
        }
    }, [props.block_active])

    const onSaveButton = (data) => {
        if (data.button_name == '') {
            Swal.fire({
                title: "Cần nhập tên button",
                icon: "warning",
                timer: 1000
            })
        } else {
            if (state.type_add_button == 'template_text_button') {
                if (state.list_message_blocks[state.index_button_active].content.text == '') {
                    Swal.fire({
                        title: 'Cần nhập nội dung',
                        timer: 2000,
                        icon: 'warning'
                    })
                } else {
                    // data['text'] = state.list_message_blocks[state.index_button_active].content.text;
                    data['block_code'] = state.block_active.code;
                    data['block_message_code'] = state.block_message_code;
                    data['tab_active'] = state.value_tab;
                    data['type'] = 'button';
                    if (data.block == undefined) {
                        data['block'] = ''
                    }
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + props.cookie
                        },
                        body: JSON.stringify(data)
                    };
                    fetch(api.create_message_block, requestOptions)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result.success) {
                                const list_message_blocks = [...state.list_message_blocks];
                                list_message_blocks[state.index_button_active].content.buttons.push(result.new_button);
                                setState({
                                    ...state,
                                    dialog_button: false,
                                    block_message_code: '',
                                    index_button_active: '',
                                    list_message_blocks: list_message_blocks
                                })
                                reset();
                            } else {
                                Swal.fire({
                                    title: result.message,
                                    icon: 'error',
                                    timer: 1500
                                })
                            }
                        }
                    )
                }
            } else if (state.type_add_button == 'template_gallery') {
                const list_message_blocks = [...state.list_message_blocks];

                if (list_message_blocks[state.index_block_message].content[state.index_gallery].buttons.length >= 3) {
                    Swal.fire({
                        title: 'Tối đa được 3 button',
                        icon: 'warning',
                        timer: 1500
                    })
                } else {
                    const new_button = {
                        title: data.button_name,
                        url_web: data.url_web == undefined ? '' : data.url_web,
                        phone: data.phone == undefined ? '' : data.phone,
                        block: data.block
                    };
                    
                    list_message_blocks[state.index_block_message].content[state.index_gallery].buttons.push(new_button);
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + props.cookie
                        },
                        body: JSON.stringify({
                            index_gallery: state.index_gallery,
                            code: state.block_message_code,
                            new_button: new_button
                        })
                    };
                    fetch(api.create_button_for_template_gallery, requestOptions)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result.success) {
                                setState({
                                    ...state,
                                    list_message_blocks: list_message_blocks,
                                    dialog_button: false,
                                    index_gallery: '',
                                    index_block_message: ''
                                })
                                reset();
                            } else {
                                Swal.fire({
                                    title: result.message,
                                    icon: 'warning',
                                    timer: '1000'
                                })
                                setState({
                                    ...state,
                                    dialog_button: false,
                                    index_gallery: '',
                                    index_block_message: ''
                                })
                            }
                        }
                    )
                }
            }
        }
    }

    const onChangeTextTemplateButton = (e, index) => {
        const list_message_blocks = [...state.list_message_blocks];
        list_message_blocks[index].content.text = e.target.value;
        setState({
            ...state,
            list_message_blocks: list_message_blocks
        })
    }

    const updateTextTemplateButton = (data, index) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                code: data.code,
                text: state.list_message_blocks[index].content.text
            })
        };
        fetch(api.update_text_for_template_text_button, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (!result.success) {
                    Swal.fire({
                        title: result.message,
                        icon: 'warning',
                        timer: 1000
                    })
                }
            }
        )
    }

    const deleteBlockMessage = (index, data) => {
        Swal.fire({
            title: 'Bạn có chắc chắn muốn xóa?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Hủy'
        }).then((result) => {
            if (result.isConfirmed) {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.cookie
                    },
                    body: JSON.stringify({
                        code: data.code
                    })
                };
                fetch(api.delete_block_message, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (!result.success) {
                            Swal.fire({
                                title: result.message,
                                icon: 'error',
                                timer: 1500
                            })
                        } else {
                            const list_message_blocks = [...state.list_message_blocks];
                            list_message_blocks.splice(index, 1);
                            setState({
                                ...state,
                                list_message_blocks: list_message_blocks
                            })
                        }
                    }
                )
            }
        })
    }

    const add_template_text_button = () => {
        const new_obj = {
            name: "template_text_button",
            code: Math.random().toString().slice(2,10),
            block_code: state.block_active.code,
            status: 1,
            content: {
                text: "",
                buttons: []
            }
        }
        const list_message_blocks = [...state.list_message_blocks, new_obj];
        setState({
            ...state,
            list_message_blocks: list_message_blocks
        })
        add_template(new_obj, list_message_blocks, api.create_new_template_text_button);
    }

    const add_template = (new_obj, list_message_blocks, url_api) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify(new_obj)
        };
        fetch(url_api, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (!result.success) {
                    list_message_blocks.pop();
                    setState({
                        ...state,
                        list_message_blocks: list_message_blocks
                    })
                    Swal.fire({
                        title: result.message,
                        icon: 'warning',
                        timer: 1500
                    })
                }
            }
        )
    }

    const add_template_image = () => {
        const new_obj = {
            name: "template_image",
            code: Math.random().toString().slice(2,10),
            block_code: state.block_active.code,
            status: 1,
            content: {
                image: ""
            }
        }
        const list_message_blocks = [...state.list_message_blocks, new_obj];
        setState({
            ...state,
            list_message_blocks: list_message_blocks
        })
        add_template(new_obj, list_message_blocks, api.create_new_template_text_button);
    }

    const add_template_video = () => {
        const new_obj = {
            name: "template_video",
            code: Math.random().toString().slice(2,10),
            block_code: state.block_active.code,
            status: 1,
            content: {
                image: ""
            }
        }
        const list_message_blocks = [...state.list_message_blocks, new_obj];
        setState({
            ...state,
            list_message_blocks: list_message_blocks
        })
        add_template(new_obj, list_message_blocks, api.create_new_template_text_button);
    }

    const add_template_gallery = () => {
        const new_obj = {
            name: "template_gallery",
            code: Math.random().toString().slice(2,10),
            block_code: state.block_active.code,
            status: 1,
            content: [{
                image: "",
                title: "",
                description: "",
                url: "",
                buttons: []
            }]
        }
        const list_message_blocks = [...state.list_message_blocks, new_obj];
        setState({
            ...state,
            list_message_blocks: list_message_blocks
        })
        add_template(new_obj, list_message_blocks, api.create_new_template_text_button);
    }

    const deleteElementTemplateGallery = (index_block, index_gallery, data) => {
        Swal.fire({
            title: 'Bạn có chắc chắn muốn xóa?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Hủy'
        }).then((result) => {
            if (result.isConfirmed) {
                const list_message_blocks = [...state.list_message_blocks];
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.cookie
                    },
                    body: JSON.stringify({
                        code: data.code,
                        index: index_gallery
                    })
                };
                fetch(api.delete_element_template_gallery, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (!result.success) {
                            Swal.fire({
                                title: result.message,
                                icon: 'warning',
                                timer: 1000
                            })
                        } else {
                            list_message_blocks[index_block].content.splice(index_gallery, 1);
                            setState({
                                ...state,
                                list_message_blocks: list_message_blocks
                            })
                        }
                    }
                )
            }
        })
    }

    const addElementTemplateGallery = (index, data) => {
        const new_obj = {
            image: "",
            title: "",
            description: "",
            url: "",
            buttons: []
        }
        const list_message_blocks = [...state.list_message_blocks];
        list_message_blocks[index].content.push(new_obj);
        if (list_message_blocks[index].content.length <= 10) {
            setState({
                ...state,
                list_message_blocks: list_message_blocks
            })
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.cookie
                },
                body: JSON.stringify({
                    code: data.code,
                    element: new_obj
                })
            };
            fetch(api.add_element_template_gallery, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (!result.success) {
                        list_message_blocks[index].content.pop();
                        setState({
                            ...state,
                            list_message_blocks: list_message_blocks
                        })
                        Swal.fire({
                            title: result.message,
                            icon: 'warning',
                            timer: 1000
                        })
                    }
                }
            )
        } else {
            Swal.fire({
                title: 'Chỉ được phép tối đa 10 ảnh trong 1 Gallery',
                icon: 'warning',
                timer: 1000
            })
        }
    }

    const add_template_mail_phone = (name, constant) => {
        var title = '';
        if (name == 'template_mail') {
            var title = "Email của bạn là?";
        } else if (name == 'template_phone') {
            var title = "Số điện thoại của bạn là?";
        }
        const new_obj = {
            name: name,
            code: Math.random().toString().slice(2,10),
            block_code: state.block_active.code,
            constant: constant,
            content: {
                title: title
            },
            status: 1
        }
        const list_message_blocks = [...state.list_message_blocks, new_obj];
        setState({
            ...state,
            list_message_blocks: list_message_blocks
        })
        add_template(new_obj, list_message_blocks, api.create_new_template_text_button);
    }

    const addObject = (name) => {
        if (name == 'template_text_button') {
            add_template_text_button();
        } else if (name == 'template_image') {
            add_template_image();
        } else if (name == 'template_video') {
            add_template_video();
        } else if (name == 'template_gallery') {
            add_template_gallery();
        } else if (name == 'template_mail' || name == 'template_phone' || name == 'template_user_input') {
            var constant = null;
            if (name == 'template_mail') {
                var constant = '{email}';
            } else if (name == 'template_phone') {
                var constant = '{phone}';
            }
            add_template_mail_phone(name, constant);
        }
    }

    const openDialogFileManager = (index) => {
        setState({
            ...state,
            index_image_active: index,
            dialog_manage_image: !state.dialog_manage_image
        })
    }

    const responseUrlImage = (data) => {
        const list_message_blocks = [...state.list_message_blocks];
        list_message_blocks[state.index_image_active].content.image = data;
        const code = list_message_blocks[state.index_image_active].code;
        setState({
            ...state,
            list_message_blocks: list_message_blocks,
            dialog_manage_image: false
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                code: code,
                image: data
            })
        };
        fetch(api.update_template_image, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (!result.success) {
                    list_message_blocks[state.index_image_active].content.image = null;
                    setState({
                        ...state,
                        index_image_active: '',
                        list_message_blocks: list_message_blocks,
                        dialog_manage_image: false
                    })
                    Swal.fire({
                        title: result.message,
                        icon: 'warning',
                        timer: 1500
                    })
                } else {
                    setState({
                        ...state,
                        index_image_active: '',
                        dialog_manage_image: false
                    })
                }
            }
        )
    }

    const changeLinkVideo = (e, index) => {
        const list_message_blocks = [...state.list_message_blocks];
        list_message_blocks[index].content.image = e.target.value;
        setState({
            ...state,
            list_message_blocks: list_message_blocks
        })
    }

    const updateLinkVideo = (index, data) => {
        const list_message_blocks = [...state.list_message_blocks];
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                code: data.code,
                image: list_message_blocks[index].content.image
            })
        };
        fetch(api.update_template_image, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (!result.success) {
                    list_message_blocks[index].content.image = null;
                    setState({
                        ...state,
                        list_message_blocks: list_message_blocks,
                    })
                    Swal.fire({
                        title: result.message,
                        icon: 'warning',
                        timer: 1500
                    })
                }
            }
        )
    }

    const redirectThisBot = () => {
        window.open("https://m.me/" + props.fanpage_id + "?ref=" + props.block_active.code);
    }

    const compare = (a,b) => {
        if ( a.ordinal_number < b.ordinal_number ){
            return -1;
        }
        if ( a.ordinal_number > b.ordinal_number ){
            return 1;
        }
        return 0;
    }

    const up = (index) => {
        if (index == 0) {
            Swal.fire({
                title: "Đã ở vị trí đầu tiên",
                icon: "warning",
                timer: 1000
            })
        } else {
            const list_message_blocks = [...state.list_message_blocks];
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.cookie
                },
                body: JSON.stringify({
                    code_up: list_message_blocks[index - 1].code,
                    code_down: list_message_blocks[index].code,
                    code: list_message_blocks[index - 1].code
                })
            };
            fetch(api.sort_block_message, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        const ordinal_up = list_message_blocks[index - 1].ordinal_number;
                        const ordinal_down = list_message_blocks[index].ordinal_number;
                        list_message_blocks[index].ordinal_number = ordinal_up;
                        list_message_blocks[index - 1].ordinal_number = ordinal_down;
                        setState({
                            ...state,
                            list_message_blocks: list_message_blocks.sort(compare)
                        })
                    } else {
                        Swal.fire({
                            title: result.message,
                            icon: 'warning',
                            timer: 1500
                        })
                    }
                }
            )
        }
    }

    const down = (index) => {
        if (index == state.list_message_blocks.length - 1) {
            Swal.fire({
                title: "Đã ở vị trí cuối cùng",
                icon: "warning",
                timer: 1000
            })
        } else {
            const list_message_blocks = [...state.list_message_blocks];
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.cookie
                },
                body: JSON.stringify({
                    code_up: list_message_blocks[index].code,
                    code_down: list_message_blocks[index + 1].code,
                    code: list_message_blocks[index].code
                })
            };
            fetch(api.sort_block_message, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        const ordinal_down = list_message_blocks[index + 1].ordinal_number;
                        const ordinal_up = list_message_blocks[index].ordinal_number; 
                        list_message_blocks[index].ordinal_number = ordinal_down;
                        list_message_blocks[index + 1].ordinal_number = ordinal_up;
                        setState({
                            ...state,
                            list_message_blocks: list_message_blocks.sort(compare)
                        })
                    } else {
                        Swal.fire({
                            title: result.message,
                            icon: 'warning',
                            timer: 1500
                        })
                    }
                }
            )
        }
    }

    const removeButtonGalleryTempalte = (indexButton, block_message_code, index_gallery, index_block) => {
        Swal.fire({
            title: 'Bạn có chắc chắn muốn xóa?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Hủy'
        }).then((result) => {
            if (result.isConfirmed) {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.cookie
                    },
                    body: JSON.stringify({
                        index_button: indexButton,
                        index_gallery: index_gallery,
                        block_message_code: block_message_code
                    })
                };
                fetch(api.delete_button_in_templete, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            const list_message_block = [...state.list_message_blocks];
                            list_message_block[index_block].content[index_gallery].buttons.splice(indexButton, 1);
                            setState({
                                ...state,
                                list_message_blocks: list_message_block
                            })
                        } else {
                            Swal.fire({
                                title: result.message,
                                icon: 'error',
                                timer: 1000
                            })
                        }
                    }
                )
            }
        })
    }

    const removeButtonTempalteButton = (indexButton, block_message_code, index_block) => {
        Swal.fire({
            title: 'Bạn có chắc chắn muốn xóa?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Hủy'
        }).then((result) => {
            if (result.isConfirmed) {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.cookie
                    },
                    body: JSON.stringify({
                        index_button: indexButton,
                        block_message_code: block_message_code,
                    })
                };
                fetch(api.delete_button_in_templete, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            const list_message_block = [...state.list_message_blocks];
                            list_message_block[index_block].content.buttons.splice(indexButton, 1);
                            setState({
                                ...state,
                                list_message_blocks: list_message_block
                            })
                        } else {
                            Swal.fire({
                                title: result.message,
                                icon: 'error',
                                timer: 1000
                            })
                        }
                    }
                )
            }
        })
    }

    const changeStatusTemplate = (e, code) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                code: code,
                status: e.target.checked,
            })
        };
        fetch(api.change_status_block_message, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (!result.success) {
                    Swal.fire({
                        title: result.message,
                        timer: 1000,
                        icon: 'error'
                    })
                }
            }
        )
    }

    return (
        <Grid item xs={6}>
            {
                props.block_active?.code != undefined &&
                <div>
                    <p style={{justifyContent: 'space-between', display: 'flex'}}>
                        <SwitchDefaultBlock
                            block_active={props.block_active}
                            key={props.block_active.code}
                            cookie={props.cookie}
                            is_default_block_active={state.is_default_block_active}
                        />
                        <Button
                            onClick={redirectThisBot}
                            variant="contained"
                            className="text-transform"
                        >
                            Test this bot
                        </Button>
                    </p>
                    <h3>
                        <InputNameBlock
                            updateNameBlockFromProps={(new_name, data) => props.updateNameBlockFromProps(new_name, data)}
                            cookie={props.cookie}
                            block_active={state.block_active}
                        />
                    </h3>
                    <p>
                        Cá nhân hóa: <span className="const">{'{full_name}'}</span> => Tên khách hàng, <span className="const">{'{page_name}'}</span> => Tên Page
                    </p>
                    <p>
                        Sử dụng spin content trong văn bản để tránh bị spam, <b>ví dụ:</b> Chào {'{full_name}'}, bạn [hãy|vui lòng] [check|kiểm tra] [inbox|tin nhắn] để [được tư vấn|trao đổi trực tiếp] nhé
                    </p>

                    <LinkChatbot
                        key={state.block_active.code}
                        block_active={state.block_active}
                        fanpage_id={props.fanpage_id}
                        cookie={props.cookie}
                    />
                    {
                        state.list_message_blocks.map((blockMessageItem, indexBlockMessage) => {
                            return (
                                <Grid container spacing={2}>
                                    <Grid item xs={10}>
                                        {
                                            blockMessageItem.name == 'template_text_button' &&
                                            <div className="block">
                                                <div className="message-item">
                                                    <textarea onChange={(e) => onChangeTextTemplateButton(e, indexBlockMessage)} onBlur={() => updateTextTemplateButton(blockMessageItem, indexBlockMessage)} placeholder="Nhập tin nhắn của bạn..." className="textarea" cols="30" rows="6" value={blockMessageItem.content.text}></textarea>
                                                    <ul className="list-button">
                                                    {
                                                        blockMessageItem.content.buttons.length > 0 &&
                                                        blockMessageItem.content.buttons.map((buttonItem, indexButton) => {
                                                            return (
                                                                <li>
                                                                    {buttonItem.title}<br></br>
                                                                    {
                                                                        buttonItem.url_web != '' &&
                                                                        <small>{buttonItem.url_web}</small>
                                                                    }
                                                                    {
                                                                        buttonItem.phone != '' &&
                                                                        <small>{buttonItem.phone}</small>
                                                                    }
                                                                    {
                                                                        buttonItem.block != null &&
                                                                        <small>
                                                                            {buttonItem.block.name}
                                                                        </small>
                                                                    }
                                                                    <br></br>
                                                                    <small>
                                                                        <DeleteOutlineOutlinedIcon
                                                                            color="error"
                                                                            size="small"
                                                                            className="cursor"
                                                                            onClick={() => removeButtonTempalteButton(indexButton, blockMessageItem.code, indexBlockMessage)}
                                                                        />
                                                                    </small>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                    </ul>
                                                    <center style={{marginTop: '10px'}}>
                                                        <Button
                                                            onClick={
                                                                () => setState({
                                                                    ...state,
                                                                    dialog_button: true,
                                                                    block_message_code: blockMessageItem.code,
                                                                    index_button_active: indexBlockMessage,
                                                                    type_add_button: 'template_text_button'
                                                            })}
                                                            startIcon={<AddOutlinedIcon/>} className="text-transform" variant="outlined">Thêm nút</Button>
                                                    </center>
                                                </div>
                                            </div>
                                        }
                                        {
                                            blockMessageItem.name == 'template_image' &&
                                            <div onClick={() => openDialogFileManager(indexBlockMessage)} className="block">
                                                <div className="cursor message-item t-center">
                                                    {
                                                        (blockMessageItem.content.image != null && blockMessageItem.content.image != '') &&
                                                        <div>
                                                            <img width="100%" src={(blockMessageItem.content.image != null && blockMessageItem.content.image != '') && blockMessageItem.content.image}></img>
                                                            <br></br>
                                                        </div>
                                                    }
                                                    <img src={ObjectPicture}></img><br></br>
                                                    Chọn ảnh
                                                </div>
                                            </div>
                                        }
                                        {
                                            blockMessageItem.name == 'template_video' &&
                                            <div className="block">
                                                <div className="message-item">
                                                    <b>Từ Facebook: </b>Tải video lên Fanpage và copy link video
                                                    <p className="m-t-0">Ví dụ: https://www.facebook.com/183657172135532/videos/1089175175302093/</p>
                                                    <TextField
                                                        value={blockMessageItem.content.image != null ? blockMessageItem.content.image : ''}
                                                        margin="dense"
                                                        id="name"
                                                        label="Link video"
                                                        type="text"
                                                        fullWidth
                                                        variant="outlined"
                                                        autoComplete="off"
                                                        onChange={(e) => changeLinkVideo(e, indexBlockMessage)}
                                                        onBlur={() => updateLinkVideo(indexBlockMessage, blockMessageItem)}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            blockMessageItem.name == 'template_gallery' &&
                                            <div className="block" style={{overflowX:'scroll', whiteSpace: 'nowrap', marginBottom: '20px'}}>
                                                <p className="m-t-0">
                                                    Gallery - Sản phẩm quay vòng: 1-10 sản phẩm
                                                </p>
                                                    {
                                                        blockMessageItem.content.map((blockGalleryItem, key_gallery) => {
                                                            return (
                                                                    <div className="message-item" style={{display: 'inline-block', width: '50%', marginRight: '20px'}}>
                                                                        <p className="t-right m-b-0 m-t-0">
                                                                            <DeleteOutlineOutlinedIcon onClick={() => deleteElementTemplateGallery(indexBlockMessage, key_gallery, blockMessageItem)} className="cursor" color="error"/>
                                                                            <AddOutlinedIcon onClick={() => addElementTemplateGallery(indexBlockMessage, blockMessageItem)} className="cursor" color="primary"/>
                                                                        </p>
                                                                        <ContentTemplateGallery
                                                                            data={blockGalleryItem}
                                                                            key_gallery={key_gallery}
                                                                            key={key_gallery}
                                                                            code={blockMessageItem.code}
                                                                            cookie={props.cookie}
                                                                            fanpage_id={props.fanpage_id}
                                                                        />
                                                                        <br></br>
                                                                        <ul className="list-button">
                                                                        {
                                                                            blockGalleryItem.buttons.length > 0 &&
                                                                            blockGalleryItem.buttons.map((buttonItem, indexButton) => {
                                                                                return (
                                                                                    <li>
                                                                                        {buttonItem.title}<br></br>
                                                                                        {
                                                                                            buttonItem.url_web != '' &&
                                                                                            <small>{buttonItem.url_web}</small>
                                                                                        }
                                                                                        {
                                                                                            buttonItem.phone != '' &&
                                                                                            <small>{buttonItem.phone}</small>
                                                                                        }
                                                                                        {
                                                                                            buttonItem.block != null &&
                                                                                            <small>{buttonItem.block.name}</small>
                                                                                        }
                                                                                        <br></br>
                                                                                        <small>
                                                                                            <DeleteOutlineOutlinedIcon
                                                                                                color="error"
                                                                                                size="small"
                                                                                                className="cursor"
                                                                                                onClick={() => removeButtonGalleryTempalte(indexButton, blockMessageItem.code, key_gallery, indexBlockMessage)}
                                                                                            />
                                                                                        </small>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                        </ul>
                                                                        <center style={{marginTop: '10px'}}>
                                                                            <Button
                                                                                onClick={
                                                                                    () => setState({
                                                                                        ...state,
                                                                                        dialog_button: true,
                                                                                        block_message_code: blockMessageItem.code,
                                                                                        type_add_button: 'template_gallery',
                                                                                        index_block_message: indexBlockMessage,
                                                                                        index_gallery: key_gallery
                                                                                })}
                                                                                startIcon={<AddOutlinedIcon/>} className="text-transform" variant="outlined">Thêm nút</Button>
                                                                        </center>
                                                                    </div>
                                                            )
                                                        })
                                                    }
                                            </div>
                                        }
                                        {
                                            (blockMessageItem.name == 'template_mail' || blockMessageItem.name == 'template_phone') && 
                                            <div className="block">
                                                <div className="message-item">
                                                    <p className="m-t-0">
                                                        User {blockMessageItem.name == 'template_mail' ? 'Email' : 'Phone'} - Hỏi {blockMessageItem.name == 'template_mail' ? 'Email' : 'số điện thoại'} của khách
                                                    </p>
                                                    <ContentTemplateEmailPhone
                                                        data={blockMessageItem}
                                                        key={indexBlockMessage}
                                                        cookie={props.cookie}
                                                        fanpage_id={props.fanpage_id}
                                                    />
                                                    <p className="m-b-0">
                                                        Lưu {blockMessageItem.name == 'template_mail' ? 'email' : 'số điện thoại'} vào biến <span style={{background: "#910a9a", 'color': '#fff'}}>{blockMessageItem.name == 'template_mail' ? '{email}' : '{phone}'}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                        {
                                            blockMessageItem.name == 'template_user_input' &&
                                            <div className="block">
                                                <div className="message-item">
                                                    <p className="m-t-0">
                                                        User Input - Đặt câu hỏi cho người dùng và lưu câu trả lời của họ
                                                    </p>
                                                    <ContentTemplateEmailPhone
                                                        data={blockMessageItem}
                                                        key={indexBlockMessage}
                                                        cookie={props.cookie}
                                                        fanpage_id={props.fanpage_id}
                                                    />
                                                    <InputSaveConstant
                                                        data={blockMessageItem}
                                                        key={indexBlockMessage}
                                                        cookie={props.cookie}
                                                        fanpage_id={props.fanpage_id}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button onClick={() => deleteBlockMessage(indexBlockMessage, blockMessageItem)} variant="contained" color="error" className="text-transform" startIcon={<DeleteOutlineOutlinedIcon/>}>
                                            Xóa
                                        </Button><br></br>
                                        <p className="t-center">
                                            <Tooltip onClick={() => up(indexBlockMessage)} title="Lên" arrow placement="top">
                                                <ArrowUpwardOutlinedIcon className="cursor icon"/>
                                            </Tooltip>
                                            <Tooltip onClick={() => down(indexBlockMessage)} title="Xuống" arrow placement="top">
                                                <ArrowDownwardOutlinedIcon className="cursor icon"/>
                                            </Tooltip>
                                        </p>
                                        <br></br>
                                        <Switch 
                                            onClick={(e) => changeStatusTemplate(e, blockMessageItem.code)}
                                            defaultChecked={blockMessageItem.status}
                                        />
                                        {/* <Button style={{width: '100px', marginTop: '10px'}} variant="contained" color="primary" className="text-transform" startIcon={<ArrowUpwardOutlinedIcon/>}>Lên</Button><br></br>
                                        <Button style={{width: '100px', marginTop: '10px'}} variant="contained" color="warning" className="text-transform" startIcon={<ArrowDownwardOutlinedIcon/>}>Xuống</Button> */}
                                    </Grid>
                                </Grid>
                            )
                        })                
                    }
                    {
                        props.block_active?.code != undefined &&
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <h3 className="m-b-0">Thêm đối tượng</h3>
                                </Grid>
                                <Grid item xs={12}>
                                    <ul className="list-object-chatbot">
                                        <li onClick={() => addObject('template_text_button')}>
                                            <img src={ObjectText}></img><br></br>
                                            Văn bản
                                        </li>
                                        <li onClick={() => addObject('template_image')}>
                                            <img src={ObjectPicture}></img><br></br>
                                            Hình ảnh
                                        </li>
                                        {/* <li onClick={() => addObject('template_video')}>
                                            <img src={ObjectVideo}></img><br></br>
                                            Video
                                        </li> */}
                                        <li onClick={() => addObject('template_gallery')}>
                                            <img src={ObjectGallery}></img><br></br>
                                            Gallery
                                        </li>
                                        <li onClick={() => addObject('template_mail')}>
                                            <img src={ObjectUserEmail}></img><br></br>
                                            User Email
                                        </li>
                                        <li onClick={() => addObject('template_phone')}>
                                            <img src={ObjectUserPhone}></img><br></br>
                                            User Phone
                                        </li>
                                        <li onClick={() => addObject('template_user_input')}>
                                            <img src={ObjectUserInput}></img><br></br>
                                            User Input
                                        </li>
                                    </ul>
                                </Grid>
                            </Grid>
                    }
                    <Dialog open={state.dialog_button} fullWidth="true" maxWidth='sm' onClose={() => {reset();setState({...state, block_message_code: '', dialog_button: false, index_button_active: ''})}}>
                        <DialogTitle>Thêm nút</DialogTitle>
                        <DialogContent>
                            <form onSubmit={handleSubmit(onSaveButton)}>
                                <TextField
                                    size="small"
                                    autoComplete="off"
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Nhập tên nút"
                                    {...register('button_name')}
                                    fullWidth
                                    variant="outlined"
                                />
                                <TabContext value={state.value_tab}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={state.value_tab} onChange={(e, newValue) => setState({...state, value_tab: newValue})} aria-label="basic tabs example">
                                            <Tab label="Block" value="block"/>
                                            <Tab label="Url" value="url_web"/>
                                            <Tab label="Phone" value="phone"/>
                                            {/*<Tab label="Cart" value="cart"/> */}
                                        </Tabs>
                                    </Box>
                                    <TabPanel value="block" style={{padding: '10px 0px'}}>
                                        <FormControl fullWidth margin="dense">
                                            <InputLabel id="demo-simple-select-label">Chọn block</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Chọn block"
                                                {...register('block')}
                                            >
                                                {
                                                    state.list_blocks.map((blockItem) => {
                                                        return (
                                                            <MenuItem value={blockItem.code}>
                                                                {blockItem.name}
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </TabPanel>
                                    <TabPanel value="url_web" style={{padding: '10px 0px'}}>
                                        <TextField
                                            size="small"
                                            {...register('url_web')}
                                            placeholder="http://"
                                            autoComplete="off"
                                            margin="dense"
                                            label="Link web"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </TabPanel>
                                    <TabPanel value="phone" style={{padding: '10px 0px'}}>
                                        <TextField
                                            size="small"
                                            margin="dense"
                                            label="Số điện thoại"
                                            {...register('phone')}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </TabPanel>
                                    <TabPanel style={{padding: '10px 0px'}} value="buy">
                                        Buy
                                    </TabPanel>
                                    <TabPanel style={{padding: '10px 0px'}} value="cart">
                                        Cart
                                    </TabPanel>
                                </TabContext>
                                <DialogActions>
                                    <Button onClick={() => setState({...state, dialog_button: false})}>Hủy</Button>
                                    <Button type="submit">Xác nhận</Button>
                                </DialogActions>
                            </form>
                        </DialogContent>
                    </Dialog>
                    {
                        props.block_active?.code != undefined &&
                        <Dialog
                                fullWidth="true"
                                maxWidth='lg'
                                open={state.dialog_manage_image}
                                onClose={() => setState({...state, dialog_manage_image: false})}
                            >
                            <FileManager
                                cookie={props.cookie}
                                fanpage_id={props.fanpage_id}
                                responseUrlImage={responseUrlImage}
                            />
                        </Dialog>
                    }
                </div>
            }
        </Grid>
    )
}