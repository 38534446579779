import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material"
import { useEffect, useState, useRef } from "react"
import UploadRoundedIcon from '@mui/icons-material/UploadRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import AddLinkRoundedIcon from '@mui/icons-material/AddLinkRounded';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import api from './../../Apis';
import SendRoundedIcon from '@mui/icons-material/SendRounded';

export default function FileManager(props) {
    const [state, setState] = useState({
        dialog_manage_image: false,
        attachments: [],
        dialog_link: false
    })
    const inputFile = useRef(null);
    const {register, handleSubmit, reset, getValues, setValue} = useForm();

    useEffect(() => {
        setState({
            ...state,
            dialog_manage_image: props.dialog_manage_image
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                fanpage_id: props.fanpage_id,
            })
        };
        fetch(api.get_attachment, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        attachments: result.datas,
                    })
                }
            }
        )
    }, [props.dialog_manage_image])

    const onSendImage = (data) => {
        if (Array.isArray(data.links) && data.links.length > 0) {
            const id = parseInt(data.links[0]);
            for (var i = 0; i < state.attachments.length; i++) {
                if (id == state.attachments[i].id) {
                    props.responseUrlImage(state.attachments[i].link);
                    break;
                }
            }
        } else {
            Swal.fire({
                title: 'Vui lòng chọn ảnh',
                icon: 'warning',
                timer: '1500'
            })
        }
    }

    const selectFile = (e) => {
        inputFile.current.click();
    }

    const deleteImage = (id, index) => {
        Swal.fire({
            title: 'Bạn có chắc chắn muốn xóa?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Hủy'
        }).then((result) => {
            if (result.isConfirmed) {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.cookie
                    },
                    body: JSON.stringify({
                        id: id,
                        fanpage_id: props.fanpage_id
                    })
                };
                fetch(api.delete_file, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            const copy_attachments = [...state.attachments];
                            copy_attachments.splice(index, 1);
                            setState({...state,
                                        attachments: copy_attachments
                                    });
                            Swal.fire({
                                title: result.message,
                                icon: 'success',
                                confirmButtonText: 'OK'
                            })
                        } else {
                            Swal.fire({
                                title: result.message,
                                icon: 'error',
                                confirmButtonText: 'OK'
                            })
                        }
                    }
                )
            }
        })
    }

    const onChangeFile = (e) => {
        const file = e.target.files[0];
        if (file.size/1024 > 25600) {
            Swal.fire({
                title: "Yêu cầu file tối đa 25MB",
                icon: 'warning',
                confirmButtonText: 'Thoát'
            })
        } else {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('fanpage_id', props.fanpage_id);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + props.cookie
                },
                body: formData
            };
            fetch(api.upload_attachment, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success == true) {
                        setState({...state, attachments: [result.datas, ...state.attachments]});
                    } else if (result.success == false && result.type == 'validate') {
                        if (result.errors.length > 0) {
                            var string_error = '';
                            result.errors.map((value, key) => {
                                string_error = string_error + '<p>' + value + '</p>';
                            })
                            Swal.fire({
                                title: string_error,
                                icon: 'warning',
                                confirmButtonText: 'Thoát'
                            })
                        }
                    } else if (result.success == false && result.type == 'error') {
                        Swal.fire({
                            title: result.message,
                            icon: 'error',
                            confirmButtonText: 'Thoát'
                        })
                    }
                }
            )
        }
    }

    const handleChangeKeySearch = (e) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                fanpage_id: props.fanpage_id,
                name_file: e.target.value
            })
        };
        fetch(api.search_name_file, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({...state, attachments: result.datas});
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                }
            }
        )
    }

    const onUploadImageByLink = (data) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                fanpage_id: props.fanpage_id,
                url: data.url
            })
        };
        fetch(api.upload_url, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({...state,
                                dialog_link: false,
                                attachments: [
                                    result.datas,
                                    ...state.attachments
                                ]
                            });
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                }
            }
        )
    }

    return (
        <div>
            <input style={{display: "none"}} ref={inputFile} onChange={onChangeFile} type="file" className='file'/>
            <Dialog open={state.dialog_link} fullWidth="true" maxWidth='sm' onClose={() => {setState({...state, dialog_link: false})}}>
                <DialogTitle>Nhập link ảnh</DialogTitle>
                <form onSubmit={handleSubmit(onUploadImageByLink)}>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Link ảnh"
                            type="text"
                            fullWidth
                            variant="standard"
                            {...register('url')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {setState({...state, dialog_link: false})}}>Đóng</Button>
                        <Button type="submit">Xác nhận</Button>
                    </DialogActions>
                </form>
            </Dialog>
            <DialogTitle sx={{display: "flex", justifyContent: "space-between"}}>
                Ảnh của shop
            </DialogTitle>
            <DialogContent sx={{overflowY: "unset"}}>
                <Button onClick={selectFile} sx={{
                    marginRight: '20px'
                    }}
                    variant="outlined" color="primary" startIcon={<UploadRoundedIcon />}>
                    Tải ảnh lên
                </Button>
                <Button sx={{
                    marginRight: '20px'
                    }} onClick={() => {setState({...state, input_link: true, dialog_link: true})}} variant="outlined" color="success" startIcon={<AddLinkRoundedIcon />}>
                    Từ URL
                </Button>
                <TextField size="small" id="outlined-basic" onChange={handleChangeKeySearch} label="Tìm kiếm tên ảnh" variant="outlined"></TextField>
            </DialogContent>
            <form onSubmit={handleSubmit(onSendImage)}>
                <DialogContent>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            {
                                state.attachments.map((value, key) => {
                                    return (
                                        <Grid key={key} item xs={2} sx={{position: 'relative'}}>
                                            <input {...register('links')} style={{position: 'absolute', right: '0px', borderRadius: 'unset'}} type="checkbox" name="links" value={value.id}></input>
                                            <div className='block-image'>
                                                <img className='lib-image' src={value.link}></img>
                                                <p className='name'>
                                                    {value.name}
                                                </p>
                                                <p className='m-b-0'>
                                                    <Button onClick={() => deleteImage(value.id, key) } color='error' startIcon={<DeleteRoundedIcon/>} size='small' variant="contained">Xóa</Button>
                                                </p>
                                            </div>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button type="submit" sx={{margin: "20px 0px 0px 0px", width: '100%'}} startIcon={<SendRoundedIcon />} variant="contained" color="primary">
                                    Xác nhận
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    {/* <button type='submit' ref={buttonSubmit} style={{display: 'none'}}>Submit</button> */}
                </DialogContent>
                {/* <Button sx={{margin: "10px 20px 20px 20px"}} type="submit" startIcon={<SendRoundedIcon />} variant="contained" color="primary">
                    Xác nhận
                </Button> */}
            </form>
        </div>
    )
}