import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            <a className="a-none" style={{marginRight: '10px', color: 'unset'}} href={`https://${ process.env.REACT_APP_DOMAIN }`} target="_blank">Trang chủ</a>
            <Link className="a-none" style={{marginRight: '10px', color: 'unset'}} to="/">Đăng nhập</Link>
            <br></br><br></br>
            {'Copyright © '} { process.env.REACT_APP_NAME } 2022
        </Typography>
    );
}

export default Copyright;