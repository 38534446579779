import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
    useNavigate, useParams, Link
} from "react-router-dom";
import api from '../../../Apis';
import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';
import Swal from "sweetalert2";
import LoadingButton from "@mui/lab/LoadingButton";
import Copyright from "../Copyright/Index";
import {domain_origin} from './../../../Helpers/function.js'

const theme = createTheme();

function ResetPassword() {
    let navigate = useNavigate();
    const [state, setState] = useState({
        error: {},
        loading_login: false
    })
    const { token } = useParams();
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setState({
            ...state,
            loading_login: true
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                password: data.get('password'),
                password_confirmation: data.get('password_confirmation'),
                token: token
            })
        };
        fetch(api.reset_password, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                    })
                    setState({
                        ...state,
                        loading_login: false
                    })
                    return navigate('/');
                } else {
                    if (result.type == 'validate') {
                        setState({
                            ...state,
                            error: result.errors,
                            loading_login: false
                        })
                    } else if (result.type == 'lose') {
                       setState({
                           ...state,
                           error: {},
                           loading_login: false
                       })
                       Swal.fire({
                           title: result.message,
                           icon: 'error',
                           timer: 2000
                       })
                    }
                }
            }
        )
    };

    const domain = window.location.origin;

    if (domain != domain_origin) {
        window.location.href = domain_origin + '/forget-password';
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    {/* <Avatar> */}
                        {/* <LockOutlinedIcon /> */}

                        <img src={`https://${ process.env.REACT_APP_DOMAIN }/assets/img/logo/logo.png`} style={{width: '70%'}} className='logo'></img>
                    {/* </Avatar> */}
                    <Typography component="h1" variant="h5">
                        Lấy lại mật khẩu
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            label="Mật khẩu mới"
                            autoComplete="off"
                            name="password"
                            type="password"
                            helperText={state.error.password?.message}
                            error={state.error.password?.status}
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            type="password"
                            id="password"
                            label="Xác nhận mật khẩu mới"
                            autoComplete="off"
                            name="password_confirmation"
                            helperText={state.error.password?.message}
                            error={state.error.password?.status}
                            autoFocus
                        />
                        <LoadingButton loading={state.loading_login}
                            type="submit"
                            fullWidth
                            variant="contained"
                            size="large"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Xác nhận
                        </LoadingButton>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    )
}

export default ResetPassword;