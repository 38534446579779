import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import ReplayIcon from '@mui/icons-material/Replay';
import { useForm } from "react-hook-form";
import { convertNumber } from "../../../../../Helpers/function";
import { useState } from "react";

export default function FormSearch(props) {
    const {register, handleSubmit, reset, control, setValue} = useForm();
    const [state, setState] = useState({
        type_time: 'all'
    })

    const searchCustomer = (data) => {
        props.searchCustomer(data);
    }

    const refresh = () => {
        reset();
        setState({
            ...state,
            type_time: 'all'
        })
        props.refresh();
    }

    const onChangeTypeTime = (e) => {
        setState({
            ...state,
            type_time: e.target.value
        })
    }

    const date_start = new Date().getFullYear() + '-' + convertNumber(parseInt(1 + new Date().getMonth())) + '-01';
    const date_end = new Date().getFullYear() + '-' + convertNumber(parseInt(1 + new Date().getMonth())) + '-' + convertNumber(new Date().getDate());

    return (
        <form onSubmit={handleSubmit(searchCustomer)}>
            <Grid container spacing={2}>
                <Grid item xs={2} md={2}>
                    <TextField
                        id="outlined-select-currency"
                        size="small"
                        fullWidth
                        placeholder="Tìm tên, sđt, địa chỉ..."
                        label="Từ khóa"
                        {...register('keyword')}
                    >
                    </TextField>
                </Grid>
                <Grid item xs={2} md={2}>
                    <FormControl size="small" fullWidth>
                        <InputLabel id="demo-simple-select-label">Chọn thời gian</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.type_time}
                            label="Chọn thời gian"
                            {...register('type_time')}
                            onChange={onChangeTypeTime}
                        >
                            <MenuItem value="all">
                                Tất cả thời gian
                            </MenuItem>
                            <MenuItem value="custom">
                                Tùy chỉnh
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {
                    state.type_time == 'custom' &&
                    <Grid item xs={2} md={2}>
                        <TextField {...register('date_start')} defaultValue={date_start} fullWidth type="date" size="small" id="outlined-basic" label="Nhắn từ ngày" variant="outlined" />
                    </Grid>
                }
                {
                    state.type_time == 'custom' &&
                    <Grid item xs={2} md={2}>
                        <TextField {...register('date_end')} defaultValue={date_end} fullWidth type="date" size="small" id="outlined-basic" label="Đến ngày" variant="outlined" />
                    </Grid>
                }
                <Grid item xs={2} md={2}>
                    <TextField
                        id="outlined-select-currency"
                        select
                        size="small"
                        fullWidth
                        label="Số điện thoại"
                        defaultValue="-1"
                        {...register('phone')}
                    >
                        <MenuItem value="-1">
                            Tất cả
                        </MenuItem>
                        <MenuItem value="1">
                            Có SĐT
                        </MenuItem>
                        <MenuItem value="0">
                            Không có SĐT
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={2}>
                    <Button sx={{marginRight: '5px'}} startIcon={<SearchIcon/>} type="submit" className="text-transform"  variant="contained">
                        Tìm kiếm
                    </Button>
                    <Button onClick={refresh} startIcon={<ReplayIcon/>} color="error" className="text-transform"  variant="contained">
                        Tải lại
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}