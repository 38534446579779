import { Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import api from './../../../../../../../Apis';
import Swal from "sweetalert2";

function ReadMoreMess(props) {
    const [state, setState] = useState({
        loading: false
    })
    const readMoreMess = () => {
        setState({...state, loading: true});
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                conversation_id: props.conversation_id,
                fanpage_id: props.fanpage_id,
                offset: props.count_mess
            })
        };
        fetch(api.load_more_mess, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({...state, loading: false});
                    props.responsePropsMessage(result.datas);
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        confirmButtonText: 'Thoát'
                    })
                }
            }
        )
    }
    return (
        <center>
            {
                state.loading ?
                <CircularProgress></CircularProgress>
                :
                <Button onClick={readMoreMess} startIcon={<AddRoundedIcon/>} sx={{textAlign: "left", textTransform: "unset"}} size="small" variant="contained">
                    Tải thêm tin nhắn
                </Button>
            }
            
        </center>
    )
}

export default ReadMoreMess;