import LoadingButton from "@mui/lab/LoadingButton";
import TabPanel from "@mui/lab/TabPanel";
import { Button, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import Swal from "sweetalert2";
import api from './../../../../../Apis';

function Info(props) {
    const [state, setState] = useState({
        info_shop: {
            'email': '',
            'username': '',
            'full_name': '',
            'phone': '',
            'bank_name': '',
            'bank_branch': '',
            'bank_account': '',
            'bank_account_number': '',
            'password': '',
            'password_confirmation': ''
        },
        loading_button: false,
        error: {
            phone: {
                status: false,
                message: ''
            },
            full_name: {
                status: false,
                message: ''
            },
            password: {
                status: false,
                message: ''
            }
        }
    })
    const {register, handleSubmit, reset} = useForm();

    const updateInfoShop = () => {
        setState({
            ...state,
            loading_button: true
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify(state.info_shop)
        };
        fetch(api.update_shop, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        timer: 1500
                    })
                    setState({
                        ...state,
                        loading_button: false,
                        error: {
                            full_name: {
                                status: false,
                                message: ''
                            },
                            phone: {
                                status: false,
                                message: ''
                            },
                            password: {
                                status: false,
                                message: ''
                            }
                        }
                    })
                } else {
                    if (result.type = 'validate' && result.type != undefined) {
                        setState({
                            ...state,
                            loading_button: false,
                            error: {
                                full_name: {
                                    status: result.errors.full_name != undefined ? true : false,
                                    message: result.errors.full_name != undefined ? result.errors.full_name.message : ''
                                },
                                phone: {
                                    status: result.errors.phone != undefined ? true : false,
                                    message: result.errors.phone != undefined ? result.errors.phone.message : ''
                                },
                                password: {
                                    status: result.errors.password != undefined ? true : false,
                                    message: result.errors.password != undefined ? result.errors.password.message : ''
                                }
                            }
                        })
                    } else {
                        setState({
                            ...state,
                            loading_button: false,
                        })
                        Swal.fire({
                            title: result.message,
                            icon: 'error',
                            timer: 1500
                        })
                    }
                }
            }
        )
    }

    useEffect(() => {
        setState({
            ...state,
            info_shop: props.info_shop
        })
    }, [props.info_shop])

    return (
        <TabPanel style={{padding: '10px 0px'}} value="1">
            <br></br>
            <Grid style={{justifyContent: 'center'}} container spacing={2}>
                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField disabled value={state.info_shop.email} fullWidth size="small" label="Địa chỉ Email hoặc username" variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField disabled value={state.info_shop.username} fullWidth size="small" id="outlined-basic" label="Username" variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                helperText={state.error.full_name.message}
                                error={state.error.full_name.status}
                                onChange={(e) => setState({...state, info_shop: {...state.info_shop, full_name: e.target.value}})}
                                value={state.info_shop.full_name}
                                fullWidth
                                size="small"
                                label="Tên shop"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                helperText={state.error.phone.message}
                                error={state.error.phone.status}
                                onChange={(e) => setState({...state, info_shop: {...state.info_shop, phone: e.target.value}})}
                                value={state.info_shop.phone}
                                fullWidth
                                size="small"
                                label="Phone"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                onChange={(e) => setState({...state, info_shop: {...state.info_shop, bank_name: e.target.value}})}
                                value={state.info_shop.bank_name}
                                fullWidth
                                size="small"
                                label="Tên ngân hàng"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                onChange={(e) => setState({...state, info_shop: {...state.info_shop, bank_branch: e.target.value}})}
                                value={state.info_shop.bank_branch}
                                fullWidth
                                size="small"
                                label="Chi nhánh"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                onChange={(e) => setState({...state, info_shop: {...state.info_shop, bank_account: e.target.value}})}
                                value={state.info_shop.bank_account}
                                fullWidth
                                size="small"
                                label="Chủ tài khoản"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                onChange={(e) => setState({...state, info_shop: {...state.info_shop, bank_account_number: e.target.value}})}
                                value={state.info_shop.bank_account_number}
                                fullWidth
                                size="small"
                                label="Số tài khoản"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField type="password" helperText={state.error.password.message} error={state.error.password.status} onChange={(e) => setState({...state, info_shop: {...state.info_shop, password: e.target.value}})} fullWidth size="small" id="outlined-basic" label="Mật khẩu mới (nếu cần)" variant="outlined" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField type="password" onChange={(e) => setState({...state, info_shop: {...state.info_shop, password_confirmation: e.target.value}})} fullWidth size="small" id="outlined-basic" label="Xác nhận mật khẩu mới" variant="outlined" />
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton loading={state.loading_button} onClick={updateInfoShop} fullWidth className="text-transform" variant="contained">Lưu thông tin</LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </TabPanel>
    )
}

export default Info;