import Add from "./Components/Add";
import Add2 from "./Components/Add2";
import { useCookies } from "react-cookie";
import ListProduct from './Components/ListProduct';
import { useEffect, useState} from "react";
import { Container, Backdrop, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CircularProgress from '@mui/material/CircularProgress';
import api from "./../../../../Apis";
import Swal from "sweetalert2";
import {debounce, search} from './../../../../Helpers/function'
import DialogCreateProduct from "./Components/DialogCreateProduct";
import EditProduct from "./Components/EditProduct";
import { useForm } from "react-hook-form";

function List() {
    const [cookies, setCookie] = useCookies('is_login');
    const [state, setState] = useState({
        new_product: '',
        product_edit: '',
        status_edit: true,
        position_edit: '',
        loading: true,
        products: [],
        products_searchs: []
    })
    const {register, handleSubmit, reset, setValue, control, getValues} = useForm();
    const update_product = (data, position_edit, type) => {
        if (type == 'edit') {
            const copy_products = [...state.products];
            copy_products[position_edit] = data;
            setState({...state, products: copy_products});
        } else if (type == 'create') {
            setState({...state, products: [data, ...state.products]});
        }
        // setState({...state, new_product: data, position_edit: position_edit});
    }

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            }
        };
        fetch(api.list_product, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        loading: false,
                        products: result.datas
                    })
                }
            }
        )
    }, [])

    const deleteProduct = (index, id) => {
        Swal.fire({
            title: 'Bạn có chắc chắn muốn xóa?',
            showDenyButton: true,
            confirmButtonText: 'Đồng ý',
            denyButtonText: 'Từ chối',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + cookies.is_login
                    },
                    body: JSON.stringify({
                        id: id
                    })
                };
                fetch(api.delete_product, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            const array_copy = [...state.products];
                            array_copy.splice(index, 1);
                            setState({...state, products: array_copy});
                            Swal.fire('Xóa thành công!', '', 'success');
                        } else {
                            Swal.fire('Vui lòng thử lại', '', 'error');
                        }
                    }
                )
            }
        })
    }

    const searchProduct = debounce((e) => {
        const key_search = e.target.value;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify({
                key_search: key_search
            })
        };
        fetch(api.search_product, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        products: result.datas
                    })
                }
            }
        )
    }, 500)

    const onShowPrice = (index) => {
        const array_copy = [...state.products];
        array_copy[index].is_show = !array_copy[index].is_show;
        setState({...state, products: array_copy});
    }

    const editProduct = (data, position_edit) => {
        setState({
            ...state,
            product_edit: data,
            status_edit: !state.status_edit,
            position_edit: position_edit
        });
    }

    const updateProduct = (data, index) => {
        const products = [...state.products];
        products[index] = data;
        setState({
            ...state,
            products: products
        })
    }

    const createProductSuccess = (data) => {
        setState({
            ...state,
            products: [data, ...state.products]
        })
    }

    const searchProduct1 = (e) => {
        if (e.target.value == '') {
            setState({
                ...state,
                products_searchs: []
            })
        }
    }
    
    const onSearchProduct = (data) => {
        const result = search(data.name_product, state.products, 'name');

        if (result.length == 0) {
            Swal.fire({
                title: 'Không tồn tại',
                icon: 'warning',
                timer: 1000
            })
        } else {
            setState({
                ...state,
                products_searchs: result
            })
        }
    }

    const ProductItem = (props) => {
        const {value, stt} = props;
        return (
            <TableRow>
                <TableCell>{stt + 1}</TableCell>
                <TableCell>{value.code}</TableCell>
                <TableCell>{value.name}</TableCell>
                <TableCell>
                    {Number(value.total_amount).toLocaleString()}
                </TableCell>
                <TableCell align="center">
                    <b>
                        {
                            value.min_price == value.max_price
                            ?
                            Number(value.min_price).toLocaleString() + ' ₫'
                            :
                            Number(value.min_price).toLocaleString() + ' ₫ - ' + Number(value.max_price).toLocaleString() + ' ₫'
                        }
                    </b>
                </TableCell>
                <TableCell align="center">
                    <div className="d-flex justify-content-center">
                        <EditProduct
                            key={stt}
                            stt={stt}
                            value={value}
                            cookie={cookies.is_login}
                            updateProduct={updateProduct}
                        />
                        <DeleteIcon className="cursor" onClick={() => deleteProduct(stt, value.id)} color="error"/>
                    </div>
                </TableCell>
            </TableRow>
        )
    }

    return (
        <Container maxWidth="xl" className='m-t-40'>
            {/* <Add2 position_edit={state.position_edit} status_edit={state.status_edit} product_edit={state.product_edit} update_product={update_product} cookie={cookies.is_login}/> */}
            <DialogCreateProduct
                position_edit={state.position_edit} status_edit={state.status_edit} product_edit={state.product_edit} update_product={update_product} cookie={cookies.is_login} createProductSuccess={createProductSuccess}
            />
            <br></br>
            {/* <ListProduct position_edit={state.position_edit} editProduct={editProduct} new_product={state.new_product} cookie={cookies.is_login}/> */}
            <Grid item md={8}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={state.load_page}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid item md={12}>
                    <form onSubmit={handleSubmit(onSearchProduct)}>
                        <TextField onKeyUp={searchProduct1} {...register('name_product')} fullWidth size="small" id="outlined-basic" label="Tìm kiếm tên sản phẩm" placeholder="Nhập và enter" variant="outlined" />
                    </form>
                    
                </Grid>
                <br></br>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>STT</TableCell>
                                <TableCell>
                                    Mã SP
                                </TableCell>
                                <TableCell>
                                    Tên
                                </TableCell>
                                <TableCell>
                                    Tổng số lượng
                                </TableCell>
                                <TableCell align="center">
                                    Giá bán
                                </TableCell>
                                <TableCell align="center">Hành động</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                state.loading ?
                                <TableRow>
                                    <TableCell align="center" colSpan={6}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                                :
                                state.products_searchs.length > 0
                                ?
                                state.products_searchs.map((value, key) => {
                                    return (
                                        <ProductItem
                                        value={value}
                                        stt={key}
                                        key={key}
                                        />
                                    )
                                })
                                :
                                state.products.map((value, key) => {
                                    return (
                                        <ProductItem
                                        value={value}
                                        stt={key}
                                        key={key}
                                        />
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Container>
    )
}

export default List;