import LoadingButton from "@mui/lab/LoadingButton";
import { Autocomplete, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { requestOptions } from "../../../../../Helpers/function";
import api from './../../../../../Apis'
import {
    Link
} from "react-router-dom";

export default function SendMessage(props) {
    const fanpages = props.fanpages;
    const {register, handleSubmit, reset, control} = useForm();
    const [state, setState] = useState({
        type_message: 24,
        total_customer: null,
        loading_check: false,
        loading_submit: false,
        type_time: ''
    })

    const storeCampaign = (data) => {
        setState({
            ...state,
            total_customer: null,
            loading_submit: true
        })
        data['fanpage_id'] = props.fanpage_id;
        fetch(api.store_campaign, requestOptions('POST', props.cookie, data))
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        loading_submit: false
                    })
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        timer: 1500
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'warning',
                        timer: 1500
                    })
                    setState({
                        ...state,
                        loading_check: false
                    })
                }
            }
        )
    }

    const onChangeTypeMessage = (e) => {
        setState({
            ...state,
            type_message: e.target.value
        })
    }

    const getTotalCustomerReciveBroadcast = (data) => {
        setState({
            ...state,
            total_customer: null,
            loading_check: true
        })
        const body = {
            type_message: data.type_message,
            fanpage_id: props.fanpage_id
        }
        fetch(api.get_total_customer_recive_broadcast, requestOptions('POST', props.cookie, body))
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        total_customer: result.total_customer,
                        loading_check: false
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'warning',
                        timer: 1500
                    })
                    setState({
                        ...state,
                        loading_check: false
                    })
                }
            }
        )
    }

    const onChangeTypeTime = (e) => {
        setState({
            ...state,
            type_time: e.target.value
        })
    }

    const getHours = () => {
        const options = [];
        for (var hour = 0; hour <= 23; hour++) {
            options.push(
                <MenuItem value={hour}>{hour} giờ</MenuItem> 
            )
        }

        return options;
    }

    const getMinutes = () => {
        const options = [];
        for (var minute = 0; minute <= 59; minute++) {
            options.push(
                <MenuItem value={minute}>{minute} phút</MenuItem> 
            )
        }

        return options;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={5} md={5}>
                <p style={{color: 'red', marginTop: '0px'}}>
                    Đọc kỹ hướng dẫn và lưu ý ở cột bên phải trước khi gửi
                </p>
                <form onSubmit={handleSubmit(storeCampaign)}>
                    <TextField
                        id="outlined-select-currency"
                        size="small"
                        fullWidth
                        label="Tên chiến dịch"
                        {...register('campaign_name')}
                    >
                    </TextField>
                    <FormControl fullWidth sx={{marginTop: '10px'}}>
                        <FormLabel id="demo-radio-buttons-group-label">Loại tin nhắn</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={state.type_message}
                            name="radio-buttons-group"
                            onChange={onChangeTypeMessage}
                        >
                            <FormControlLabel control={<Radio {...register('type_message')} value="24" />} label="Tin nhắn tiêu chuẩn (24h)" />
                            <FormControlLabel control={<Radio {...register('type_message')} value="25" />} label="Tin nhắn CSKH" />
                        </RadioGroup>
                    </FormControl>
                    {
                        state.type_message == 25 &&
                        <FormControl size="small" fullWidth sx={{marginTop: '10px', marginBottom: '10px'}}>
                            <InputLabel id="demo-simple-select-label">Thẻ tin nhắn</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Thẻ tin nhắn"
                                {...register('message_tag')}
                            >
                                <MenuItem value="ACCOUNT_UPDATE">Cập nhật tài khoản (ACCOUNT_UPDATE)</MenuItem>
                                <MenuItem value="CONFIRMED_EVENT_UPDATE">Cập nhật sự kiện (CONFIRMED_EVENT_UPDATE)</MenuItem>
                                <MenuItem value="POST_PURCHASE_UPDATE">Cập nhật giao dịch (POST_PURCHASE_UPDATE)</MenuItem>
                            </Select>
                        </FormControl>
                    }
                    <FormControl size="small" fullWidth sx={{marginTop: '10px'}}>
                        <InputLabel id="demo-simple-select-label">Chọn Block muốn gửi</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Chọn Block muốn gửi"
                            {...register('block_id')}
                        >
                            {
                                props.blocks.map((blockItem, key) => {
                                    return (
                                        <MenuItem value={blockItem.code}>{blockItem.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                        <small>
                            Thêm mới block <Link to="/dashboard/chatbot">tại đây</Link>
                        </small>
                    </FormControl>
                    {/* <textarea style={{marginTop: '20px'}} {...register('message')} rows="10" placeholder="Tin nhắn gửi cho khách" className="textarea"></textarea> */}
                    <FormControl sx={{marginTop: '10px'}} fullWidth >
                        <FormLabel id="demo-radio-buttons-group-label">Thời gian gửi</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="now"
                            name="radio-buttons-group"
                            onChange={onChangeTypeTime}
                        >
                            <FormControlLabel value="now" control={<Radio {...register('type_time')}/>} label="Gửi ngay" />
                            <FormControlLabel value="custom" control={<Radio {...register('type_time')} />} label="Lên lịch" />
                        </RadioGroup>
                    </FormControl>
                    {
                        state.type_time == 'custom'
                        &&
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    fullWidth
                                    size="small"
                                    defaultValue={0}
                                    {...register('hour')}
                                >
                                    {getHours()}
                                </Select>
                            </Grid>
                            <Grid item xs={3}>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    fullWidth
                                    size="small"
                                    defaultValue={0}
                                    {...register('minute')}
                                >
                                    {getMinutes()}
                                </Select>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField type="date" {...register('date')} fullWidth size="small" id="outlined-basic" variant="outlined" />
                            </Grid>
                        </Grid>
                    }
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <LoadingButton loading={state.loading_submit} type="submit" sx={{marginTop: '10px'}} variant="contained" className="text-transform">Xác nhận gửi</LoadingButton>
                        <LoadingButton loading={state.loading_check} type="button" onClick={handleSubmit(getTotalCustomerReciveBroadcast)} color="warning" sx={{marginTop: '10px', marginLeft: '10px'}} variant="contained" className="text-transform">Kiểm tra số người nhận</LoadingButton>
                        {
                            state.total_customer != null &&
                            <span style={{marginLeft: '10px'}}>Tổng khách nhận được là: <b>{state.total_customer}</b></span>
                        }
                    </div>
                </form>
            </Grid>
            <Grid item xs={1} md={1}></Grid>
            <Grid item xs={6} md={6}>
                <div style={{maxHeight: '700px', overflow: 'auto'}}>
                    <h3 style={{marginTop: '0px'}}>
                        Lưu ý:
                    </h3>
                    <p>
                        { process.env.REACT_APP_NAME } chỉ cung cấp công cụ, giải pháp và sẽ không chịu trách nhiệm bất cứ vấn đề gì liên quan đến Fanpage của bạn, ví dụ như: page bị vi phạm chính sách Facebook, page bị khóa,.....
                    </p>
                    <p>
                        Bạn nên thêm biến <b>{'{full_name}'}</b> trong chiến dịch hoặc sử dụng SPIN CONTENT để tránh nội dung giống hệt nhau để hạn chế tỷ lệ bị đánh dấu spam.
                    </p>
                    <p>
                    <b>- Tin nhắn tiêu chuẩn 24h</b>: gửi cho những khách hàng nhắn tin với page trong vòng 24h. Với loại tin nhắn này, bạn được phép gửi nội dung chứa quảng cáo.
                    </p>
                    <p>
                    <b>- Tin nhắn CSKH</b>: gửi cho cả những khách hàng tương tác trong và ngoài 24h. Với loại tin nhắn này, bạn không được gửi nội dung chứa quảng cáo như các từ: Khuyến mãi, Sale off, Giảm giá, Deal, Coupon, Voucher, sale off, survey,... Khi gửi loại tin nhắn này bạn phải lựa chọn Message Tag (thẻ Tag Facebook) phù hợp với nội dung chiến dịch. 
                    </p>
                    <p>
                    - Chiến dịch không được phép gửi trong khoảng thời gian từ 23h - 6h do chính sách của facebook tránh làm phiền người dùng, trong khoảng thời gian nghỉ ngơi này nếu gửi sẽ dễ bị FB quét.
                    </p>
                    <p>
                    - Cùng 1 tệp khách hàng nên gửi chiến dịch cách nhau từ 7-9 ngày đối với loại tin nhắn CSKH với 3 thẻ (sự kiện-giao dịch-tài khoản).
                    </p>
                    <h3>
                        Chọn tag gì khi gửi tin nhắn sau 24h?
                    </h3>
                    <p >Khi khách hàng tương tác với Messenger của Page (nhắn tin, thích tin nhắn, truy cập link ref m.me), thì Page có 24 giờ để bán hàng.</p>
                    <p >Sau 24 giờ kể từ lần tương tác cuối cùng, bạn không được tin nhắn quảng cáo, bán hàng cho khách hàng này.</p>
                    <p >Nhưng, Facebook cho phép Page gửi tin nhắn Chăm sóc khách hàng. Với các tin nhắn này, bạn cần gắn thẻ tin nhắn.</p>
                    <p ><strong>Thẻ Tin Nhắn (Tag Message) là gì?</strong></p>
                    <p >Khi gửi gửi tin nhắn 1:1 đến khách hàng đã tương tác với page quá 24 giờ, bạn cần gắn thẻ tin nhắn để phân loại nội dung gửi:</p>
                    <p ><strong>1. Thẻ cập nhật sự kiện (CONFIRMED_EVENT_UPDATE):&nbsp;</strong></p>
                    <p >Tin nhắn cập nhật cho khách hàng về sự kiện mà họ đã đăng ký, có thể được gửi tới khách hàng bất kỳ lúc nào.</p>
                    <p >Ví dụ:</p>
                    <ul>
                        <li >Nhắc nhở về một sự kiện hoặc cuộc hẹn</li>
                        <li >Xác nhận đặt phòng hoặc tham dự</li>
                        <li >Cập nhật lịch trình di chuyển</li>
                    </ul>
                    <p ><strong>2. Cập nhật giao dịch (POST_PURCHASE_UPDATE):</strong></p>
                    <p >Tin nhắn cập nhật cho khách hàng về giao dịch mua hàng gần đây, có thể được gửi tới khách hàng bất kỳ lúc nào.</p>
                    <p >Ví dụ:</p>
                    <ul>
                        <li >Hóa đơn hoặc biên lai</li>
                        <li >Tình trạng đơn hàng, vận chuyển</li>
                        <li >Thay đổi liên quan đến đơn hàng mà người dùng đã đặt</li>
                    </ul>
                    <p ><strong>3. Cập nhật tài khoản (ACCOUNT_UPDATE):</strong></p>
                    <p >Tin nhắn cập nhật cho khách hàng về thay đổi liên quan đến cá nhân, có thể được gửi tới khách hàng bất kỳ lúc nào.</p>
                    <p >Ví dụ:</p>
                    <ul>
                        <li >Thay đổi trạng thái công việc</li>
                        <li >Thay đổi tài khoản thanh toán</li>
                        <li >Cảnh báo gian lận</li>
                        <li>Chúc mừng sinh nhật</li>
                    </ul>
                    <p >Không được dùng thẻ tin nhắn với các nội dung:</p>
                    <ul>
                        <li >Bán hàng, khuyến mãi, coupon, voucher, sale off</li>
                        <li >Upsale, Cross-sale, khảo sát thăm dò ý kiến</li>
                    </ul>
                    <p >Khi các tin nhắn được gửi đi sau 24h có gắn thẻ thì sẽ được đội kiểm duyệt Facebook xem xét, họ sẽ không thấy lịch sử của cuộc trò chuyện. Họ chỉ xem được một tin nhắn duy nhất. Nên nếu nếu một tin nhắn không tuân thủ trường hợp sử dụng được nêu trong Chính sách, tin nhắn đó sẽ bị gắn cờ là vi phạm.</p>
                    <p ><em><strong>Ví dụ:</strong></em></p>
                    <p >Bạn gửi nội dung tin nhắn dưới đây và gắn thẻ POST_PURCHASE_UPDATE</p>
                    <p >Tin nhắn 1: “Cảm ơn bạn đã đặt đơn hàng 1234. Hiện chúng tôi đang xử lý đơn hàng của bạn”</p>
                    <p >Tin nhắn 2: “Kiểm tra một lần nữa xem bạn có chắc chắn muốn màu đỏ không?”</p>
                    <p >Khi 2 tin nhắn này được gửi đi, đội Kiểm duyệt Facebook sẽ gắn cờ fanpage của bạn vi phạm chính sách vì tin nhắn thứ 2 không đề cập đến bất cứ giao dịch nào.</p>
                    <p >Cách ĐÚNG là:</p>
                    <p >Tin nhắn 1: “Cảm ơn bạn đã đặt đơn hàng 1234. Chúng tôi đang xử lý đơn hàng của bạn ngay bây giờ”</p>
                    <p >Tin nhắn 2: “Đối với đơn đặt hàng 1234 của bạn, kiểm tra lại lần nữa xem bạn có chắc chắn muốn màu đỏ không?”</p>
                    <p >&nbsp;</p>
                    <p >Tất cả các tin nhắn trong THẺ thông báo, PHẢI có nội dung có liên quan 100% đến thẻ thông báo cụ thể đó:</p>
                    <ul>
                        <li >Nếu đó là CONFIRMED_EVENT_UPDATE - mọi tin nhắn phải có tên EVENT đó</li>
                        <li >Nếu đó là ACCOUNT_UPDATE - mọi tin nhắn phải có một số chi tiết về ACCOUNT được đề cập</li>
                        <li >Nếu đó là POST_PURCHASE_UPDATE - mọi tin nhắn phải có một số chi tiết về PURCHASE được đề cập</li>
                    </ul>
                </div>
            </Grid>
        </Grid>
    )
}
