import { useEffect, useRef, useState } from "react";
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AvatarProfile from "./AvatarProfile";
import { Avatar, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { requestOptions } from "../../../Helpers/function";
import Swal from "sweetalert2";
import api from './../../../Apis';

const StyledMenu = styled((props) => (
    <Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
		/>
	))(({ theme }) => ({
		'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color:
			theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
			fontSize: 18,
			color: theme.palette.text.secondary,
			marginRight: theme.spacing(1.5),
			},
			'&:active': {
			backgroundColor: alpha(
				theme.palette.primary.main,
				theme.palette.action.selectedOpacity,
			),
			},
		},
	},
}));
function UserOption(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [cookies, setCookie] = useCookies('is_login');
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeShopActive = (fb_id) => {
		fetch(api.active_shop, requestOptions('POST', cookies.is_login, {id: fb_id}))
		.then(res => res.json())
		.then(
			(result) => {
				if (result.success) {
					window.location.reload();
					// return navigate('/dashboard');
				} else {
					Swal.fire(result.message, '', 'error');
					//return navigate('/dashboard');
				}
			}
		)
    }

    return (
        <div className='avatar'>
            <Stack direction="row" spacing={2}>
              <Button
                  id="demo-customized-button"
                  aria-controls={open ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  // variant="contained"
                  disableElevation
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                  className="text-transform"
              >
                Shop: {props.user_info.shop_active.full_name}
              </Button>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                  {
                    props.user_info.shops.map((value, index) => {
                      return (
                        // <Link to={'shop/' + value.fb_id} className="a-none">
                        <a onClick={() => changeShopActive(value.fb_id)} className="cursor a-none">
                          <MenuItem onClick={handleClose} disableRipple>
                            
                              <Avatar style={{marginRight: '10px'}}>
                                <img src={value.avatar}></img>
                              </Avatar>
                                {
                                  value.full_name
                                }
                          </MenuItem>
                        </a>
                        
                      )
                    })
                  }
                </StyledMenu>
                <AvatarProfile user_info={props.user_info}/>
            </Stack>
        </div>
    )
}

export default UserOption;