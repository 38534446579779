import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import api from './../../../../Apis';
import logo_viettel_post from './../../../../Assets/Images/logo-viettel-post.png';
import logo_vnpost from './../../../../Assets/Images/logo-vnpost.png';

function ViettelPost() {
    const [state, setState] = useState({
        error: '',
        data: ''
    })
    const {id} = useParams();
    const [cookies, setCookie] = useCookies('is_login');
    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify({
                code_transport: id
            })
        };
        fetch(api.print_bill_viettel_post, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        data: result.data
                    })
                    // window.print();
                } else {
                    setState({
                        ...state,
                        error: result.message
                    })
                }
            }
        )
    }, [])

    return (
        <Container maxWidth="md" sx={{border: '2px solid #000', paddingTop: '10px', paddingBottom: '10px'}}>
            {
                state.error != ''
                ?
                <Grid item xs={12}>
                    <center>{state.error}</center>
                </Grid>
                :
                state.data != '' &&
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <p className="t-center">
                            Mã đơn hàng<br></br>
                            <b>{state.data?.code_transport}</b>
                        </p>
                    </Grid>
                    <Grid item xs={4}>
                        <h4 className="t-center m-t-0">
                            PHIẾU GỬI<br></br>
                            BILL OF CONSIGNMENT
                        </h4>
                    </Grid>
                    <Grid item xs={4}>
                        {
                            state.data?.transport_id == 4
                            ?
                            <p className="t-right">
                                <img src={logo_viettel_post} width="50%"></img>
                            </p>
                            :
                            state.data?.transport_id == 3
                            ?
                            <p className="t-right">
                                <img src={logo_vnpost} width="100%"></img>
                            </p>
                            :
                            <div></div>
                        }
                        
                    </Grid>
                    <Grid item xs={12}>
                        <table>
                            <tr>
                                <td>
                                    <p>
                                        <b>Người gửi:</b>
                                    </p>
                                    <p>
                                        Họ tên người gửi: <b>{state.data?.sender.name}</b>
                                    </p>
                                    <p>
                                        Địa chỉ: <b>{state.data?.sender.address}, {state.data?.sender.ward}, {state.data?.sender.district}, {state.data?.sender.province}, </b>
                                    </p>
                                    <p>
                                        SĐT: <b>{state.data?.sender.phone}</b>
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        <b>Dịch vụ cộng thêm:</b>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        <b>Người nhận:</b>
                                    </p>
                                    <p>
                                        Họ tên người gửi: <b>{state.data?.receive.name}</b>
                                    </p>
                                    <p>
                                        Địa chỉ: <b>{state.data?.receive.address}, {state.data?.receive.ward}, {state.data?.receive.district}, {state.data?.receive.province}, </b>
                                    </p>
                                    <p>
                                        SĐT: <b>{state.data?.receive.phone}</b>
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        <b>Cước phí:</b>
                                    </p>
                                    <p>
                                        Trọng lượng: <b>{state.data?.weight/1000} Kg</b>
                                    </p>
                                    <p>
                                        Tổng cước: <b>{Number(state.data?.fee).toLocaleString()} (đ)</b>
                                    </p>
                                    <p>
                                        Thanh toán cước: <b>Người gửi</b>
                                    </p>
                                    <p>
                                        Số tiền phải thu: <b>{Number(state.data?.cod).toLocaleString()} (đ)</b>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p><b>Nội dung hàng hóa:</b></p>
                                    <p>
                                        <b>
                                            {state.data?.content}
                                        </b>
                                    </p>
                                </td>
                                <td>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} className="t-center">
                                            <p>
                                                <b>Ngày giờ gửi</b>
                                            </p>
                                            <p>
                                                {state.data?.time}
                                            </p>
                                            <p>
                                                <b>Họ tên chữ ký người gửi</b>
                                            </p>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                        </Grid>
                                        <Grid item xs={6} className="t-center">
                                            <p>
                                                <b>Ngày giờ nhận</b>
                                            </p> 
                                            <p>
                                                ...h.../.../.../20...
                                            </p>
                                            <p>
                                                <b>Họ tên chữ ký người gửi</b>
                                            </p>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                        </Grid>
                                    </Grid>
                                </td>
                            </tr>
                        </table>
                    </Grid>
                </Grid>
            }
            <style dangerouslySetInnerHTML={{__html: "@page { size: auto;  margin: 0mm; } td{vertical-align:top;width: 50%} table tr td{ border: 1px solid #ccc; padding: 10px 20px} p{margin: 0px} table{border-collapse: collapse;}" }} />
        </Container>
    )
}

export default ViettelPost;