import TabPanel from "@mui/lab/TabPanel";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import api from './../../../../../Apis'
import LoadingButton from '@mui/lab/LoadingButton';

function Transport(props) {
    const {register: register_ghtk, handleSubmit: handleSubmitGhtk, reset: reset_ghtk} = useForm({
        defaultValues: useMemo(() => {
            return props.state.setting_transports;
          }, [props.state.setting_transports])
    });
    const {register: register_ghn, handleSubmit: handleSubmitGhn, reset: reset_ghn} = useForm({
        defaultValues: useMemo(() => {
            return props.state.setting_transports;
          }, [props.state.setting_transports])
    });
    const {register: register_vnpost, handleSubmit: handleSubmitVnpost, reset: reset_vnpost, setValue} = useForm({
        defaultValues: useMemo(() => {
            return props.state.setting_transports;
          }, [props.state.setting_transports])
    });

    const {register: register_viettel_post, handleSubmit: handleSubmitViettelPost, reset: reset_viettel_post, setValue: setValueViettelPost} = useForm({
        defaultValues: useMemo(() => {
            return props.state.setting_transports;
          }, [props.state.setting_transports])
    });

    const {register, handleSubmit, reset} = useForm();

    const [state, setState] = useState({
        dialog: false,
        dialog_viettel_post: false,
        loading_submit_token: false
    })

    const getTokenViettelPost = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setState({
            ...state,
            loading_submit_token: true
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                username: data.get('username'),
                password: data.get('password')
            })
        };
        fetch(api.get_token_viettel_post, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setValueViettelPost('viettel_post', result.token);
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        timer: 1500
                    })
                    setState({
                        ...state,
                        dialog: false,
                        loading_submit_token: false
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error'
                    })
                    setState({
                        ...state,
                        loading_submit_token: false
                    })
                }
            }
        )
    }

    const getTokenVnpost = (data) => {
        setState({
            ...state,
            loading_submit_token: true
        })
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify(data)
        };
        fetch(api.get_token_vnpost, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setValue('vnpost', result.token);
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        timer: 1500
                    })
                    setState({
                        ...state,
                        dialog: false,
                        loading_submit_token: false
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error'
                    })
                    setState({
                        ...state,
                        loading_submit_token: false
                    })
                }
            }
        )
    }

    const closeDialog = () => {
        setState({
            ...state,
            dialog_viettel_post: false,
            dialog: false
        })
    }

    useEffect(() => {
        reset_ghtk(props.state.setting_transports);
        reset_vnpost(props.state.setting_transports);
        reset_ghn(props.state.setting_transports);
        reset_viettel_post(props.state.setting_transports);
    }, [props.state.setting_transports]);

    const callApi = (api_token, company_name, shop_id, transport_id) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                api_token: api_token,
                company_name: company_name,
                shop_id: shop_id,
                transport_id: transport_id
            })
        };
        fetch(api.create_api_token, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    Swal.fire({
                        title: result.message,
                        timer: 1500,
                        icon: 'success'
                    })
                } else {
                    if (result.type == 'validate') {
                        var text_error = '';
                        result.errors.map((value, key) => {
                            text_error+= '<p>' + value + '</p>';
                        })
                        Swal.fire({
                            title: result.message,
                            icon: 'warning',
                            html: text_error
                        })
                    } else {
                        Swal.fire({
                            title: result.message,
                            timer: 1500,
                            icon: 'error'
                        })
                    }

                }
            }
        )
    }

    const submitApiTokenVnpost = (data) => {
        callApi(data.vnpost, 'vnpost', null, 3);
    }

    const submitApiTokenViettelPost = (data) => {
        callApi(data.viettel_post, 'viettel_post', null, 4);
    }

    const submitApiTokenGhtk = (data) => {
        callApi(data.ghtk, 'ghtk', null, 1);
    }

    const submitApiTokenGhn = (data) => {
        callApi(data.ghn, 'ghn', data.shop_id, 2);
    }
    
    return (
        <TabPanel style={{padding: '30px 0px'}} value="5">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <h3 className='m-t-0'>
                        Cài đặt vận chuyển GHTK
                    </h3>
                    <form onSubmit={handleSubmitGhtk(submitApiTokenGhtk)}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField {...register_ghtk('ghtk')}  autoComplete='off' fullWidth size="small" id="outlined-basic" label="API Token Key" variant="outlined" />
                            </Grid>
                            <Grid item xs={3}>
                                <Button type="submit" className="text-transform" variant="contained">
                                    Lưu
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
                <Grid item xs={6}>
                    <h3 className='m-t-0 m-b-0'>
                        Cài đặt vận chuyển GHN (giao hàng nhanh)
                    </h3>
                    <p className="m-t-0">
                        <small>
                            <span style={{color: 'red'}}>Chú ý: </span> Cần nhập địa chỉ cho bưu tá lấy hàng trên hệ thống GHN
                        </small>
                    </p>
                    
                    <form onSubmit={handleSubmitGhn(submitApiTokenGhn)}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField  autoComplete='off' {...register_ghn('ghn')} fullWidth size="small" id="outlined-basic" label="API Token Key" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField  autoComplete='off' {...register_ghn('shop_id')} fullWidth size="small" id="outlined-basic" label="Shop ID" variant="outlined" />
                            </Grid>
                            <Grid item xs={2}>
                                <Button type="submit" className="text-transform" variant="contained">
                                    Lưu
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    
                </Grid>
                <Grid item xs={6}>
                    <h3 className='m-t-0'>
                        Cài đặt vận chuyển VNPOST
                    </h3>
                    <form onSubmit={handleSubmitVnpost(submitApiTokenVnpost)}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField  autoComplete='off' {...register_vnpost('vnpost')} fullWidth size="small" id="outlined-basic" label="API Token Key" variant="outlined" />
                            </Grid>
                            <Grid item xs={3}>
                                <Button type="submit" className="text-transform" variant="contained">
                                    Lưu
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    <p>
                        <Button
                            variant="contained"
                            color="warning"
                            className="text-transform"
                            onClick={() => setState({...state, dialog: true})}
                        >
                            Lấy Token
                        </Button>
                    </p>
                    <Dialog open={state.dialog} fullWidth="true" maxWidth='sm' onClose={closeDialog}>
                        <DialogTitle>Nhập thông tin đăng nhập VNPOST</DialogTitle>
                        <form onSubmit={handleSubmit(getTokenVnpost)}>
                            <DialogContent>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="Tên đăng nhập VNPOST"
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    required
                                    {...register('username')}
                                />
                                <TextField
                                    margin="dense"
                                    label="Mật khẩu VNPOST"
                                    type="password"
                                    fullWidth
                                    variant="outlined"
                                    required
                                    {...register('password')}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color="error" onClick={closeDialog}>Đóng</Button>
                                <LoadingButton loading={state.loading_submit_token} variant="contained" type="submit">Xác nhận</LoadingButton>
                            </DialogActions>
                        </form>
                    </Dialog>
                </Grid>
                <Grid item xs={6}>
                    <h3 className='m-t-0'>
                        Cài đặt vận chuyển Viettel Post
                    </h3>
                    <form onSubmit={handleSubmitViettelPost(submitApiTokenViettelPost)}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField  autoComplete='off' {...register_viettel_post('viettel_post')} fullWidth size="small" id="outlined-basic" label="API Token Key" variant="outlined" />
                            </Grid>
                            <Grid item xs={3}>
                                <Button type="submit" className="text-transform" variant="contained">
                                    Lưu
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    <p>
                        <Button
                            variant="contained"
                            color="warning"
                            className="text-transform"
                            onClick={() => setState({...state, dialog_viettel_post: true})}
                        >
                            Lấy Token
                        </Button>
                        <Button
                            sx={{marginLeft: '10px'}}
                            variant="contained"
                            color="success"
                            className="text-transform"
                            onClick={() => window.open('https://id.viettelpost.vn/Account/Register')}
                        >
                            Tạo tài khoản
                        </Button>
                        {/* <a className="cursor" onClick={() => setState({...state, dialog_viettel_post: true})}>
                            Lấy Token
                        </a>
                        /
                        <a>
                            Tạo tài khoản
                        </a> */}
                    </p>
                    <Dialog open={state.dialog_viettel_post} fullWidth="true" maxWidth='sm' onClose={closeDialog}>
                        <DialogTitle>Nhập thông tin đăng nhập Viettel Post</DialogTitle>
                        <form onSubmit={getTokenViettelPost}>
                            <DialogContent>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="Tên đăng nhập Viettel Post"
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    required
                                    name="username"
                                />
                                <TextField
                                    margin="dense"
                                    label="Mật khẩu Viettel Post"
                                    type="password"
                                    fullWidth
                                    variant="outlined"
                                    required
                                    name="password"
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color="error" onClick={() => setState({...state, dialog_viettel_post: false})}>Đóng</Button>
                                <LoadingButton loading={state.loading_submit_token} variant="contained" type="submit">Xác nhận</LoadingButton>
                            </DialogActions>
                        </form>
                    </Dialog>
                </Grid>
            </Grid>            
        </TabPanel>
    )
}

export default Transport;