import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { SelectableGroup } from "react-selectable-fast"
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useDispatch } from 'react-redux';
import { getMessSample } from "../../Store/Actions/GetMessSample";
import Swal from "sweetalert2";
import ButtonUploadImage from "./ButtonUploadImage";
import api from "../../Apis";
import ImageItem from "./ImageItem";
import AddLinkRoundedIcon from '@mui/icons-material/AddLinkRounded';
import { useForm } from "react-hook-form";
import UploadRoundedIcon from '@mui/icons-material/UploadRounded';
import UploadImageFromDevice from "./UploadImageFromDevice";
export default function DialogAttachmentManager(props) {
    const { isOpenDialogAttachment, closeDialogAttachment, cookie, imageSelectedOld, page_name } = props;
    const [state, setState] = useState({
        image_selected: [],
        attachments: [],
        is_load_data: true,
        new_image: [],
        dialog_link: false
    })
    const dispatch = useDispatch();
    const {register, handleSubmit, reset, control, getValues, setValue} = useForm();

    useEffect(() => {
        if (isOpenDialogAttachment) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + cookie
                }
            };
            fetch(api.get_attachment, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        setState({
                            ...state,
                            attachments: result.datas,
                            is_load_data: false,
                            // image_selected: [],
                        })
                    }
                }
            )
        }
    }, [isOpenDialogAttachment])

    useEffect(() => {
        setState({
            ...state,
            image_selected: imageSelectedOld
        })
    }, [imageSelectedOld])

    const onSelected = (selected) => {
        if (selected.length) {
            const image_selected = [];
            selected.map((item) => {
                image_selected.push({
                    'mime_type': "image/jpeg",
                    'file_url': null,
                    'video_data': null,
                    'image_data': item.props.value.link,
                    'code': item.props.value.code,
                    'id': item.props.value.id
                })
            })
            setState({...state, image_selected: image_selected})
        } else {
            setState({...state, image_selected: []})
        }
    }

    const onSendImage = () => {
        if (state.image_selected.length > 0) {
            const action = getMessSample({
                message: '',
                attachments: state.image_selected,
                page_name: page_name,
                rand: Math.random()
            });
            dispatch(action);
            const image_links = [];
            state.image_selected.map((item) => {
                image_links.push({
                    id: item.id,
                    code: item.code,
                    url: item.image_data
                })
            })
            props.imagePicked(image_links)
            closeDialogAttachment()
        } else {
            Swal.fire({
                title: 'Vui lòng chọn ảnh',
                icon: 'warning',
                timer: 1500
            });
        }
    }

    const sendImageRightAway = (image_selected) => {
        const action = getMessSample({
            message: '',
            attachments: image_selected,
            page_name: page_name,
            rand: Math.random()
        });
        dispatch(action);
        closeDialogAttachment()
    }

    const updateAttachment = (newImages) => {
        const attachments = newImages.concat(state.attachments);
        setState({
            ...state,
            new_image: newImages,
            attachments: attachments
        });
    }

    const onDeleteImage = () => {
        Swal.fire({
            title: 'Bạn có chắc chắn muốn xóa?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Hủy'
        }).then((result) => {
            if (result.isConfirmed) {
                const image_codes = [];
                state.image_selected.map((item) => {
                    image_codes.push(item.code)
                })
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.cookie
                    },
                    body: JSON.stringify({
                        ids: image_codes
                    })
                };
                fetch(api.delete_multiple_image, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            const attachmentNew = state.attachments.filter((item) => { return !image_codes.includes(item.code)})
                            setState({...state, attachments: attachmentNew, image_selected: []})
                        } else {
                            Swal.fire({
                                title: result.message,
                                icon: 'error',
                                confirmButtonText: 'OK'
                            })
                        }
                    }
                )
            }
        })        
    }

    const onUploadImageByLink = (data) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                fanpage_id: props.fanpage_id,
                url: data.url
            })
        };
        fetch(api.upload_url, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success == true) {
                    setState({...state,
                                dialog_link: false,
                                attachments: [
                                    {link: data.url, name: data.url},
                                    ...state.attachments
                                ]
                            });
                    Swal.fire({
                        title: result.message,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                }
            }
        )
        reset();
    }

    if (!isOpenDialogAttachment) return null;

    return (
        <>
            <Dialog open={state.dialog_link} fullWidth="true" maxWidth='sm' onClose={() => {setState({...state, dialog_link: false})}}>
                <DialogTitle>Nhập link ảnh</DialogTitle>
                <form onSubmit={handleSubmit(onUploadImageByLink)}>
                    <DialogContent sx={{paddingTop: '0px'}}>
                        <p className='m-b-0 m-t-0'>
                            Tải ảnh lên Fanpage và copy link ảnh
                        </p>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Đường dẫn ảnh của bạn"
                            type="text"
                            fullWidth
                            variant="outlined"
                            {...register('url')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {setState({...state, dialog_link: false})}}>Đóng</Button>
                        <Button type="submit">Xác nhận</Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog
                fullWidth="true"
                maxWidth='lg'
                open={isOpenDialogAttachment}
                onClose={closeDialogAttachment}
            >
                <DialogTitle sx={{display: "flex", justifyContent: "space-between"}}>
                    Ảnh của shop
                    <Button startIcon={<CloseOutlinedIcon />} variant="contained" color="warning" onClick={closeDialogAttachment}>
                        Đóng
                    </Button>
                </DialogTitle>
                <DialogContent sx={{overflowY: "unset", paddingBottom: "0px"}}>
                    <ButtonUploadImage
                        cookie={props.cookie}
                        fanpage_id={props.fanpage_id}
                        updateAttachment={updateAttachment}
                    />
                    <Button sx={{
                        marginRight: '20px'
                        }} onClick={() => {setState({...state, input_link: true, dialog_link: true})}} variant="outlined" color="success" startIcon={<AddLinkRoundedIcon />}>
                        Từ URL
                    </Button>
                    {
                        page_name == "livechat" &&
                        <UploadImageFromDevice
                        fanpage_id={props.fanpage_id}
                        cookie={props.cookie}
                        sendImageRightAway={sendImageRightAway}
                        />
                    }
                    {/* <TextField sx={{
                        marginRight: '20px'
                        }} size="small" id="outlined-basic" onChange={handleChangeKeySearch} label="Tìm kiếm tên ảnh" variant="outlined"></TextField> */}
                    {
                        state.image_selected.length > 0 &&
                        <Button sx={{
                            marginRight: '20px'
                            }} onClick={onSendImage} variant="outlined" color="warning">Sử dụng { state.image_selected.length } ảnh</Button>
                    }
                    {
                        state.image_selected.length > 0 &&
                        <Button onClick={onDeleteImage} variant="outlined" color="error">Xóa { state.image_selected.length } ảnh</Button>
                    }
                    <p style={{margin: "5px 0px 0px 0px", fontSize: "18px"}}>
                        <small>Kéo chuột để chọn nhiều ảnh hoặc click vào từng ảnh muốn sử dụng</small>
                    </p>
                </DialogContent>
                <DialogContent>
                    <SelectableGroup
                        allowClickWithoutSelected={true}
                        tolerance={0}
                        // duringSelection={handleDuringSelection}
                        onSelectionFinish={(selected) => onSelected(selected)}
                        ignoreList={['.name-col', '.h-col', '.s-col', '.u-col', '.v-col']}
                    >
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                {
                                    state.is_load_data &&
                                    <p style={{textAlign: "center", width: "100%"}}>
                                        <CircularProgress />
                                    </p>
                                }
                                {
                                    state.attachments.map((value, key) => {
                                        const imageSelected =  state.image_selected.filter((item) => {return item.id == value.id})
                                        return (
                                            <ImageItem
                                            value={value}
                                            key={key}
                                            index={key}
                                            isSelected={imageSelected.length > 0 ? true : false}
                                            />
                                        )
                                    })
                                }
                            </Grid>
                        </Box>
                    </SelectableGroup>
                </DialogContent>
            </Dialog>
        </>
        
    )
}