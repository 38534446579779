import { Backdrop, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import api from "./../../../../../Apis";
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2'
import {debounce} from './../../../../../Helpers/function'

function ListProduct(props) {
    const [state, setState] = useState({
        loading: true,
        products: [],
        load_page: false
    })
    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            }
        };
        fetch(api.list_product, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        loading: false,
                        products: result.datas
                    })
                }
            }
        )
    }, [])

    useEffect(() => {
        if (props.new_product != '') {
            if (props.position_edit == '' && props.position_edit != 0) {
                setState({...state, products: [props.new_product, ...state.products]});
            } else {
                const copy_array = [...state.products];
                copy_array[props.position_edit] = props.new_product;
                setState({...state, products: copy_array});
            }
        }
    }, [props.new_product])
    
    const onShowPrice = (index) => {
        const array_copy = [...state.products];
        array_copy[index].is_show = !array_copy[index].is_show;
        setState({...state, products: array_copy});
    }

    const deleteProduct = (index, id) => {
        Swal.fire({
            title: 'Bạn có chắc chắn muốn xóa?',
            showDenyButton: true,
            confirmButtonText: 'Đồng ý',
            denyButtonText: 'Từ chối',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.cookie
                    },
                    body: JSON.stringify({
                        id: id
                    })
                };
                fetch(api.delete_product, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            const array_copy = [...state.products];
                            array_copy.splice(index, 1);
                            setState({...state, products: array_copy});
                            Swal.fire('Xóa thành công!', '', 'success');
                        } else {
                            Swal.fire('Vui lòng thử lại', '', 'error');
                        }
                    }
                )
            }
          })
    }

    const searchProduct = debounce((e) => {
        const key_search = e.target.value;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
            },
            body: JSON.stringify({
                key_search: key_search
            })
        };
        fetch(api.search_product, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        products: result.datas
                    })
                }
            }
        )
    }, 500)

    const editProduct = (data, position_edit) => {
        props.editProduct(data, position_edit);
    }

    return (
        <Grid item md={8}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={state.load_page}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid item md={12}>
                <TextField onChange={searchProduct} fullWidth size="small" id="outlined-basic" label="Tìm kiếm tên hoặc mã sản phẩm" variant="outlined" />
            </Grid>
            <br></br>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>STT</TableCell>
                            <TableCell>
                                Mã SP
                            </TableCell>
                            <TableCell>
                                Tên
                            </TableCell>
                            <TableCell>
                                Cân nặng/Đơn vị
                            </TableCell>
                            <TableCell align="center">
                                Giá
                            </TableCell>
                            <TableCell align="center">Hành động</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            state.loading ?
                            <TableRow>
                                <TableCell align="center" colSpan={6}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                            
                            :
                            state.products.map((value, key) => {
                                return (
                                    <TableRow>
                                        <TableCell>{key + 1}</TableCell>
                                        <TableCell>{value.code}</TableCell>
                                        <TableCell>{value.name}</TableCell>
                                        <TableCell>{value.weight}g / {value.unit}</TableCell>
                                        <TableCell align="center">
                                            <Button color={value.is_show ? 'warning' : 'primary'} onClick={() => onShowPrice(key)} sx={{textTransform: "unset"}} variant="contained">
                                                {value.is_show ? 'Ẩn' : 'Hiện'}
                                            </Button>
                                            {
                                                value.is_show &&
                                                <Table sx={{background: '#fafafa'}}>
                                                    {
                                                        value.detail_products.map((item, key_parent) => {
                                                            if (Array.isArray(item.type_list) && item.type_list.length > 0) {
                                                                return item.type_list.map((type_list, key_child) => {
                                                                    return (
                                                                        <TableRow>
                                                                            {
                                                                                key_child == 0 &&
                                                                                <TableCell rowSpan={item.type_list.length}>
                                                                                    {item.name}
                                                                                </TableCell>
                                                                            }
                                                                            
                                                                            <TableCell>
                                                                                {value.product_type.name} {type_list.name}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {Number(type_list.price).toLocaleString()}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                            {Number(type_list.number).toLocaleString()} {value.unit}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                })
                                                            } else {
                                                                return (
                                                                    <TableRow>
                                                                        <TableCell>
                                                                            {item.name}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {Number(item.price).toLocaleString()}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {Number(item.number).toLocaleString()} {value.unit}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            }
                                                            
                                                        })
                                                    }
                                                </Table>
                                            }
                                            
                                        </TableCell>
                                        <TableCell align="center">
                                            <EditRoundedIcon onClick={() => editProduct(value, key)} className="cursor" color="primary" sx={{marginRight: "10px"}}/>
                                            <DeleteIcon className="cursor" onClick={() => deleteProduct(key, value.id)} color="error"/>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}

export default ListProduct;