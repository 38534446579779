import { Backdrop, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import api from './../../../../Apis';

function ChangeShopActive() {
    const [cookies, setCookie] = useCookies('is_login');
    const { id } = useParams();
    let navigate = useNavigate();
    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify({
                id: id
            })
        };
        fetch(api.active_shop, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    window.location.href = '/dashboard';
                    // return navigate('/dashboard');
                } else {
                    Swal.fire(result.message, '', 'error');
                    return navigate('/dashboard');
                }
            }
        )
    })

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default ChangeShopActive;