import { Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { requestOptions } from "../../../../../Helpers/function";
import api from './../../../../../Apis';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from "sweetalert2";

function DeleteCampaign(props) {
    const [isLoading, setIsLoading] = useState(false);
    const deleteCampaign = () => {
        Swal.fire({
            title: 'Bạn thực sự muốn xóa chiến dịch này?',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý',
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true);
                props.deleteCampaign(props.index, props.code);
            }
        })
    }
    
    return (
        <LoadingButton loading={isLoading} color="error" onClick={() => deleteCampaign(props.index)} startIcon={<DeleteIcon/>} variant="contained" className="text-transform">Xóa</LoadingButton>
    )
}

export default function ListCampaign(props) {
    const [state, setState] = useState({
        list_campaigns: [],
        loading: true
    })

    const deleteCampaign = (index, code) => {
        fetch(api.delete_campaign, requestOptions('POST', props.cookie, {code: code}))
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    const list_campaign_olds = [...state.list_campaigns];
                    list_campaign_olds.splice(index, 1);
                    Swal.fire({
                        title: result.message,
                        timer: 2000,
                        icon: 'success'
                    })
                    setState({
                        ...state,
                        list_campaigns: list_campaign_olds
                    })
                } else {
                    Swal.fire({
                        title: result.message,
                        timer: 2000,
                        icon: 'error'
                    })
                }
            }
        )
    }

    useEffect(() => {
        fetch(api.get_campaign, requestOptions('POST', props.cookie, {fanpage_id: props.fanpage_id}))
        .then(res => res.json())
        .then((result) => {
            if (result.success) {
                setState({
                    ...state,
                    list_campaigns: result.list_campaigns,
                    loading: false
                })
            }
        })
    }, []);

    return (
        <div>
            <h3>Danh sách chiến dịch</h3>
            <Grid item xs={12} md={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow style={{whiteSpace: 'nowrap'}}>
                                <TableCell>STT</TableCell>
                                <TableCell>Tên chiến dịch</TableCell>
                                <TableCell align="center">Tổng khách</TableCell>
                                <TableCell align="center">Đã gửi</TableCell>
                                <TableCell align="center">Chưa gửi</TableCell>
                                <TableCell align="center">Gửi lỗi</TableCell>
                                <TableCell align="center">Hành động</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                state.loading
                                ?
                                <TableRow>
                                    <TableCell colSpan={7}>
                                        <center>
                                            <CircularProgress />
                                        </center>
                                    </TableCell>
                                </TableRow>
                                :
                                state.list_campaigns.length == 0
                                ?
                                <TableRow>
                                    <TableCell colSpan={7}>
                                        <center><i>Dữ liệu trống</i></center>
                                    </TableCell>
                                </TableRow>
                                :
                                state.list_campaigns.map((campaignItem, key) => {
                                    return (
                                        <TableRow>
                                            <TableCell>
                                                {key + 1}
                                            </TableCell>
                                            <TableCell>{campaignItem.name}</TableCell>
                                            <TableCell align="center">{campaignItem.total}</TableCell>
                                            <TableCell align="center">{campaignItem.total_success}</TableCell>
                                            <TableCell align="center">{campaignItem.total_in_active}</TableCell>
                                            <TableCell align="center">{campaignItem.total_error}</TableCell>
                                            <TableCell align="center">
                                                <DeleteCampaign key={key} code={campaignItem.code} index={key} deleteCampaign={deleteCampaign}/>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </div>
    )
}