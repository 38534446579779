import { useEffect, useState } from "react";
import { Avatar, Button, Checkbox, Chip, CircularProgress, Container,FormControl,FormControlLabel,FormGroup,Grid, InputLabel, MenuItem, Pagination, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useCookies } from "react-cookie";
import api from './../../../../Apis'
import DeleteIcon from '@mui/icons-material/Delete';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';
import Swal from "sweetalert2";
import Filter from "./Components/Filter";
import {Link} from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip';
import PrintBill from "./Components/PrintBill";
import CheckBoxItem from "./Components/CheckBoxItem";
import ReportPhoneNumber from "./Components/ReportPhoneNumber";

function Order() {
    const [state, setState] = useState({
        orders: [],
        loading: true,
        order_status: [],
        employees: [],
        pages: [],
        payment_methods: [],
        transports: [],
        last_page: 0,
        filters: {},
        provinces: [],
        products: [],
        select_all: false,
        warehouses: [],
        page: 1,
        date_begin: '',
        date_end: '',
        total_order: ''
    })
    const [cookies, setCookie] = useCookies('is_login');

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            }
        };
        fetch(api.get_order, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        orders: result.datas,
                        loading: false,
                        order_status: result.order_status,
                        employees: result.employees,
                        pages: result.pages,
                        payment_methods: result.payment_methods,
                        transports: result.transports,
                        last_page: result.last_page,
                        provinces: result.provinces,
                        products: result.products,
                        warehouses: result.warehouses,
                        date_begin: result.date_begin,
                        date_end: result.date_end,
                        total_order: result.total_order
                    })
                } else {
                    setState({
                        ...state,
                        loading: false
                    })
                }
            }
        )
    }, [])

    const callApi = () => {
        setState({
            ...state,
            loading: true,
            orders: []
        })
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            }
        };
        fetch(api.get_order, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        orders: result.datas,
                        loading: false,
                        order_status: result.order_status,
                        employees: result.employees,
                        pages: result.pages,
                        payment_methods: result.payment_methods,
                        transports: result.transports,
                        last_page: result.last_page,
                        provinces: result.provinces,
                        products: result.products,
                        warehouses: result.warehouses,
                        date_begin: result.date_begin,
                        date_end: result.date_end,
                        total_order: result.total_order
                    })
                } else {
                    setState({
                        ...state,
                        loading: false
                    })
                }
            }
        )
    }

    const deleteOrder = (index, orderId) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify({
                order_id: orderId
            })
        };
        Swal.fire({
            title: 'Bạn có chắc chắn muốn xóa?',
            showDenyButton: true,
            confirmButtonText: 'Đồng ý',
            denyButtonText: 'Từ chối',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                fetch(api.delete_order, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success) {
                            const copy_orders = [...state.orders];
                            copy_orders.splice(index, 1);
                            setState({
                                ...state,
                                orders: copy_orders
                            })
                            Swal.fire({
                                title: result.message,
                                icon: 'success',
                                timer: 1500
                            })
                        } else {
                            Swal.fire({
                                title: result.message,
                                icon: 'error'
                            })
                        }
                    }
                )
            }
          })
        
    }

    const search_order = (result, filters) => {
        setState({
            ...state,
            loading: true
        })
        setTimeout(() => {
            setState({
                ...state,
                orders: result.datas,
                loading: false,
                filters: filters,
                last_page: result.last_page,
                date_begin: result.date_begin,
                date_end: result.date_end,
                total_order: result.total_order
            })
        }, 500);
    }

    const refreshOrder = (data) => {
        setState({
            ...state,
            orders: [data, ...state.orders]
        })
    }

    const handleChangePage = (e, page_num) => {
        setState({
            ...state,
            loading: true,
            page: page_num
        })
        const filters = state.filters;
        filters['page'] = page_num;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            },
            body: JSON.stringify(filters)
        };
        fetch(api.search_order, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        loading: false,
                        orders: result.datas,
                        page: page_num
                    })
                } else {
                    setState({
                        ...state,
                        loading: false,
                        page: page_num
                    })
                }
            }
        )
    }

    const printBillOrder = (code) => {
        window.open('/print-bill-order/' + code, '_blank');
    }

    const selectAll = (e) => {
        //if (e.target.checked) {
            const copy_orders = [...state.orders];
            copy_orders.map((item, index) => {
                item.is_checked = !item.is_checked
            })
            setState({
                ...state,
                orders: copy_orders,
                select_all: !state.select_all
            })
        // } else {
            
        // }
    }

    const updateChecked = (index) => {
        const new_orders = [...state.orders];
        new_orders[index].is_checked = !new_orders[index].is_checked;
        setState({
            ...state,
            orders: new_orders
        })
    }

    return (
        <Container maxWidth="xl" className='m-t-40'>
            <Filter 
                search_order={search_order}
                state={state} 
                cookie={cookies.is_login}
                refreshOrder={refreshOrder}
                callApi={callApi}
            />
            <br></br>
            <Grid item xs={12} md={12}>
                <p>
                    Có tổng <b>{state.total_order} </b>
                    {
                        state.date_begin != '' && state.date_end != ''
                        ?
                        <span> đơn hàng từ <b>{state.date_begin}</b> đến <b>{state.date_end}</b>
                        </span>
                        :
                        <span> đơn hàng</span>
                    }
                </p>
                
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow style={{whiteSpace: 'nowrap'}}>
                                {/* <TableCell>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox onChange={selectAll}/>} label="All" />
                                    </FormGroup>
                                </TableCell> */}
                                <TableCell>STT</TableCell>
                                <TableCell align="center">Mã đơn</TableCell>
                                <TableCell>Khách hàng</TableCell>
                                {/* <TableCell>Sản phẩm</TableCell> */}
                                <TableCell>Page/Sale</TableCell>
                                <TableCell>Thanh toán</TableCell>
                                <TableCell>Vận chuyển</TableCell>
                                {/* <TableCell>Chiết khấu (%)</TableCell>
                                <TableCell>Giảm giá (vnđ)</TableCell>
                                <TableCell>Phụ thu (vnđ)</TableCell> */}
                                <TableCell>Tài chính (vnđ)</TableCell>
                                <TableCell>Trạng thái</TableCell>
                                {/* <TableCell>Trạng vận đơn</TableCell> */}
                                <TableCell>Hành động</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                state.loading
                                ?
                                <TableRow>
                                    <TableCell align="center" colSpan={12}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                                :
                                state.orders.length > 0
                                ?
                                    state.orders.map((value, index) => {
                                        var product_price_total = 0;
                                        return (
                                            <TableRow>
                                                {/* <TableCell>
                                                    <CheckBoxItem
                                                        updateChecked={updateChecked}
                                                        order={value}
                                                        state={state}
                                                        index={index}
                                                    />{index + 1}
                                                </TableCell> */}
                                                <TableCell>
                                                    {
                                                        (state.page - 1) * 20 + index + 1
                                                    }
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Link className="cursor a-none" to={'/dashboard/' + value.code + '/order'}>
                                                        <Chip label={value.code} sx={{cursor: 'pointer'}} color="warning"/>
                                                    </Link>
                                                    {/* <p>
                                                        <span className="label label-warning">
                                                            {value.code}
                                                        </span>
                                                    </p> */}
                                                    <p>
                                                        {value.date}
                                                    </p>                                                
                                                </TableCell>
                                                <TableCell>
                                                    <p className="display-flex align-item">
                                                        <Avatar sx={{marginRight: '10px'}} alt={value.customer.name} src={value.avatar_user} />
                                                        <b>{value.customer.name}</b>
                                                    </p>
                                                    {/* <Chip
                                                        avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg" />}
                                                        label={value.customer.name}
                                                        variant="outlined"
                                                    /> */}
                                                    <p>
                                                        <small>
                                                        {value.customer.phone}
                                                        </small>
                                                       
                                                    </p>
                                                    <p>
                                                        {/* <small>
                                                           {value.customer.address}, {value.customer.ward}, {value.customer.district}, {value.customer.province} 
                                                        </small> */}
                                                        <small>
                                                           {value.customer.province} 
                                                        </small>
                                                        
                                                    </p>
                                                </TableCell>
                                                {/* <TableCell style={{whiteSpace: 'nowrap'}}>
                                                    {
                                                        value.order_details.map((value, key_order_detail) => {
                                                            product_price_total+= value.price;
                                                            return (
                                                                <p>
                                                                    {value.amount} x <b>
                                                                        {Number(value.product.price).toLocaleString()}
                                                                    </b> x {value.product.name} ({value.product.product_type_group_name}{value.product.product_type_name != null && ',' + value.product.product_type_name}) 
                                                                </p>
                                                            )
                                                        })
                                                    }
                                                </TableCell> */}
                                                <TableCell>
                                                    <p className="display-flex align-item">
                                                        <Avatar sx={{marginRight: '10px'}} alt={value.page.avatar} src={value.page.avatar} />
                                                        <b>{value.page.name}</b>
                                                    </p>
                                                    <p className="display-flex align-item">
                                                        <Avatar sx={{marginRight: '10px'}} alt={value.sale.full_name} src={value.sale.avatar} />
                                                        <b>{value.sale.full_name}</b>
                                                    </p>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {value.payment_method}
                                                </TableCell>
                                                <TableCell>
                                                    <p>
                                                        {value.transport}
                                                    </p>
                                                    {
                                                        value.code_transport != null &&
                                                        <Chip label={value.code_transport}  variant="outlined" color="primary" />
                                                    }
                                                    {/* <p>
                                                        <b>{value.code_transport}</b>
                                                    </p> */}
                                                </TableCell>
                                                {/* <TableCell align="right">
                                                    <b className="f-20">
                                                        {Number(value.finance.commission).toLocaleString()}
                                                    </b>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <b className="f-20">
                                                        {Number(value.finance.discount).toLocaleString()}
                                                    </b>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <b className="f-20">
                                                        {Number(value.finance.extra).toLocaleString()}
                                                    </b>
                                                </TableCell> */}
                                                <TableCell align="right" style={{whiteSpace: 'nowrap'}}>
                                                    <table>
                                                        <tr>
                                                            <td>
                                                                <small>Sản phẩm: </small>
                                                            </td>
                                                            <td>
                                                                <b>
                                                                    {/* {Number(product_price_total).toLocaleString()} */}
                                                                    {Number(value.finance.total_price_product).toLocaleString()}
                                                                </b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <small>Chiết khấu NV: </small>
                                                            </td>
                                                            <td>
                                                                <b>
                                                                    {Number(value.finance.commission).toLocaleString()}
                                                                </b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <small>Giảm giá: </small>
                                                            </td>
                                                            <td>
                                                                <b>
                                                                    {Number(value.finance.discount).toLocaleString()}
                                                                </b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <small>Phụ thu: </small>
                                                            </td>
                                                            <td>
                                                                <b>
                                                                    {Number(value.finance.extra).toLocaleString()}
                                                                </b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <small>Phí ship: </small>
                                                            </td>
                                                            <td>
                                                                <b>
                                                                    {Number(value.finance.fee_ship).toLocaleString()}
                                                                </b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <small>Phí hoàn: </small>
                                                            </td>
                                                            <td>
                                                                <b>
                                                                    {Number(value.finance.return_fee_ship).toLocaleString()}
                                                                </b>
                                                            </td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td colSpan={2} style={{borderTop: '1px solid #ccc'}}>
                                                                <small>Thành tiền: </small>
                                                                <b style={{fontSize: '20px'}}>
                                                                    {Number(value.finance.total).toLocaleString()}
                                                                </b>
                                                            </td>
                                                        </tr> */}
                                                    </table>
                                                    
                                                </TableCell>
                                                <TableCell style={{whiteSpace: 'nowrap'}}>
                                                    <table className="status-order">
                                                        <tr>
                                                            <td>Đơn hàng: </td>
                                                            <td>
                                                                <span className="label" style={{background: value.status.color, color: '#fff'}}>
                                                                    {value.status.name}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Vận đơn:</td>
                                                            <td>
                                                                <span className={value.status_transport.color}>
                                                                    {value.status_transport.name}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td>Thanh toán:</td>
                                                            <td>
                                                                {
                                                                    value.status_payment.name != '' &&
                                                                    <span className={value.status_payment.class}>
                                                                        {value.status_payment.name}
                                                                    </span>
                                                                }
                                                            </td>
                                                        </tr> */}
                                                    </table>
                                                    
                                                </TableCell>
                                                {/* <TableCell style={{whiteSpace: 'nowrap'}}>
                                                    <span className={value.status_transport.color}>
                                                        {value.status_transport.name}
                                                    </span>
                                                </TableCell> */}
                                                <TableCell style={{whiteSpace: 'nowrap'}}>
                                                    <p>
                                                        <Link to={'/dashboard/' + value.code + '/order'}>
                                                            <Tooltip title="Chi tiết, sửa" arrow placement="top">
                                                                <Button variant="contained" sx={{marginRight: "10px", minWidth: '45px'}} size="small">
                                                                    <EditRoundedIcon sx={{fontSize: '20px'}} size="small" className="cursor"/>
                                                                </Button>
                                                            </Tooltip>
                                                        </Link>
                                                        <Tooltip onClick={() => deleteOrder(index, value.code)} title="Xóa đơn hàng" arrow placement="top">
                                                            <Button color="error" variant="contained" sx={{minWidth: '45px'}} size="small">
                                                                <DeleteIcon sx={{fontSize: '20px'}} className="cursor"/>
                                                            </Button>
                                                        </Tooltip>                                                     
                                                    </p>
                                                    <p>
                                                        <Tooltip  title="In hóa đơn" arrow placement="top">
                                                            <Button onClick={() => printBillOrder(value.code)} color="inherit" variant="contained" sx={{marginRight: "10px", minWidth: '45px'}} size="small">
                                                                <LocalPrintshopRoundedIcon sx={{fontSize: '20px'}} color="default" className="cursor"/>
                                                            </Button>
                                                        </Tooltip>
                                                        <PrintBill 
                                                            order={value}
                                                            cookie={cookies.is_login}
                                                        />
                                                    </p>
                                                    <p>
                                                        <ReportPhoneNumber
                                                            cookie={cookies.is_login}
                                                            data={value}
                                                            state={state}
                                                            order_id={value.code}
                                                        />
                                                    </p>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                :
                                <TableRow>
                                    <TableCell colSpan={12} align="center">
                                        Dữ liệu trống
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <br></br>
            {
                state.last_page > 1 &&
                <Grid item xs={12} md={12} className="display-flex justify-content-center">
                    <Stack spacing={2}>
                        <Pagination
                            onChange={handleChangePage}
                            color="primary"
                            count={state.last_page}
                            showFirstButton
                            showLastButton
                        />
                    </Stack>
                </Grid>
            }
            <br></br>
        </Container>
    )
}

export default Order;