import { CircularProgress, Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import api from "../../../../../Apis";
import GoBack from "../../../../Globals/GoBack";
import GroupFanpageItem from "./GroupFanpageItem";

export default function GroupFanpage() {
    const [cookies, setCookie] = useCookies('is_login');
    const [state, setState] = useState({
        group_fanpages: [],
        is_loading: true,
        error_message: ""
    })
    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + cookies.is_login
            }
        };
        fetch(api.group_fanpage, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success) {
                    setState({
                        ...state,
                        group_fanpages: result.data,
                        is_loading: false,
                        error_message: ""
                    })
                } else {
                    setState({
                        ...state,
                        group_fanpages: [],
                        is_loading: false,
                        error_message: result.message
                    })
                }
            } 
        )
    }, []);

    const updateGroupAfterDelete = (index) => {
        const group_fanpage_copy = [...state.group_fanpages];
        group_fanpage_copy.splice(index, 1);
        setState({...state, group_fanpages: group_fanpage_copy})
    }

    return (
        <Container maxWidth="xl" className='m-t-40'>
            <GoBack/>
            <Grid item xs={12} md={12}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>STT</TableCell>
                                <TableCell>Tên nhóm</TableCell>
                                <TableCell>Số Fanpage</TableCell>
                                <TableCell>Chi tiết</TableCell>
                                <TableCell>Hành động</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                state.is_loading
                                ?
                                <TableRow>
                                    <TableCell colSpan={5} style={{ textAlign: "center" }}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                                :
                                state.group_fanpages.map((groupItem, index) => {
                                    return (
                                        <GroupFanpageItem
                                            stt = { index + 1 }
                                            index_group = { index }
                                            updateGroupAfterDelete = { updateGroupAfterDelete }
                                            data = { groupItem }
                                            cookie = { cookies.is_login }
                                            total_fanpage = { groupItem.fanpages.length }
                                            fanpages = { groupItem.fanpages }
                                        />
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Container>
    )
}