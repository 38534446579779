import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import api from './../../../../../Apis';
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import { Link } from "react-router-dom";

export default function DialogKeywordMessage(props) {
    const [state, setState] = useState({
        loading_button: false
    })
    const {register, handleSubmit, reset, getValues, setValue} = useForm();
    const onSaveKeywordMessage = (data) => {
        setState({
            ...state,
            loading_button: true
        })
        props.onSaveKeywordMessage(data);
    }

    useEffect(() => {
        if (props.index_edit_message != null && props.data_edit_message != null) {
            setValue('keyword', props.data_edit_message.keyword_comment);
            setValue('message', props.data_edit_message.message_inbox);
            setValue('block_code', props.data_edit_message.block.code);
        } else {
            reset();
        }
    }, [props.index_edit_message])

    return (
        <form onSubmit={handleSubmit(onSaveKeywordMessage)}>
            <Grid container spacing={2}>
                {
                    props.index_edit_message == null &&
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox {...register('is_setting_together')} />} label='Cài đặt cho tất cả Fanpage' />
                        </FormGroup>
                    </Grid>
                }
                <Grid item xs={12}>
                    <FormControl className="w-100">
                        <FormLabel sx={{padding: '9px 0px'}} id="demo-radio-buttons-group-label">Khi người dùng nhắn tin chứa một trong các từ khóa dưới đây:</FormLabel>
                        <TextField
                            fullWidth id="outlined-basic"
                            size="small"
                            required
                            {...register('keyword')}
                            autoComplete="off"
                            placeholder="Mỗi từ khóa cách nhau bằng dấu phẩy"
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className="w-100">
                        <FormLabel sx={{padding: '9px 0px'}} id="demo-radio-buttons-group-label">Thì tự động trả lời tin nhắn (Không bắt buộc): </FormLabel>
                        <textarea cols="30" {...register('message')} placeholder="Nội dung bình luận" className="textarea" rows="5"></textarea>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormLabel sx={{padding: '9px 0px'}} id="demo-radio-buttons-group-label">
                            Hoặc gửi Block, tạo block <Link to="/dashboard/chatbot"><span>tại đây</span></Link>
                        </FormLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={(props.index_edit_message != null && props.data_edit_message != null) ? props.data_edit_message.block.code : "null"}
                            {...register('block_code')}
                        >
                            <MenuItem value="null">Chọn một block bất kỳ</MenuItem>
                            {
                                Array.isArray(props.blocks) &&
                                props.blocks.map((value, key) => {
                                    return (
                                        <MenuItem value={value.code}>{value.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                {/* <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox defaultChecked={props.data_edit_message != null ? props.data_edit_message.is_inbox : true} {...register('is_inbox')} />} label="Chỉ nhắn tin 1 lần/khách" />
                    </FormGroup>
                </Grid> */}
                <Grid item xs={12}>
                    Thuật ngữ viết tắt nội dung tin nhắn:<br></br>
                    <b>{"{full_name}"}</b>: Tên khách hàng<br></br>
                    <b>{"{page_name}"}</b>: Tên page<br></br>
                </Grid>
                <Grid item xs={12}>
                    <LoadingButton loading={state.loading_button} type="submit" startIcon={<SaveOutlinedIcon/>} variant="contained" color="primary">
                        Lưu
                    </LoadingButton>
                </Grid>
            </Grid>
        </form>
    )
}