import { LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import Pusher from "pusher-js";
import pusherConfig from "../../../../../Config/pusher";

export default function ProcessGetCustomer(props) {
    const [state, setState] = useState({
        total_customer: 0
    })
    useEffect(() => {
        if (props.owner_id != '') {
           Pusher.logToConsole = false;
            const pusher = new Pusher(pusherConfig.dev.key, {
                cluster: pusherConfig.dev.cluster
            });
            const channel = pusher.subscribe('get-customer-old-' + props.owner_id);
            channel.bind('get-customer-old-' + props.owner_id, function (data) {
                setState((oldState) => {
                    return ({
                        ...oldState,
                        total_customer: parseInt(oldState.total_customer + 1)
                    })
                })
            }); 
        }
    }, [props.owner_id])

    return (
        <div>
            <p>
                Đã quét: <b>{Number(state.total_customer).toLocaleString()}</b> khách hàng
            </p>
            <LinearProgress/>
            <br></br>
        </div>
    )
}